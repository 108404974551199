import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import clsx from 'clsx'
import { motion } from 'framer-motion'

import './styles.scss'

type TooltipProps = {
  text: string
  children: React.ReactNode
  classNames?: string
}

const PortalTooltip: React.FC<TooltipProps> = ({
  text,
  children,
  classNames,
}) => {
  const [position, setPosition] = useState<{
    x: number
    y: number
  } | null>(null)
  const [tooltipHeight, setTooltipHeight] = useState(0)
  const [tooltipWidth, setTooltipWidth] = useState(0)
  const [parentWidth, setParentWidth] = useState(0)

  const handleMouseOver = (e: React.MouseEvent<HTMLElement>) => {
    const bounds = e.currentTarget.getBoundingClientRect()

    setPosition({
      x: bounds.x,
      y: bounds.y,
    })

    setParentWidth(bounds.width)
  }

  const handleMouseOut = () => setPosition(null)

  const anchorProps = {
    onMouseOver: handleMouseOver,
    onMouseOut: handleMouseOut,
  }

  const anchor = React.isValidElement(children) ? (
    React.cloneElement(children, anchorProps)
  ) : (
    <span {...anchorProps}>{children}</span>
  )

  return (
    <>
      {anchor}
      {position &&
        ReactDOM.createPortal(
          <motion.div
            style={{
              top: position.y - tooltipHeight - 2,
              left: position.x - tooltipWidth + parentWidth,
            }}
            className={clsx('portal-tooltip-container', classNames)}
            ref={(elem) => {
              elem && setTooltipHeight(elem.clientHeight)
              elem && setTooltipWidth(elem.clientWidth)
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className='portal-tooltip-container__text'>{text}</div>
          </motion.div>,
          document.body
        )}
    </>
  )
}

export default PortalTooltip
