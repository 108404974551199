import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { SupplementInfo } from './modalSupplementInfoSlice'
import { getData } from '../../utils/axiosManager'

type SupplementFactsState = {
  supplementFactsList: SupplementInfo[]
}

const initialState: SupplementFactsState = {
  supplementFactsList: [],
}

export const getSupplementAsync = createAsyncThunk(
  'getSupplementAsync',
  (article: string) => {
    return getData(`/Supplements?Article=${article}`)
  }
)

const supplementFactsSlice = createSlice({
  name: 'supplementFacts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSupplementAsync.fulfilled, (state, action) => {
      return {
        supplementFactsList: [
          ...state.supplementFactsList,
          action.payload.Supplements[0],
        ],
      }
    })
  },
})

export const {} = supplementFactsSlice.actions

export default supplementFactsSlice.reducer
