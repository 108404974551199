import { useEffect, useState } from 'react'

import { pluralizeWords } from '../utils/pluralizeWords'
import { useAppSelector } from '../redux/hook'
import { WordVariants } from '../redux/slices/partnerInterfaceSlice'

export const useWordDeclension = (textId: WordVariants, count?: number) => {
  const [words, setWords] = useState<string[]>([])
  const { styles } = useAppSelector((state) => state.partnerInterface)

  useEffect(() => {
    if (!styles.length) {
      return
    }

    Array.from(
      {
        length: count || textId.count,
      },
      (_, index) =>
        pluralizeWords(
          styles[0].TextIDsRepository.localeId,
          index + 1,
          textId.variants
        ).then((data) => {
          if (words.length < textId.count) {
            setWords((prevState) => [...prevState, data])
          }
        })
    )
  }, [styles])

  return words
}
