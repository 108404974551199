import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { useCookies } from 'react-cookie'
import { AxiosError } from 'axios'
import { LayoutGroup, motion } from 'framer-motion'

import { useAppDispatch, useAppSelector } from '../../redux/hook'
import {
  changeCourseReceptionDuration,
  RecipeOfDay,
  reformatDataForServerRequest,
  sortedBySupplement,
  sortedByTime,
  updateCourseServiceValues,
} from '../../redux/slices/courseSlice'
import Card from '../UI/Card'
import Select from '../UI/Select'
import SupplementOfDay from '../SupplementOfDay'
import SortedByTimeCard from '../SortedByTimeCard'
import SortedByTimeCardItem from '../SortedByTimeCard/SortedByTimeCardItem'
import { getSupplementAsync } from '../../redux/slices/supplementFactsSlice'
import TotalPayPanel from '../TotalPayPanel'
import ShippingPayment from '../ShippingPayment'
import { CookieEnum } from '../../enums/cookie'
import { changeSupplementCourse } from '../../utils/axiosManager'
import { ApiRoutes } from '../../enums/apiRoutes'
import { setErrorState } from '../../redux/slices/errorsSlice'
import FilterControls from '../shared/FilterControls'
import { useMatchMedia } from '../../hooks/useMatchMedia'
import { CheckboxType } from '../UI/Checkbox/Checkbox'
import { useWordDeclension } from '../../hooks/useWordDeclension'

import './styles.scss'

export type CurrentFilter = {
  type: 'time' | 'supplement' | string
}

type CoursePanelProps = {
  alwaysOpened?: boolean
  headerClosedPanel?: React.ReactElement
  header?: React.ReactElement
  children?: React.ReactElement
}

const CoursePanel: React.FC<CoursePanelProps> = ({
  alwaysOpened,
  headerClosedPanel,
  header,
  children,
}) => {
  const [isOpenPanel, setIsOpenPanel] = useState(false)
  const [currentFilter, setCurrentFilter] = useState<CurrentFilter>({
    type: '',
  })
  const [courseDuration, setCourseDuration] = useState<string>('')
  const [isCollapsedAll, setIsCollapsedAll] = useState(false)
  const [isLoadingCourseUpdated, setIsLoadingCourseUpdated] = useState(false)
  const [weekdaysCheckboxes, setWeekdayCheckboxes] = useState<CheckboxType[]>(
    []
  )
  const { course } = useAppSelector((state) => state.course)
  const { styles } = useAppSelector((state) => state.partnerInterface)
  const profileData = useAppSelector((state) => state.profileSlice)
  const analysisOrderList = useAppSelector((state) => state.analysisOrder.list)
  const [cookies, , removeCookie] = useCookies([CookieEnum.SupplementCourse])
  const dispatch = useAppDispatch()
  const { isMobile, isDesktop } = useMatchMedia()
  const courseDurationOptions = useWordDeclension(
    styles[0]?.TextIDsRepository.myCourseWeek
  )

  const filterControlsButtonsList = [
    {
      title: styles[0]?.TextIDsRepository.myCourseByAppointmentTime,
      currentFilterType: 'time',
    },
    {
      title: styles[0]?.TextIDsRepository.myCourseBySupplement,
      currentFilterType: 'supplement',
    },
  ]

  useEffect(() => {
    if (currentFilter.type === 'time') {
      dispatch(sortedByTime())
    }

    if (currentFilter.type === 'supplement') {
      dispatch(sortedBySupplement())
    }
  }, [currentFilter])

  useEffect(() => {
    dispatch(sortedByTime())
    if (course.id !== null) {
      editCourseRequest(course.duration)
    }
  }, [course.supplementsList])

  useEffect(() => {
    if (styles[0]) {
      const weekdayCheckboxesInitialState =
        styles[0].TextIDsRepository.myCourseDayOfWeek.map((value, index) => ({
          id: index,
          checked: false,
          value,
        }))

      setWeekdayCheckboxes(weekdayCheckboxesInitialState)
    }
  }, [styles])

  const editCourseRequest = (duration: number) => {
    const reformatList = reformatDataForServerRequest(course.supplementsList)
    let currentLength = 0

    reformatList.forEach((item) => {
      if (!isNaN(Number(item.ReceptionTime[item.ReceptionTime.length - 1]))) {
        currentLength++
      }
    })

    if (reformatList.length !== currentLength || !cookies.SupplementCourse) {
      return
    }

    setIsLoadingCourseUpdated(true)

    changeSupplementCourse(
      cookies.SupplementCourse,
      course.id,
      profileData.customerID,
      duration,
      reformatList,
      ApiRoutes.EditSupplementCourse
    )
      .then((data) => {
        if (data instanceof AxiosError) {
          dispatch(setErrorState(true))
          return
        }

        if (typeof data.EditSupplementCourse === 'string') {
          return
        }

        if (data.EditSupplementCourse.Success) {
          dispatch(updateCourseServiceValues(data.EditSupplementCourse.Success))
          return
        }

        dispatch(updateCourseServiceValues(data.EditSupplementCourse))
        setIsLoadingCourseUpdated(false)
      })
      .catch(() => dispatch(setErrorState(true)))

    if (!course.supplementsList.length) {
      removeCookie('SupplementCourse' as CookieEnum.SupplementCourse)
    }
  }

  useEffect(() => {
    if (isOpenPanel) {
      course.supplementsList.forEach((supplement) =>
        dispatch(getSupplementAsync(supplement.supplement.Article))
      )
    }

    if (courseDurationOptions.length) {
      setCourseDuration(courseDurationOptions[course.duration - 1])
    }
  }, [isOpenPanel, courseDurationOptions])

  const handleCoursePanelSize = () => {
    setCurrentFilter({ type: 'time' })
    setIsOpenPanel((prevState) => !prevState)
  }

  const handleCourseTimeSelect = (value: string) => {
    const courseDuration = Number(value[0])
    dispatch(changeCourseReceptionDuration(courseDuration))
    editCourseRequest(courseDuration)
    setCourseDuration(value)
  }

  const handleCollapseAll = () => {
    setIsCollapsedAll((prevState) => !prevState)
  }

  const animateTransition = {
    type: 'spring',
    stiffness: 5000,
    damping: 100,
    mass: 0.2,
  }

  if (children && header) {
    return !isOpenPanel ? (
      <motion.aside
        className={clsx('course-panel-container', {
          'course-panel-container-mobile': isMobile,
        })}
        onClick={handleCoursePanelSize}
        layout
        transition={animateTransition}
      >
        <div className='course-panel-container__header'>
          <button
            className='course-panel-container__header__course-collapse-btn'
            onClick={(event) => {
              event.stopPropagation()
              handleCoursePanelSize()
            }}
          >
            <ChevronLeft />
          </button>

          <div className='course-panel-container-mobile__header'>
            {headerClosedPanel}
          </div>
        </div>

        {analysisOrderList.map((analysis) => (
          <span
            key={analysis.serviceId}
            className='course-panel-container__body__analysis'
          >
            {analysis.nameService}
          </span>
        ))}
      </motion.aside>
    ) : (
      <motion.aside
        className={clsx('course-panel-container', {
          'open-panel': isOpenPanel,
        })}
        layout
        transition={animateTransition}
      >
        <>
          <div className='course-panel-container__header'>
            {!alwaysOpened && (
              <button
                className='course-panel-container__header__course-collapse-btn'
                onClick={handleCoursePanelSize}
              >
                {isOpenPanel ? <ChevronRight /> : <ChevronLeft />}
              </button>
            )}

            {header}
          </div>

          <div className='course-panel-container__body'>{children}</div>
        </>
      </motion.aside>
    )
  }

  return !isOpenPanel ? (
    <motion.aside
      className='course-panel-container'
      onClick={handleCoursePanelSize}
      layout
      transition={animateTransition}
    >
      <div className='course-panel-container__header'>
        <button
          className='course-panel-container__header__course-collapse-btn'
          onClick={(event) => {
            event.stopPropagation()
            handleCoursePanelSize()
          }}
        >
          <ChevronLeft />
        </button>

        {isMobile && (
          <span className='course-panel-container__header__bio-count'>
            {course.supplementsList.length} биодобавки
          </span>
        )}

        <span className='course-panel-container__header__title'>
          {styles[0]?.TextIDsRepository.myCourseLabel}
        </span>
      </div>

      {!course.supplementsList.length && isDesktop && (
        <span className='course-panel-container__empty-text'>
          {styles[0]?.TextIDsRepository.myCourseInvitation}
        </span>
      )}

      {!isMobile && (
        <div className='course-panel-container__list'>
          {course.supplementsList.map((supplement) => (
            <div key={supplement.supplement.Article}>
              <img src={supplement.supplement.Picture} alt='' />
              <span>{supplement.supplement.GoodsCommercialName}</span>
            </div>
          ))}
        </div>
      )}
    </motion.aside>
  ) : (
    <motion.aside
      className={clsx('course-panel-container', {
        'open-panel': isOpenPanel,
      })}
      layout
      transition={animateTransition}
    >
      <>
        <div className='course-panel-container__header'>
          <button
            className='course-panel-container__header__course-collapse-btn'
            onClick={() => handleCoursePanelSize()}
          >
            {isOpenPanel ? <ChevronRight /> : <ChevronLeft />}
          </button>
          <span className='course-panel-container__header__title'>
            {styles[0]?.TextIDsRepository.myCourseLabel}
          </span>

          <Select
            classNames='course-panel-container__header__select'
            value={courseDurationOptions[Number(courseDuration[0]) - 1]}
            onChange={handleCourseTimeSelect}
            options={courseDurationOptions}
            disabled={!course.supplementsList.length}
          />

          {!isMobile && (
            <button
              className='course-panel-container__header__collapse-btn'
              onClick={handleCollapseAll}
            >
              {isCollapsedAll
                ? styles[0]?.TextIDsRepository.myCourseCollapseAll
                : styles[0]?.TextIDsRepository.myCourseExpandAll}
            </button>
          )}
        </div>

        <div className='course-panel-container__body'>
          <LayoutGroup id='coursePanel'>
            <FilterControls
              classNames='course-panel-container__controls'
              buttonsList={filterControlsButtonsList}
              state={currentFilter}
              changeState={setCurrentFilter}
            />
          </LayoutGroup>

          {!course.supplementsList.length && (
            <span className='course-panel-container__body__invitation'>
              {styles[0].TextIDsRepository.myCourseInvitation}
            </span>
          )}

          <div className='course-panel-container__list-opened'>
            {currentFilter.type === 'supplement'
              ? course.supplementsList?.map((supplement) => {
                  return (
                    <Card
                      key={supplement.supplement.Article}
                      supplementInCourse={supplement}
                      title={supplement.supplement.GoodsCommercialName}
                      isCollapsedAll={isCollapsedAll}
                    >
                      {supplement.recipes.map((recipe: RecipeOfDay) => {
                        return (
                          <SupplementOfDay
                            key={recipe.id}
                            recipe={recipe}
                            article={supplement.supplement.Article}
                            form={supplement.supplement.SupplementForm}
                            weekdaysCheckboxes={weekdaysCheckboxes}
                            setWeekdayCheckboxes={setWeekdayCheckboxes}
                          />
                        )
                      })}
                    </Card>
                  )
                })
              : Object.entries(course.filteredByTime).map((day) => {
                  return Object.entries(day[1]).map((time) => {
                    return (
                      <SortedByTimeCard
                        key={time[0]}
                        day={day}
                        time={time}
                        isCollapsedAll={isCollapsedAll}
                      >
                        {time[1].map((supplement) => {
                          return (
                            <SortedByTimeCardItem
                              key={supplement.supplement.Article}
                              supplement={supplement}
                              numberOfItems={time[1].length}
                            />
                          )
                        })}
                      </SortedByTimeCard>
                    )
                  })
                })}
          </div>

          <div className='course-panel-container__order-title'>
            <img src={styles[0]?.TextIDsRepository.assets.packageIcon} alt='' />
            <span>{styles[0].TextIDsRepository.myCourseOrderingTitle}</span>
          </div>

          <ShippingPayment />

          <TotalPayPanel isLoadingCourseUpdated={isLoadingCourseUpdated} />
        </div>
      </>
    </motion.aside>
  )
}

export default CoursePanel
