import React from 'react'
import { ChevronDown } from 'react-feather'
import clsx from 'clsx'

import './styles.scss'

type SelectProps = {
  options: string[]
  value: string
  onChange: (value: string) => void
  classNames?: string
  disabled?: boolean
}

const Select: React.FC<SelectProps> = ({
  options,
  value,
  classNames,
  disabled,
  onChange,
}) => {
  return (
    <div className={clsx('select-container', classNames)}>
      <select
        className='select'
        value={value}
        onChange={(event) => onChange(event.target.value)}
        disabled={disabled}
      >
        {options.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </select>

      {!disabled && <ChevronDown className='select-container__chevron' />}
    </div>
  )
}

export default Select
