export enum NameOfDays {
  daily = 'daily',
  monday = 'Понедельник',
  tuesday = 'Вторник',
  wednesday = 'Среда',
  thursday = 'Четверг',
  friday = 'Пятница',
  saturday = 'Суббота',
  sunday = 'Воскресенье',
}
