import React, { useState } from 'react'
import { Filter, Search, User } from 'react-feather'
import clsx from 'clsx'
import { AnimatePresence } from 'framer-motion'

import Calendar from '../../UI/Calendar'
import { useAppSelector } from '../../../redux/hook'
import PortalTooltip from '../../UI/PortalTooltip'
import { useMatchMedia } from '../../../hooks/useMatchMedia'
import { ModalWrapper } from '../../ModalWrapper'

import './styles.scss'

const ProfileAccruals = () => {
  const [isShowFilters, setIsShowFilters] = useState(false)
  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const { isMobile } = useMatchMedia()

  const renderFilters = () => {
    if (isMobile) {
      return (
        <>
          <button
            className='accruals-container__filter-button'
            onClick={() => setIsShowFilters(true)}
          >
            <Filter />
          </button>

          <AnimatePresence>
            {isShowFilters && (
              <ModalWrapper
                setIsOpen={setIsShowFilters}
                fullScreen
                closeButtonClassNames='accruals-container__modal__close-button'
              >
                <div className='accruals-container__modal__header'>
                  <span>Фильтр</span>
                </div>

                <div className='accruals-container__modal__body'>
                  <div
                    className={clsx(
                      'accruals-container__calendar-wrapper',
                      'accruals-container__modal__calendar'
                    )}
                  >
                    <Calendar
                      label={
                        styles[0].TextIDsRepository.personalAccountStartDate
                      }
                    />

                    <hr className='accruals-container__calendar-wrapper__separator' />

                    <Calendar
                      label={
                        styles[0].TextIDsRepository
                          .personalAccountCompletionDate
                      }
                    />
                  </div>
                </div>
              </ModalWrapper>
            )}
          </AnimatePresence>
        </>
      )
    }

    return (
      <div className='accruals-container__calendar-wrapper'>
        <Calendar
          label={styles[0].TextIDsRepository.personalAccountStartDate}
        />

        <hr className='accruals-container__calendar-wrapper__separator' />

        <Calendar
          label={styles[0].TextIDsRepository.personalAccountCompletionDate}
        />
      </div>
    )
  }

  return (
    <div>
      <div
        className={clsx(
          'clients-container__header',
          'accruals-container__header'
        )}
      >
        {renderFilters()}

        <label className='clients-container__header__label'>
          <input
            className='clients-container__header__search'
            placeholder='Поиск по ФИО'
          />

          <Search />
        </label>
      </div>

      <div className='accruals-container__body'>
        <div className='accruals-container__body__header'>Дата</div>
        <div className='accruals-container__body__header'>ФИО</div>
        <div className='accruals-container__body__header'>Тип</div>
        {!isMobile && (
          <>
            <div className='accruals-container__body__header'>База</div>
            <div className='accruals-container__body__header'>% начисления</div>
          </>
        )}
        <div
          className={clsx('accruals-container__body__header', {
            'accruals-container__body__header__sum': isMobile,
          })}
        >
          Сумма
        </div>

        <div className='accruals-container__body__row'>
          <div>16.03.23</div>
          <div className='accruals-container__body__row__name'>
            <span>Отставнова</span>
            <span>Нина Александровна</span>
          </div>

          <div>
            <PortalTooltip
              text={styles[0]?.TextIDsRepository.personalAccountBioAddTooltip}
              classNames='accruals-container__body__tooltip'
            >
              <img
                src={styles[0]?.TextIDsRepository.assets.packageIcon}
                alt=''
              />
            </PortalTooltip>
          </div>

          {!isMobile && (
            <>
              <div className='accruals-container__body__row__grey-text'>
                10 000 {styles[0]?.TextIDsRepository.currency}
              </div>
              <div className='accruals-container__body__row__grey-text'>
                10 %
              </div>
            </>
          )}
          <div className='accruals-container__body__row__sum'>
            <span>1000 {styles[0]?.TextIDsRepository.currency}</span>

            {isMobile && (
              <>
                <span className='accruals-container__body__row__grey-text'>
                  10 000 {styles[0]?.TextIDsRepository.currency} | 10 %
                </span>
              </>
            )}
          </div>
        </div>

        <div className='accruals-container__body__row'>
          <div>18.03.23</div>
          <div className='accruals-container__body__row__name'>
            <span>Отставнова</span>
            <span>Нина Александровна</span>
          </div>
          <div>
            <PortalTooltip
              text={
                styles[0]?.TextIDsRepository
                  .personalAccountPersonalizationTooltip
              }
              classNames='accruals-container__body__tooltip'
            >
              <User />
            </PortalTooltip>
          </div>
          {!isMobile && (
            <>
              <div className='accruals-container__body__row__grey-text'>
                12 000 {styles[0]?.TextIDsRepository.currency}
              </div>
              <div className='accruals-container__body__row__grey-text'>
                12 %
              </div>
            </>
          )}
          <div className='accruals-container__body__row__sum'>
            <span>1200 {styles[0]?.TextIDsRepository.currency}</span>

            {isMobile && (
              <>
                <span className='accruals-container__body__row__grey-text'>
                  12 000 {styles[0]?.TextIDsRepository.currency} | 12 %
                </span>
              </>
            )}
          </div>
        </div>

        <div className='accruals-container__body__row'>
          <div>18.03.23</div>
          <div className='accruals-container__body__row__name'>
            <span>Отставнова</span>
            <span>Нина Александровна</span>
          </div>
          <div>
            <PortalTooltip
              text={
                styles[0]?.TextIDsRepository.personalAccountLaboratoryTooltip
              }
              classNames='accruals-container__body__tooltip'
            >
              <img src={styles[0]?.TextIDsRepository.assets.flaskIcon} alt='' />
            </PortalTooltip>
          </div>
          {!isMobile && (
            <>
              <div className='accruals-container__body__row__grey-text'>
                12 000 {styles[0]?.TextIDsRepository.currency}
              </div>
              <div className='accruals-container__body__row__grey-text'>
                12 %
              </div>
            </>
          )}
          <div className='accruals-container__body__row__sum'>
            <span>1200 {styles[0]?.TextIDsRepository.currency}</span>

            {isMobile && (
              <>
                <span className='accruals-container__body__row__grey-text'>
                  12 000 {styles[0]?.TextIDsRepository.currency} | 12 %
                </span>
              </>
            )}
          </div>
        </div>

        <div className='accruals-container__body__row'>
          <div>18.03.23</div>
          <div className='accruals-container__body__row__name'>
            <span>Отставнова</span>
            <span>Нина Александровна</span>
          </div>
          <div>
            <PortalTooltip
              text={
                styles[0]?.TextIDsRepository.personalAccountFacilitiesTooltip
              }
              classNames='accruals-container__body__tooltip'
            >
              <img src={styles[0]?.TextIDsRepository.assets.balance} alt='' />
            </PortalTooltip>
          </div>
          {!isMobile && (
            <>
              <div className='accruals-container__body__row__grey-text'>
                12 000 {styles[0]?.TextIDsRepository.currency}
              </div>
              <div className='accruals-container__body__row__grey-text'>
                12 %
              </div>
            </>
          )}
          <div className='accruals-container__body__row__sum'>
            <span>1200 {styles[0]?.TextIDsRepository.currency}</span>

            {isMobile && (
              <>
                <span className='accruals-container__body__row__grey-text'>
                  12 000 {styles[0]?.TextIDsRepository.currency} | 12 %
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileAccruals
