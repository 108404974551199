import React from 'react'
import { Calendar as CalendarIcon } from 'react-feather'

import './styles.scss'

type CalendarProps = {
  label?: string
}

const Calendar: React.FC<CalendarProps> = ({ label }) => {
  return (
    <form>
      <span className='calendar-container__label'>{label}</span>
      <div className='calendar-container'>
        <input
          placeholder='__.__.__'
          className='calendar-container__date'
          type='date'
          name='selected_date'
          onChange={(event) => console.log(event.target.value)}
        />
        <span className='calendar-container__button-container'>
          <button>
            <CalendarIcon />
          </button>
        </span>
      </div>
    </form>
  )
}

export default Calendar
