import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ArrowLeft, X, RotateCw } from 'react-feather'
import { useLocation, useNavigate } from 'react-router-dom'
import InputMask from 'react-input-mask'
import { useCookies } from 'react-cookie'

import { AxiosError } from 'axios'
import clsx from 'clsx'

import { useAppDispatch, useAppSelector } from '../../redux/hook'
import {
  updateProfileCustomerField,
  updateProfileDataFromServer,
} from '../../redux/slices/profileSlice'
import { getCustomerData } from '../../utils/axiosManager'
import { CookieEnum } from '../../enums/cookie'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import { formatPhoneNumber } from '../../utils/formatPhoneNumber'
import { useDefineCurrentPathName } from '../../hooks/useDefineCurrentPathName'
import { setErrorState } from '../../redux/slices/errorsSlice'
import { RoutesEnum } from '../../enums/routes'
import { ApiStatuses } from '../../enums/apiRoutes'
import {
  getValidatePhoneCustomer,
  setConfirmationByCode,
  setPhoneCustomerCode,
} from '../../models/auth/api'
import { customerEditPhone } from '../../models/customer/api'

import './styles.scss'

const Login: React.FC = () => {
  const [verificationCode, setVerificationCode] = useState('')
  const [currentVerificationCodeIndex, setCurrentVerificationCodeIndex] =
    useState(0)
  const [callTimer, setCallTimer] = useState(10)
  const [isRestartCall, setIsRestartCall] = useState(false)
  const [isErrorCode, setIsErrorCode] = useState(false)

  const { styles } = useAppSelector((state) => state.partnerInterface)
  const auth = useAppSelector((state) => state.auth)
  const profileData = useAppSelector((state) => state.profileSlice)

  const [, setCookies] = useCookies([CookieEnum.CustomerID])
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const isCabinetPathName = useDefineCurrentPathName(
    `${RoutesEnum.PersonalCabinet}/*`
  )
  const isAnalysisPathName = useDefineCurrentPathName(
    `${RoutesEnum.Analysis}/*`
  )
  const inputNode = useRef<any>(null) // because types conflict

  const customerPhoneMasked = useMemo(
    () =>
      formatPhoneNumber(
        isCabinetPathName ? location.state.newPhoneNumber : auth.customerPhone
      ),
    [auth.customerPhone]
  )

  const customerPhone = useAppSelector((state) => state.auth.customerPhone)

  useEffect(() => {
    const interval = handleInterval()

    if (callTimer === 0) {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [callTimer])

  useEffect(() => {
    if (inputNode) {
      inputNode.current?.focus()
    }

    if (Number(verificationCode).toString().length === 4) {
      setConfirmationByCode(
        isCabinetPathName ? location.state.newPhoneNumber : customerPhone,
        { code: verificationCode }
      ).then((response) => {
        if (response.status === ApiStatuses.NotFound) {
          setIsRestartCall(true)
          setIsErrorCode(true)
          return
        }

        if (isCabinetPathName && location.state.newClient) {
          navigate(`${RoutesEnum.PersonalCabinet + RoutesEnum.Register}`, {
            state: { ...location.state },
          })
          return
        }

        if (isCabinetPathName) {
          const oldPhone = profileData.customerPhone.toString()
          const request = {
            customerId: profileData.customerID,
            newPhone: location.state.newPhoneNumber,
          }

          customerEditPhone(oldPhone, request).then((response) => {
            if (response.status === ApiStatuses.NotFound) {
              navigate(RoutesEnum.PersonalCabinet)
              return
            }

            dispatch(
              updateProfileCustomerField({ customerPhone: request.newPhone })
            )
            navigate(RoutesEnum.PersonalCabinet)
          })

          return
        }

        getValidatePhoneCustomer(customerPhone).then((response) => {
          if (response.CustomerID) {
            navigate(RoutesEnum.PersonalCabinet)

            setCookies(CookieEnum.CustomerID, response.CustomerID)
            dispatch(
              updateProfileCustomerField({ customerID: response.CustomerID })
            )

            getCustomerData(response.CustomerID).then((data) => {
              if (data instanceof AxiosError) {
                dispatch(setErrorState(true))
                return
              }

              if (data.CustomerName) {
                dispatch(updateProfileDataFromServer(data))
              }
            })

            return
          }

          navigate(RoutesEnum.Register)
        })
      })
    }
  }, [verificationCode])

  const close = () => {
    if (isCabinetPathName) {
      navigate(RoutesEnum.PersonalCabinet)
      return
    }

    if (isAnalysisPathName) {
      navigate(RoutesEnum.Analysis)
      return
    }

    navigate(RoutesEnum.Main)
  }

  const back = () => {
    if (isCabinetPathName) {
      navigate(`${RoutesEnum.PersonalCabinet + RoutesEnum.ChangePhone}`)
      return
    }

    if (isAnalysisPathName) {
      navigate(RoutesEnum.Analysis + RoutesEnum.Auth)
      return
    }

    navigate(RoutesEnum.Auth)
  }

  const handleInterval = () => {
    const interval = setInterval(() => {
      setCallTimer((prevState) => {
        if (prevState === 1) {
          clearInterval(interval)
          setIsRestartCall(true)
        }

        return prevState - 1
      })
    }, 1000)

    return interval
  }

  const restartCall = () => {
    inputNode.current.focus()

    setCallTimer(10)
    setIsRestartCall(false)
    setVerificationCode('____')
    setCurrentVerificationCodeIndex(0)
    setIsErrorCode(false)

    setPhoneCustomerCode({
      phone: isCabinetPathName
        ? location.state.newPhoneNumber
        : Number(customerPhone),
    }).then((response) => {
      if (response.status === ApiStatuses.NotFound) {
        return
      }
    })
  }

  return (
    <div className='modal-auth' role='button' tabIndex={0} onClick={close}>
      <div
        className={clsx('modal-auth__login-container', 'modal-enter-code')}
        role='presentation'
        onClick={(event) => event.stopPropagation()}
      >
        <button
          className='modal-auth__login-container__back-btn'
          onClick={back}
        >
          <ArrowLeft />
        </button>

        <span
          className={clsx(
            'modal-auth__login-container__text',
            'modal-enter-code__phone-number'
          )}
        >
          {customerPhoneMasked}
        </span>

        {!isErrorCode && (
          <span className='modal-enter-code__info'>
            {styles[0]?.TextIDsRepository.modalLoginCofirmTooltipBeforeCounter}
            {callTimer}
            {styles[0]?.TextIDsRepository.modalLoginCofirmTooltipAfterCounter}
          </span>
        )}

        <div className='modal-enter-code__enter-code-container'>
          <InputMask
            className='modal-enter-code__enter-code-input'
            inputMode='numeric'
            mask='9999'
            maskPlaceholder='_'
            placeholder='____'
            value={verificationCode}
            onChange={(event) => {
              setVerificationCode(event.target.value)
              setCurrentVerificationCodeIndex(event.target.value.indexOf('_'))
            }}
            ref={inputNode}
          />

          {Array.from({ length: 4 }, (_, index) => (
            <div
              key={index}
              className={clsx('modal-enter-code__enter-code-number', {
                'modal-enter-code__enter-code-number-active':
                  currentVerificationCodeIndex === index,
                'modal-enter-code__enter-code-number-error': isErrorCode,
              })}
            >
              <span>{verificationCode[index]}</span>
            </div>
          ))}

          {isRestartCall && (
            <button
              className='modal-enter-code__restart-call-button'
              onClick={restartCall}
            >
              <RotateCw />
              {styles[0]?.TextIDsRepository.modalLogInWrongConfirmCallback}
            </button>
          )}
        </div>

        {isErrorCode && (
          <ErrorMessage
            message={
              styles[0]?.TextIDsRepository.modalLoginWrongCodeWarnMessage
            }
            classNames='modal-auth__login-container__error'
          />
        )}

        <button
          className='modal-auth__login-container__close-btn'
          onClick={close}
        >
          <X />
        </button>
      </div>
    </div>
  )
}

export default Login
