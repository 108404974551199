import React from 'react'
import { ShoppingCart } from 'react-feather'
import clsx from 'clsx'
import { motion, usePresence } from 'framer-motion'

import { Supplement } from '../../redux/slices/supplementsSlice'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { modalControl } from '../../redux/slices/modalSupplementSlice'
import { modalSupplementInfoControl } from '../../redux/slices/modalSupplementInfoSlice'
import Button from '../UI/Button/Button'
import { useMatchMedia } from '../../hooks/useMatchMedia'

import './styles.scss'

interface SupplementProps {
  supplement: Supplement
}

const SupplementsListItem: React.FC<SupplementProps> = ({ supplement }) => {
  const course = useAppSelector((state) => state.course.course)
  const styles = useAppSelector((state) => state.partnerInterface.styles)

  const dispatch = useAppDispatch()
  const { isMobile, isTablet, isDesktop } = useMatchMedia()
  const [isPresent, removeItem] = usePresence()

  const isContainInCourse = course.supplementsList.some(
    (item) => item.supplement.Article === supplement.Article
  )

  const handleModal = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    dispatch(modalControl({ isOpen: true, supplementId: supplement.Article }))
  }

  const handleModalInfo = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    dispatch(
      modalSupplementInfoControl({
        isOpen: true,
        supplementId: supplement.Article,
        supplementPicture: supplement.Picture,
        supplementGoodsCommercialName: supplement.GoodsCommercialName,
      })
    )
  }

  const animations = {
    initial: 'out',
    animate: isPresent ? 'in' : 'out',
    whileTap: 'tapped',
    variants: {
      in: { scaleY: 1, opacity: 1 },
      out: { scaleY: 0, opacity: 0, zIndex: -1 },
      tapped: { scale: 0.98, opacity: 0.5, transition: { duration: 0.2 } },
    },
    onAnimationComplete: () => !isPresent && removeItem(),
  }

  return (
    <motion.div
      className='list-container__body__row'
      onClick={handleModalInfo}
      tabIndex={0}
      role='button'
      {...animations}
    >
      <div className='list-container__body__column'>
        <img src={supplement.Picture} alt='' className='list-item__logo' />
        <span>{supplement.GoodsCommercialName}</span>
      </div>

      <div className='list-container__body__column'>
        <span>{supplement.CommercialDescription}</span>
      </div>

      <div className='list-container__body__column'>
        <span>
          {`${
            (supplement.CurrentPrices && supplement.CurrentPrices.toFixed(2)) ||
            0
          } ${styles[0]?.TextIDsRepository.currency}`}
        </span>
      </div>

      <div className='list-container__body__column'>
        <Button
          title={
            isMobile || isTablet ? (
              <ShoppingCart />
            ) : (
              `${
                isContainInCourse
                  ? `+1`
                  : `${styles[0]?.TextIDsRepository.formTableCartBtnLabel}`
              }`
            )
          }
          click={handleModal}
          classNames={clsx({
            contain: isContainInCourse && isDesktop,
            'list-item__mobile-button': isMobile || isTablet,
            'list-item__mobile-button-contain':
              isContainInCourse && (isMobile || isTablet),
          })}
        />
      </div>
    </motion.div>
  )
}

export default SupplementsListItem
