import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { ArrowLeft } from 'react-feather'
import { AxiosError } from 'axios'

import CoursePanel from '../CoursePanel'
import Button from '../UI/Button'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import ShortQuestionnaireItem from '../ShortQuestionnaireItem'
import {
  fillSurvey,
  FillSurveyRequest,
  getSurvey,
} from '../../utils/axiosManager'
import { addSurveyList } from '../../redux/slices/surveySlice'
import { RoutesEnum } from '../../enums/routes'
import { setErrorState } from '../../redux/slices/errorsSlice'

import './styles.scss'

const ShortQuestionnaire = () => {
  const [isAnswered, setIsAnswered] = useState(false)

  const questionnaire = useAppSelector((state) => state.survey)
  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const profileData = useAppSelector((state) => state.profileSlice)

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  useEffect(() => {
    getSurvey(profileData.customerID).then((data) => {
      if (data instanceof AxiosError) {
        dispatch(setErrorState(true))
        return
      }

      dispatch(addSurveyList(data))
    })
  }, [])

  useEffect(() => {
    const answeredValues = questionnaire.questionList.reduce(
      (acc, value) => (value.value !== null ? acc + 1 : acc),
      0
    )

    if (
      answeredValues === questionnaire.questionList.length &&
      answeredValues !== 0
    ) {
      setIsAnswered(true)
    } else {
      setIsAnswered(false)
    }
  }, [questionnaire.questionList])

  const navigateBack = () => {
    navigate(-1)
  }

  const sendSurvey = () => {
    if (isAnswered) {
      const answers = questionnaire.questionList.map((answer) => ({
        question: answer.question,
        answer: answer.value !== null && answer.value,
      }))

      const request: FillSurveyRequest = {
        header: 'Анкета',
        answers,
      }

      fillSurvey(profileData.customerID, request).then((response) => {
        if (response instanceof AxiosError) {
          dispatch(setErrorState(true))
          return
        }

        navigate(RoutesEnum.PersonalCabinet)
      })
    }
  }

  return (
    <>
      <div className='short-questions-container'>
        <div className='short-questions__wrapper'>
          <div className='short-questions__header'>
            <Button
              title={<ArrowLeft />}
              click={navigateBack}
              classNames='short-questions__header__back'
            />

            <span className='short-questions__header__title'>
              {styles[0]?.TextIDsRepository.questionnaireQuickTitle}
            </span>
          </div>

          <div className='short-questions__content'>
            <div className='short-questions__content__desc'>
              {styles[0]?.TextIDsRepository.questionnaireQuickDescription}
            </div>
            <div className='short-questions__content__questions'>
              {questionnaire.questionList.map((question, index) => {
                return (
                  <ShortQuestionnaireItem
                    key={question.id}
                    index={index + 1}
                    question={question}
                  />
                )
              })}
            </div>

            <div className='short-questions__content__send-survey-container'>
              <Button
                title='Отправить анкету'
                disabled={!isAnswered}
                click={sendSurvey}
              />
            </div>
          </div>
        </div>

        <CoursePanel />
      </div>

      <Outlet />
    </>
  )
}

export default ShortQuestionnaire
