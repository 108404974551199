import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import InputMask from 'react-input-mask'
import { motion, usePresence } from 'framer-motion'
import { X } from 'react-feather'
import clsx from 'clsx'

import Select from '../UI/Select'
import { useAppSelector } from '../../redux/hook'
import { changeRecipeTime } from '../../redux/slices/supplementOfDaySlice'
import { CheckboxType } from '../UI/Checkbox/Checkbox'
import {
  controlRecipeOfDayInSupplementRecipes,
  deleteRecipeFromDay,
  changeRecipeFrequency,
  changeClockTime,
  changeRecipeDosage,
  deleteDosageFromRecipe,
} from '../../redux/slices/modalSupplementSlice'
import {
  changeClockTimeSupplementInCourse,
  changeRecipeDosageSupplementInCourse,
  changeRecipeFrequencySupplementInCourse,
  controlRecipeOfDayInSupplementRecipesInCourse,
  deleteDosageFromRecipeSupplementInCourse,
  deleteRecipeFromDaySupplementInCourse,
  RecipeOfDay,
} from '../../redux/slices/courseSlice'
import CheckboxContainer from '../shared/CheckboxContainer'
import { useWordDeclension } from '../../hooks/useWordDeclension'
import { CourseDragVariants } from '../../redux/slices/partnerInterfaceSlice'

import './styles.scss'

type SupplementOfDayProps = {
  recipe: RecipeOfDay
  article: string
  form: string
  weekdaysCheckboxes: CheckboxType[]
  setWeekdayCheckboxes: (checkboxes: CheckboxType[]) => void
  index?: number
}

const SupplementOfDay: React.FC<SupplementOfDayProps> = ({
  recipe,
  article,
  form,
  weekdaysCheckboxes,
  setWeekdayCheckboxes,
  index,
}) => {
  const controls = useAppSelector((state) => state.modalSupplement.controls)
  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const dispatch = useDispatch()

  const [recipePeriod, setRecipePeriod] = useState<string>(recipe.id)
  const [showCheckboxes, setShowCheckboxes] = useState(
    recipePeriod !== 'daily' && index === 0
  )
  const dosageOptions = useWordDeclension(
    styles[0]?.TextIDsRepository.myCourseDrag[form as CourseDragVariants]
  )

  const [isPresent, safeToRemove] = usePresence()

  const transition = {
    type: 'spring',
    stiffness: 5000,
    damping: 100,
    mass: 0.2,
  }

  const animations = {
    layout: true,
    initial: 'out',
    animate: isPresent ? 'in' : 'out',
    variants: {
      in: { scaleY: 1, opacity: 1 },
      out: { scaleY: 0, opacity: 0 },
    },
    onAnimationComplete: () => !isPresent && safeToRemove(),
    transition,
  }

  const handleCheckboxState = (checkboxId: number) => {
    const newWeekdaysCheckboxesState = weekdaysCheckboxes.map((checkbox) => {
      if (checkbox.id === checkboxId) {
        if (!controls.isOpen) {
          dispatch(
            controlRecipeOfDayInSupplementRecipesInCourse({
              ...checkbox,
              checked: !checkbox.checked,
              article,
            })
          )
        } else {
          dispatch(
            controlRecipeOfDayInSupplementRecipes({
              ...checkbox,
              checked: !checkbox.checked,
              article,
            })
          )
        }

        return { ...checkbox, checked: !checkbox.checked }
      }

      return checkbox
    })

    setWeekdayCheckboxes(newWeekdaysCheckboxesState)
  }

  const mask = (time: string) => {
    return [
      /[0-2]/,
      time.startsWith('2') ? /[0-3]/ : /[0-9]/,
      ':',
      /[0-5]/,
      /[0-9]/,
    ]
  }

  const handleRecipeTime = (value: string) => {
    setRecipePeriod(value)
    if (value === styles[0].TextIDsRepository.myCourseDaily) {
      dispatch(changeRecipeTime('daily'))
    } else {
      dispatch(changeRecipeTime('weekly'))
      setShowCheckboxes(true)
    }
  }

  const handleRecipeFrequency = (value: string) => {
    if (!controls.isOpen) {
      dispatch(
        changeRecipeFrequencySupplementInCourse({
          value: value,
          recipeId: recipe.id,
          article,
        })
      )
    } else {
      dispatch(changeRecipeFrequency({ value: value, recipeId: recipe.id }))
    }
  }

  const handleRecipeDosage = (id: string, value: string) => {
    const tempDosage = recipe.recipeDosage.find((item) => item.id === id)

    if (tempDosage) {
      if (!controls.isOpen) {
        dispatch(
          changeRecipeDosageSupplementInCourse({
            ...tempDosage,
            dosage: value,
            recipeId: recipe.id,
            article,
          })
        )
      } else {
        dispatch(
          changeRecipeDosage({
            ...tempDosage,
            dosage: value,
            recipeId: recipe.id,
          })
        )
      }
    }
  }

  const handleClockTime = (time: string, value: string) => {
    const tempDosage = recipe.recipeDosage.find((item) => item.time === time)

    if (tempDosage) {
      if (!controls.isOpen) {
        dispatch(
          changeClockTimeSupplementInCourse({
            ...tempDosage,
            time: value,
            recipeId: recipe.id,
            article,
          })
        )
      } else {
        dispatch(
          changeClockTime({ ...tempDosage, time: value, recipeId: recipe.id })
        )
      }
    }
  }

  const deleteDosageFromDay = (id: string) => {
    if (!controls.isOpen) {
      dispatch(
        deleteDosageFromRecipeSupplementInCourse({
          id,
          article,
          recipeId: recipe.id,
        })
      )
    } else {
      dispatch(deleteDosageFromRecipe({ id, recipeId: recipe.id }))
    }
  }

  const deleteDayFromRecipe = (id: string) => {
    if (!controls.isOpen) {
      dispatch(deleteRecipeFromDaySupplementInCourse({ recipeId: id, article }))
    } else {
      dispatch(deleteRecipeFromDay(id))
    }
  }

  return (
    <motion.div className='supplement-of-day-container' {...animations}>
      <div className='modal-content__controls-first'>
        <label className='modal-content__controls-first__label'>
          <span>{styles[0]?.TextIDsRepository.myCourseHowToUse}</span>

          <div className='supplement-of-day-container__select'>
            {recipePeriod !== 'daily' ? (
              <button
                className='delete-day-button'
                onClick={() => setShowCheckboxes((prevState) => !prevState)}
              >
                {recipe.id === 'daily'
                  ? styles[0].TextIDsRepository.myCourseWeekly
                  : recipe.id}
              </button>
            ) : (
              <Select
                value={recipePeriod}
                onChange={(value) => handleRecipeTime(value)}
                options={[
                  styles[0].TextIDsRepository.myCourseDaily,
                  styles[0].TextIDsRepository.myCourseWeekly,
                ]}
              />
            )}

            {recipe.id !== 'daily' ? (
              <button
                className='modal-content__controls-second__del-button'
                onClick={() => deleteDayFromRecipe(recipe.id)}
              >
                <X />
              </button>
            ) : null}
          </div>
        </label>

        {showCheckboxes && (
          <CheckboxContainer
            weekdaysCheckboxes={weekdaysCheckboxes}
            setShowCheckboxes={setShowCheckboxes}
            handleCheckboxState={handleCheckboxState}
            article={article}
          />
        )}

        <label
          className={clsx(
            'modal-content__controls-first__freq-select',
            'modal-content__controls-first__label'
          )}
        >
          <span>{styles[0]?.TextIDsRepository.myCourseHowMuch}</span>

          <Select
            value={recipe.recipeFrequency}
            onChange={handleRecipeFrequency}
            options={['1', '2', '3', '4', '5']}
          />
        </label>
      </div>

      <div className='modal-content__controls-second'>
        {recipe.recipeDosage.map((dosage, index) => {
          const inputTimeMask = mask(recipe.recipeDosage[index].time)

          return (
            <div
              key={dosage.id}
              className='modal-content__controls-second__child'
            >
              <label className='modal-content__controls-second__child__label'>
                <span>{styles[0]?.TextIDsRepository.myCourseWhere}</span>

                <InputMask
                  className='modal-content__controls-second__input'
                  inputMode='numeric'
                  mask={inputTimeMask}
                  placeholder='__:__'
                  value={recipe.recipeDosage[index].time}
                  onChange={(event) =>
                    handleClockTime(
                      recipe.recipeDosage[index].time,
                      event.target.value
                    )
                  }
                  onFocus={(event) => event.target.setSelectionRange(0, 0)}
                />
              </label>

              <label className='modal-content__controls-second__child__label'>
                <span>{styles[0]?.TextIDsRepository.myCourseDosage}</span>

                <Select
                  value={dosageOptions[Number(dosage.dosage[0]) - 1]}
                  onChange={(event) => handleRecipeDosage(dosage.id, event)}
                  options={dosageOptions}
                />
              </label>

              <button
                className='modal-content__controls-second__del-button'
                disabled={recipe.recipeDosage.length === 1}
                onClick={() => deleteDosageFromDay(dosage.id)}
              >
                <X />
              </button>
            </div>
          )
        })}
      </div>
    </motion.div>
  )
}

export default React.memo(SupplementOfDay)
