import React, { useState } from 'react'
import { RegisterOptions } from 'react-hook-form'
import clsx from 'clsx'

import { SelectCustomerCityItem } from '../../../utils/axiosManager'

import './styles.scss'

type AutoCompleteInputProps = {
  classNames: string
  placeholder: string
  register: any
  value: string
  setValue: any
  registerLabel: string
  dataList: SelectCustomerCityItem[]
  optionalEditValue?: string
  registerOptions?: RegisterOptions
  containerClassNames?: string
  onClick?: () => void
}

const AutoCompleteInput: React.FC<AutoCompleteInputProps> = ({
  classNames,
  placeholder,
  register,
  value,
  setValue,
  registerLabel,
  optionalEditValue,
  dataList,
  registerOptions,
  onClick,
  containerClassNames,
}) => {
  const [isFocus, setIsFocus] = useState(false)

  const chooseLocationName = (
    event: React.MouseEvent<HTMLButtonElement>,
    option: SelectCustomerCityItem
  ) => {
    event.stopPropagation()
    setValue(optionalEditValue, option.CustomerCityID)
    setValue(registerLabel, option.FullName)
  }

  return (
    <>
      <input
        autoComplete='off'
        className={clsx(classNames)}
        placeholder={placeholder}
        onClick={onClick}
        onFocus={() => setIsFocus(true)}
        {...register(registerLabel, {
          ...registerOptions,
          onBlur: () => setIsFocus(false),
        })}
      />

      {isFocus && (
        <div
          className={clsx(
            'autocomplete-dropdown-container',
            containerClassNames
          )}
        >
          {dataList.map((option) => {
            let firstNormalPartString = ''

            const firstIndex = option.FullName.toLowerCase().indexOf(
              value.toLowerCase()
            )

            const boldPartString = option.FullName.slice(
              firstIndex,
              firstIndex + value.length
            )

            if (firstIndex !== 0) {
              // пропадал пробел в конце строки
              firstNormalPartString =
                option.FullName.slice(0, firstIndex) + '\xA0'
            }

            const secondNormalPartString = option.FullName.slice(
              firstNormalPartString.length + value.length
            )

            return (
              <button
                key={option.CustomerCityID}
                className='autocomplete-button'
                onMouseDown={(event) => chooseLocationName(event, option)}
              >
                <span>{firstNormalPartString}</span>{' '}
                <span className='autocomplete-button__bold-text'>
                  {boldPartString}
                </span>
                <span>{secondNormalPartString}</span>
              </button>
            )
          })}
        </div>
      )}
    </>
  )
}

export default AutoCompleteInput
