import React, { useEffect, useMemo, useState } from 'react'
import InputMask from 'react-input-mask'
import { Controller, useForm } from 'react-hook-form'
import { HelpCircle } from 'react-feather'
import { useNavigate } from 'react-router-dom'

import { AxiosError } from 'axios'
import clsx from 'clsx'

import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import Button from '../../UI/Button'
import AutoCompleteInput from '../../UI/AutoCompleteInput'
import ErrorMessage from '../../ErrorMessage/ErrorMessage'
import { RegisterFormFields } from '../../Register/Register'
import { dateInputMaskValidate } from '../../../utils/dateInputMaskValidate'
import {
  getOrdersList,
  OrdersListItem,
  OrdersListItemSmall,
  SelectCustomerCityItem,
} from '../../../utils/axiosManager'
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber'
import { replaceInputValue } from '../../../utils/replaceInputValue'
import { Regexp } from '../../../enums/regexp'
import CourseInDeliveryCard from '../../CourseInDeliveryCard'
import { setErrorState } from '../../../redux/slices/errorsSlice'
import { RoutesEnum } from '../../../enums/routes'
import PortalTooltip from '../../UI/PortalTooltip'
import ProfileAbout from '../ProfileAbout'
import { customerEditData } from '../../../models/customer/api'
import { updateProfileData } from '../../../redux/slices/profileSlice'

import './styles.scss'

const EmailRegExpPattern = new RegExp(Regexp.Email)

const ProfileData = () => {
  const [dateInputMask, setDateInputMask] = useState<(string | RegExp)[]>([])
  const [ordersList, setOrdersList] = useState<
    Array<OrdersListItem & OrdersListItemSmall>
  >([])
  const [locationsList] = useState<SelectCustomerCityItem[]>([])
  const [currentOpenCourseDeliveryCard, setCurrentOpenCourseDeliveryCard] =
    useState(0)
  const profileData = useAppSelector((state) => state.profileSlice)
  const { styles } = useAppSelector((state) => state.partnerInterface)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const {
    register,
    watch,
    control,
    setValue,
    reset,
    formState: { isValid, isValidating, isDirty, errors },
  } = useForm<RegisterFormFields>({
    defaultValues: {
      CustomerSurname: profileData.customerSurname,
      CustomerName: profileData.customerName,
      CustomerPatronymic: profileData.customerPatronymic,
      CustomerDateOfBirth: profileData.customerDateOfBirth,
      CustomerCityID: profileData.customerCityID,
      CustomerCity: profileData.customerCity,
      CustomerEmail: profileData.customerEmail,
    },
    mode: 'onChange',
  })

  const formData = watch()
  const watchCustomerCityFullName = watch('CustomerCity')

  const phoneNumber = useMemo(
    () => formatPhoneNumber(profileData.customerPhone.toString()),
    [profileData.customerPhone]
  )

  useEffect(() => {
    if (profileData.customerID) {
      const mask = dateInputMaskValidate(
        profileData.customerDateOfBirth ? profileData.customerDateOfBirth : ''
      )
      setDateInputMask(mask)

      getOrdersList(profileData.customerID).then((data) => {
        if (data instanceof AxiosError) {
          dispatch(setErrorState(true))
          return
        }

        setOrdersList(data.OrdersList)
      })

      reset({
        CustomerSurname: profileData.customerSurname,
        CustomerName: profileData.customerName,
        CustomerPatronymic: profileData.customerPatronymic,
        CustomerDateOfBirth: profileData.customerDateOfBirth,
        CustomerCityID: profileData.customerCityID,
        CustomerCity: profileData.customerCity,
        CustomerEmail: profileData.customerEmail,
      })
    }
  }, [profileData])

  useEffect(() => {
    const debounceGetData = setTimeout(() => {
      if (isValid && !isValidating && isDirty && !Object.keys(errors).length) {
        const { CustomerPhone, ...requestValue } = formData

        customerEditData(profileData.customerID, requestValue).then(
          (response) => {
            if (response instanceof AxiosError) {
              dispatch(setErrorState(true))
              return
            }

            dispatch(updateProfileData(response))
          }
        )
      }
    }, 500)

    return () => clearTimeout(debounceGetData)
  }, [isValid, isValidating, isDirty, errors])

  const followToChooseCourse = () => {
    navigate(RoutesEnum.Main, {
      state: {
        activeCatalogItem: 0,
      },
    })
  }

  const followToChangePhone = () => {
    navigate(RoutesEnum.PersonalCabinet + RoutesEnum.ChangePhone)
  }

  return (
    <div className='profile-data-container'>
      <div className='profile-data-container__header'>
        <span className='profile-data-container__header__title'>
          {phoneNumber}
        </span>
        <button
          className='profile-data-container__header__button'
          onClick={followToChangePhone}
        >
          {styles[0]?.TextIDsRepository.personalAccountChangePhone}
        </button>

        <PortalTooltip
          text={styles[0]?.TextIDsRepository.personalAccountChangePhoneTooltip}
          classNames='profile-data-container__header__info-tooltip'
        >
          <span className='profile-data-container__header__info'>
            <HelpCircle />
          </span>
        </PortalTooltip>
      </div>

      <form className='profile-data-container__form'>
        <label
          className={clsx({
            'modal-register__form-container__label-input-error':
              errors.CustomerSurname?.message,
          })}
        >
          <span className='modal-register__form-container__label-text'>
            {styles[0]?.TextIDsRepository.modalLoginLastName}
          </span>
          <input
            className='modal-register__form-container__label-input'
            placeholder={styles[0]?.TextIDsRepository.modalLoginLastName}
            onInput={replaceInputValue}
            {...register('CustomerSurname', {
              required: {
                value: true,
                message:
                  styles[0]?.TextIDsRepository.modalLoginLastNameWarnMessage,
              },
            })}
          />
        </label>

        <label
          className={clsx({
            'modal-register__form-container__label-input-error':
              errors.CustomerName?.message,
          })}
        >
          <span className='modal-register__form-container__label-text'>
            {styles[0]?.TextIDsRepository.modalLoginFirstName}
          </span>
          <input
            className='modal-register__form-container__label-input'
            placeholder={styles[0]?.TextIDsRepository.modalLoginFirstName}
            onInput={replaceInputValue}
            {...register('CustomerName', {
              required: {
                value: true,
                message:
                  styles[0]?.TextIDsRepository.modalLoginFirstNameWarnMessage,
              },
            })}
          />
        </label>

        <label
          className={clsx({
            'modal-register__form-container__label-input-error':
              errors.CustomerPatronymic?.message,
          })}
        >
          <span className='modal-register__form-container__label-text'>
            {styles[0]?.TextIDsRepository.modalLoginSecondName}
          </span>
          <input
            className='modal-register__form-container__label-input'
            placeholder={styles[0]?.TextIDsRepository.modalLoginSecondName}
            onInput={replaceInputValue}
            {...register('CustomerPatronymic', {
              required: {
                value: true,
                message:
                  styles[0]?.TextIDsRepository.modalLoginSecondNameWarnMessage,
              },
            })}
          />
        </label>

        <label
          className={clsx({
            'modal-register__form-container__label-input-error':
              errors.CustomerDateOfBirth?.message,
          })}
        >
          <span className='modal-register__form-container__label-text'>
            {styles[0]?.TextIDsRepository.modalLoginBirthdateName}

            <Controller
              name='CustomerDateOfBirth'
              control={control}
              defaultValue={profileData.customerDateOfBirth}
              rules={{
                required: {
                  value: true,
                  message:
                    styles[0]?.TextIDsRepository.modalLoginBirthdateWarnMessage,
                },
                minLength: {
                  value: 10,
                  message:
                    styles[0]?.TextIDsRepository.modalLoginBirthdateWarnMessage,
                },
                onChange: (event) => {
                  const mask = dateInputMaskValidate(event.target.value)
                  setDateInputMask(mask)
                },
              }}
              render={({ field }) => (
                <InputMask
                  mask={dateInputMask}
                  className='modal-register__form-container__label-input'
                  maskPlaceholder={null}
                  placeholder={
                    styles[0]?.TextIDsRepository
                      .modalLoginBirthdateNamePlaceholder
                  }
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </span>
        </label>

        <label
          className={clsx({
            'modal-register__form-container__label-input-error':
              errors.CustomerCity?.message,
          })}
        >
          <span
            className={clsx('modal-register__form-container__label-text', {
              'error-message-text': errors.CustomerCity,
            })}
          >
            {styles[0]?.TextIDsRepository.modalLoginCity}
          </span>
          <AutoCompleteInput
            classNames={clsx('modal-register__form-container__label-input', {
              'error-message-input': errors.CustomerCity,
            })}
            placeholder={styles[0]?.TextIDsRepository.modalLoginCity}
            registerLabel='CustomerCity'
            optionalEditValue='CustomerCityID'
            register={register}
            value={watchCustomerCityFullName}
            setValue={setValue}
            dataList={locationsList}
            registerOptions={{
              required: {
                value: true,
                message: styles[0]?.TextIDsRepository.modalLoginCityWarnMessage,
              },
            }}
          />
        </label>

        <label
          className={clsx({
            'modal-register__form-container__label-input-error':
              errors.CustomerEmail?.message,
          })}
        >
          <span className='modal-register__form-container__label-text'>
            {styles[0]?.TextIDsRepository.modalLoginEMailName}
          </span>
          <input
            className='modal-register__form-container__label-input'
            placeholder='xiyq1vck7@temporaremail.com'
            {...register('CustomerEmail', {
              required: {
                value: true,
                message:
                  styles[0]?.TextIDsRepository.modalLoginEMailWarnMessage,
              },
              pattern: {
                value: EmailRegExpPattern,
                message:
                  styles[0]?.TextIDsRepository.modalLoginEMailWarnMessage,
              },
            })}
          />
        </label>
      </form>

      {Object.keys(errors).length ? (
        <ErrorMessage
          message={
            errors.CustomerName?.message ||
            errors.CustomerEmail?.message ||
            errors.CustomerSurname?.message ||
            errors.CustomerPatronymic?.message ||
            errors.CustomerDateOfBirth?.message
          }
          classNames='profile-data-container__form__errors'
        />
      ) : null}

      <div className='profile-data-container__course'>
        <span className='profile-data-container__course__title'>
          {styles[0]?.TextIDsRepository.personalAccountMyCoursesTitle}
        </span>

        {ordersList.length ? (
          <div className='profile-data-container__course__cards-container'>
            {ordersList.map((order, index) => {
              return (
                <CourseInDeliveryCard
                  key={order.OrderNumberPlatform}
                  order={order}
                  index={index}
                  currentOpenCourseDeliveryCard={currentOpenCourseDeliveryCard}
                  setCurrentOpenCourseDeliveryCard={
                    setCurrentOpenCourseDeliveryCard
                  }
                />
              )
            })}
          </div>
        ) : (
          <>
            <span className='profile-data-container__course__description'>
              {styles[0]?.TextIDsRepository.myCourseInvitation}
            </span>

            <Button
              classNames='profile-data-container__course__button'
              title={styles[0]?.TextIDsRepository.personalAccountSelectCourse}
              click={followToChooseCourse}
            />
          </>
        )}
      </div>

      {profileData.nutritionist && <ProfileAbout />}
    </div>
  )
}

export default ProfileData
