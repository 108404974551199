import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { AxiosError } from 'axios'

import SpecialistsSideBar from './SpecialistsSideBar'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import SpecialistCard from './SpecialistCard'
import { useMatchMedia } from '../../hooks/useMatchMedia'
import { getSpecialists } from '../../models/specialist/api'
import {
  attachSpecialist,
  setSpecialistsList,
} from '../../redux/slices/specialistsSlice'
import { ApiStatuses } from '../../enums/apiRoutes'

import './styles.scss'

const SpecialistsPage = () => {
  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const specialistsList = useAppSelector((state) => state.specialists.list)
  const customerId = useAppSelector((state) => state.profileSlice.customerID)
  const attachedSpecialistId = useAppSelector(
    (state) => state.profileSlice.nutritionistId
  )

  const { isMobile } = useMatchMedia()

  const dispatch = useAppDispatch()

  useEffect(() => {
    getSpecialists().then((response) => {
      if (
        response instanceof AxiosError &&
        response.response?.status === ApiStatuses.NotFound
      ) {
        return
      }

      dispatch(setSpecialistsList(response))
      if (attachedSpecialistId) {
        const currentSpecialist = response.find(
          (specialist) => specialist.CustomerID === attachedSpecialistId
        )

        if (currentSpecialist) {
          dispatch(attachSpecialist(currentSpecialist))
        }
      }
    })
  }, [])

  return (
    <>
      <div className='specialists-page'>
        <div className='specialists-page__wrapper'>
          {!isMobile && (
            <span className='specialists-page__title'>
              {styles[0]?.TextIDsRepository.specialistPageTitle}
            </span>
          )}

          <div className='specialists-page__list'>
            {specialistsList.map(
              (specialist) =>
                customerId !== specialist.CustomerID && (
                  <SpecialistCard
                    key={specialist.CustomerID}
                    specialist={specialist}
                  />
                )
            )}
          </div>
        </div>

        <SpecialistsSideBar />
      </div>

      <Outlet />
    </>
  )
}

export default SpecialistsPage
