import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import './styles.scss'

type TextAreaProps = {
  value?: string
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  defaultValue?: string
  placeholder?: string
  withCounter?: number
}

const TextArea: React.FC<TextAreaProps> = ({
  value,
  onChange,
  defaultValue,
  placeholder,
  withCounter,
}) => {
  const [valueLength, setValueLength] = useState(0)

  useEffect(() => {
    setValueLength(value && value.length ? value.length : 0)
  }, [value])

  return (
    <div className='text-area-container'>
      <textarea
        className='text-area'
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />

      {withCounter && (
        <span className='text-area-container__counter'>
          <span
            className={clsx({
              'text-area-container__counter__active': valueLength > 0,
            })}
          >
            {valueLength}
          </span>
          /
          <span className='text-area-container__counter__default'>
            {withCounter}
          </span>
        </span>
      )}
    </div>
  )
}

export default TextArea
