import React from 'react'

import clsx from 'clsx'

import { useAppSelector } from '../../../redux/hook'
import { SupplementFacts } from '../../../redux/slices/modalSupplementInfoSlice'
import './styles.scss'

type SupplementFactsType = {
  supplementFacts: SupplementFacts[]
  classNames?: string
}
const SupplementFactsTable: React.FC<SupplementFactsType> = ({
  supplementFacts,
  classNames,
}) => {
  const { styles } = useAppSelector((state) => state.partnerInterface)

  return (
    <div className={clsx('facts-table-container', classNames)}>
      <table className='facts-table'>
        <thead>
          <tr>
            <td>
              {
                styles[0]?.TextIDsRepository
                  .modalSupplementCardNutrientComposition
              }
            </td>
            <td>
              {
                styles[0].TextIDsRepository
                  .modalSupplementCardSuppFactsActiveComponentsWeigth
              }
            </td>
            <td>
              {
                styles[0].TextIDsRepository
                  .modalSupplementCardSuppFactsAUPPercent
              }
            </td>
          </tr>
        </thead>

        <tbody>
          {supplementFacts &&
            supplementFacts.length > 0 &&
            supplementFacts.map((fact) => {
              return (
                <tr key={fact.ActiveComponents}>
                  <td>
                    {fact.ActiveComponents} ({fact.ActiveComponentsRaw})
                  </td>
                  <td>{`${fact.ActiveComponentsWeigth} ${fact.Units}`}</td>
                  <td>
                    {`${Math.round(
                      (fact.RecomendatedWeigth / fact.ActiveComponentsWeigth) *
                        100
                    )} %`}
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </div>
  )
}

export default SupplementFactsTable
