import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import Switch from '../../UI/Switch'

import './styles.scss'

type ContactContainerType = {
  icon: string
  value: string | null
  setValue: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const ContactContainer: React.FC<ContactContainerType> = ({
  icon,
  value,
  setValue,
}) => {
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (value) {
      setChecked(true)
    }
  }, [value])

  return (
    <div className='contact-container'>
      <div className='contact-container__switch'>
        <Switch checked={checked} setChecked={setChecked} />

        <img src={icon} alt='' />

        <input
          className={clsx('contact-container__switch__input')}
          value={checked && value ? value : ''}
          onChange={setValue}
          disabled={!checked}
        />
      </div>
    </div>
  )
}

export default ContactContainer
