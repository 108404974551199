import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import {
  RecipeDosageType,
  RecipeOfDay,
  SupplementInCourse,
} from './courseSlice'
import { CheckboxType } from '../../components/UI/Checkbox/Checkbox'
import {
  createValueFromFrequency,
  initialRecipeDosageValue,
} from './supplementOfDaySlice'
import { returnToOriginalFormatDayName } from '../../utils/formatDayName'

export type ModalControl = { isOpen: boolean; supplementId: string }

export type ModalSupplementState = {
  controls: ModalControl
  recipes: RecipeOfDay[]
}

export const initialRecipeState = () => {
  return [
    {
      id: 'daily',
      recipeFrequency: '1',
      recipeDosage: [initialRecipeDosageValue()],
    },
  ]
}

const initialState: ModalSupplementState = {
  controls: { isOpen: false, supplementId: '' },
  recipes: [],
}

export type CourseItemToServer = {
  Article: string
  SupplementQuantity: number
  SupplementPrice: number
  ReceptionTime: string
  ReceptionDay: string
}

export type RecipeFrequencyType = { value: string; recipeId: string }

export const convertDataForChangeCourse = (
  supplementsList: SupplementInCourse[]
) => {
  const arrayToServer: CourseItemToServer[] = []

  supplementsList.forEach((supplement) => {
    supplement.recipes.forEach((recipe) => {
      recipe.recipeDosage.forEach((dosage) => {
        arrayToServer.push({
          Article: supplement.supplement.Article,
          SupplementQuantity: Number(dosage.dosage[0]),
          SupplementPrice: supplement.supplement.CurrentPrices,
          ReceptionTime: dosage.time,
          ReceptionDay: returnToOriginalFormatDayName(recipe.id),
        })
      })
    })
  })

  return arrayToServer
}

const modalSupplementSlice = createSlice({
  name: 'modalSupplement',
  initialState,
  reducers: {
    modalControl(state, action: PayloadAction<ModalControl>) {
      if (action.payload.isOpen) {
        return {
          controls: action.payload,
          recipes: initialRecipeState(),
        }
      }

      return {
        controls: action.payload,
        recipes: [],
      }
    },
    controlRecipeOfDayInSupplementRecipes(
      state,
      action: PayloadAction<CheckboxType & { article: string }>
    ) {
      if (action.payload.checked) {
        const recipesWithoutDaily = state.recipes.filter(
          (item) => item.id !== 'daily'
        )

        return {
          ...state,
          recipes: !recipesWithoutDaily.length
            ? [
                {
                  id: action.payload.value,
                  recipeFrequency: '1',
                  recipeDosage: [initialRecipeDosageValue()],
                },
              ]
            : [
                ...recipesWithoutDaily,
                {
                  id: action.payload.value,
                  recipeFrequency: '1',
                  recipeDosage: [initialRecipeDosageValue()],
                },
              ],
        }
      }

      const recipesWithoutDay = current(state).recipes.filter(
        (recipe) => recipe.id !== action.payload.value
      )

      if (!recipesWithoutDay.length) {
        return {
          ...state,
          recipes: initialRecipeState(),
        }
      }

      return {
        ...state,
        recipes: recipesWithoutDay,
      }
    },
    deleteRecipeFromDay(state, action: PayloadAction<string>) {
      const filteredRecipes = state.recipes.filter(
        (recipe) => recipe.id !== action.payload
      )

      if (filteredRecipes.length === 0) {
        return {
          ...state,
          recipes: initialRecipeState(),
        }
      }

      return {
        ...state,
        recipes: filteredRecipes,
      }
    },
    deleteDosageFromRecipe(
      state,
      action: PayloadAction<{
        id: string
        recipeId: string
      }>
    ) {
      const currentRecipe = current(state).recipes.filter(
        (recipe) => recipe.id === action.payload.recipeId
      )[0]

      const updatedFrequency = (
        Number(currentRecipe.recipeFrequency) - 1
      ).toString()
      const updatedDosage = currentRecipe.recipeDosage.filter(
        (dosage) => dosage.id !== action.payload.id
      )

      return {
        ...state,
        recipes: state.recipes.map((recipe) =>
          recipe.id === action.payload.recipeId
            ? {
                ...recipe,
                recipeFrequency: updatedFrequency,
                recipeDosage: updatedDosage,
              }
            : recipe
        ),
      }
    },
    changeRecipeFrequency(state, action: PayloadAction<RecipeFrequencyType>) {
      const payloadValue = Number(action.payload.value)
      const recipeId = action.payload.recipeId
      const currentRecipe = current(state).recipes.filter(
        (recipe) => recipe.id === recipeId
      )[0]
      const currentRecipeFrequency = Number(currentRecipe.recipeFrequency)

      if (payloadValue > currentRecipeFrequency) {
        const newRecipeDosage = createValueFromFrequency(
          payloadValue,
          currentRecipeFrequency,
          initialRecipeDosageValue
        ) as RecipeDosageType[]

        return {
          ...state,
          recipes: state.recipes.map((recipe) =>
            recipe.id === recipeId
              ? {
                  ...recipe,
                  recipeFrequency: action.payload.value,
                  recipeDosage: [...recipe.recipeDosage, ...newRecipeDosage],
                }
              : recipe
          ),
        }
      } else {
        const recipeDosageWithoutLast = currentRecipe.recipeDosage.slice(
          0,
          payloadValue
        )

        return {
          ...state,
          recipes: state.recipes.map((recipe) =>
            recipe.id === recipeId
              ? {
                  ...recipe,
                  recipeFrequency: action.payload.value,
                  recipeDosage: recipeDosageWithoutLast,
                }
              : recipe
          ),
        }
      }
    },
    changeClockTime(
      state,
      action: PayloadAction<RecipeDosageType & { recipeId: string }>
    ) {
      return {
        ...state,
        recipes: state.recipes.map((recipe) =>
          recipe.id === action.payload.recipeId
            ? {
                ...recipe,
                recipeDosage: recipe.recipeDosage.map((dosage) =>
                  dosage.id === action.payload.id
                    ? {
                        ...dosage,
                        time: action.payload.time,
                      }
                    : dosage
                ),
              }
            : recipe
        ),
      }
    },
    changeRecipeDosage(
      state,
      action: PayloadAction<RecipeDosageType & { recipeId: string }>
    ) {
      return {
        ...state,
        recipes: state.recipes.map((recipe) =>
          recipe.id === action.payload.recipeId
            ? {
                ...recipe,
                recipeDosage: recipe.recipeDosage.map((dosage) =>
                  dosage.id === action.payload.id
                    ? { ...dosage, dosage: action.payload.dosage }
                    : dosage
                ),
              }
            : recipe
        ),
      }
    },
  },
})

export const {
  modalControl,
  controlRecipeOfDayInSupplementRecipes,
  deleteRecipeFromDay,
  deleteDosageFromRecipe,
  changeRecipeFrequency,
  changeClockTime,
  changeRecipeDosage,
} = modalSupplementSlice.actions

export default modalSupplementSlice.reducer
