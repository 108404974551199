import React from 'react'

import { useAppSelector } from '../../redux/hook'

import './styles.scss'

const ErrorPage = () => {
  const { styles } = useAppSelector((state) => state.partnerInterface)

  return (
    <div className='error-page'>
      <div className='error-page__code-wrapper'>
        <img
          className='error-page__code-wrapper__pills-icon'
          src={styles[0]?.TextIDsRepository.assets.pillsIcon}
          alt=''
        />
        <span className='error-page__code-wrapper__code'>404</span>
        <img
          className='error-page__code-wrapper__capsules-icon'
          src={styles[0]?.TextIDsRepository.assets.capsulesIcon}
          alt=''
        />
      </div>

      <div className='error-page__title'>
        {styles[0] && styles[0].TextIDsRepository.errorPageTitle}
      </div>
    </div>
  )
}

export default ErrorPage
