import React, { useEffect, useState } from 'react'
import { X } from 'react-feather'
import InputMask from 'react-input-mask'
import { useNavigate } from 'react-router-dom'

import { AxiosError } from 'axios'
import { motion } from 'framer-motion'
import clsx from 'clsx'

import {
  updateCustomerCountry,
  updateCustomerPhoneAndID,
} from '../../redux/slices/authSlice'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { getCustomerCountry } from '../../utils/axiosManager'
import Button from '../UI/Button'
import { useDefineCurrentPathName } from '../../hooks/useDefineCurrentPathName'
import { setErrorState } from '../../redux/slices/errorsSlice'
import { RoutesEnum } from '../../enums/routes'
import { setPhoneCustomerCode } from '../../models/auth/api'
import { ApiStatuses } from '../../enums/apiRoutes'

import './styles.scss'

const Auth: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [error, setError] = useState(false)

  const customerCountry = useAppSelector((state) => state.auth.customerCountry)
  const styles = useAppSelector((state) => state.partnerInterface.styles)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isCabinetPathName = useDefineCurrentPathName('/cabinet/*')
  const isCabinetAuthPathName = useDefineCurrentPathName('/cabinet/auth')
  const isAnalysisPathName = useDefineCurrentPathName(
    `${RoutesEnum.Analysis}/*`
  )

  useEffect(() => {
    getCustomerCountry().then((data) => {
      if (data instanceof AxiosError) {
        dispatch(setErrorState(true))
        return
      }

      dispatch(updateCustomerCountry(data))
    })
  }, [])

  const close = () => {
    if (isCabinetPathName) {
      navigate(RoutesEnum.PersonalCabinet)
      return
    }

    if (isAnalysisPathName) {
      navigate(RoutesEnum.Analysis)
      return
    }

    navigate(RoutesEnum.Main)
  }

  const checkPhoneIsFilled = () => {
    return isNaN(Number(phoneNumber.slice(-1)))
  }

  const handlePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const processedPhoneNumber = event.target.value.substring(1)
    setPhoneNumber(processedPhoneNumber)
  }

  const getPhoneCode = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    setPhoneCustomerCode({ phone: Number(phoneNumber) }).then((response) => {
      if (
        response.status === ApiStatuses.ServerError ||
        response.status === ApiStatuses.NotFound
      ) {
        setError(true)
        return
      }

      if (isCabinetAuthPathName) {
        navigate(RoutesEnum.PersonalCabinet + RoutesEnum.EnterCode, {
          state: {
            newClient: true,
            newPhoneNumber: phoneNumber,
          },
        })
        return
      }

      if (isCabinetPathName) {
        navigate(RoutesEnum.PersonalCabinet + RoutesEnum.EnterCode, {
          state: {
            newPhoneNumber: phoneNumber,
          },
        })
        return
      }

      if (isAnalysisPathName) {
        navigate(RoutesEnum.Analysis + RoutesEnum.Login)
        return
      }

      dispatch(updateCustomerPhoneAndID({ customerId: '', phoneNumber }))

      navigate(RoutesEnum.Login)
    })
  }

  return (
    <motion.div
      className='modal-auth'
      role='button'
      tabIndex={0}
      onClick={close}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.2 } }}
    >
      <motion.div
        className='modal-auth__login-container'
        role='presentation'
        onClick={(event) => event.stopPropagation()}
        initial={{ scale: 0.5 }}
        animate={{ scale: 1, transition: { duration: 0.2 } }}
      >
        <img
          className='modal-auth__login-container__logo'
          src={styles[0]?.PartnerLogo}
          alt=''
        />

        <span className='modal-auth__login-container__text'>
          {isCabinetPathName
            ? styles[0]?.TextIDsRepository.modalLoginTitle2
            : styles[0]?.TextIDsRepository.modalLoginTitle1}
        </span>

        <div
          className={clsx(
            'modal-auth__login-container__phone-number-container',
            {
              'modal-auth__login-container__phone-number-container__error':
                error,
            }
          )}
        >
          <img
            className='modal-auth__login-container__phone-number-container__logo'
            src={customerCountry.countryFlag}
            alt=''
          />

          <InputMask
            className={
              'modal-auth__login-container__phone-number-container__input'
            }
            inputMode='tel'
            mask={`+${customerCountry.callingCode}9999999999`}
            maskPlaceholder='_'
            placeholder={`+${customerCountry.callingCode} _ _ _ _ _ _ _ _ _ _`}
            value={phoneNumber}
            onChange={handlePhoneNumber}
          />
        </div>

        {error && (
          <span className='modal-auth__login-container__error-message'>
            {styles[0].TextIDsRepository.modalLoginError}
          </span>
        )}

        <Button
          disabled={checkPhoneIsFilled() || !phoneNumber.length}
          title={styles[0]?.TextIDsRepository.modalLoginGetCode}
          click={getPhoneCode}
        />

        <button
          className='modal-auth__login-container__close-btn'
          onClick={close}
        >
          <X />
        </button>
      </motion.div>
    </motion.div>
  )
}

export default Auth
