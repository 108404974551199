import { AxiosError } from 'axios'

import { ApiRoutes } from '../../enums/apiRoutes'
import { getData, setData } from '../../utils/axiosManager'
import {
  CustomerRequest,
  CustomerResponse,
  PhoneCustomerRequest,
  ValidatePhoneCustomerRequest,
} from './interface'

export const getValidatePhoneCustomer = async (
  phone: string,
  baseURL = ApiRoutes.ValidatePhoneCustomer
): Promise<{ CustomerID: string; NutritionistID: string }> => {
  return getData(baseURL + `?phone=${phone}`)
}

export const setPhoneCustomerCode = async (
  data: PhoneCustomerRequest['post'],
  baseURL = ApiRoutes.PhoneCustomer
): Promise<{ status: number }> => {
  return setData(baseURL, data).then((response) => {
    if (response instanceof AxiosError && response.response?.status) {
      return { status: response.response.status }
    }

    return { status: response }
  })
}

export const setConfirmationByCode = async (
  phoneNumber: string,
  data: ValidatePhoneCustomerRequest['post'],
  baseURL = ApiRoutes.ValidatePhoneCustomer
): Promise<{ status: number }> => {
  return setData(baseURL + `?phone=${phoneNumber}`, data).then((response) => {
    if (response instanceof AxiosError && response.response?.status) {
      return { status: response.response.status }
    }

    return { status: response }
  })
}

export const createCustomer = async (
  phoneNumber: string,
  data: CustomerRequest['post'],
  baseURL = ApiRoutes.Customer,
  customerId?: string
): Promise<CustomerResponse['post']> => {
  const query = customerId
    ? `?phone=${phoneNumber}&customerId=${customerId}`
    : `?phone=${phoneNumber}`

  return setData(baseURL + query, data)
}
