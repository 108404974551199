import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

import InfoSideBar from './InfoSideBar'
import { useAppSelector } from '../../redux/hook'
import PersonalCabinetContainer from './PersonalCabinetContainer'
import { useMatchMedia } from '../../hooks/useMatchMedia'

import './styles.scss'
import clsx from 'clsx'

export type CurrentFilter = {
  type: 'data' | 'clients' | 'earnings' | 'payments' | string
}

const PersonalCabinet = () => {
  const profileData = useAppSelector((state) => state.profileSlice)
  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const currentClient = useAppSelector(
    (state) => state.personalCabinet.currentClient
  )

  const [currentFilter, setCurrentFilter] = useState<CurrentFilter>({
    type: profileData.nutritionist ? 'clients' : 'data',
  })

  const { isMobile } = useMatchMedia()

  useEffect(() => {
    if (profileData.nutritionist) {
      setCurrentFilter({ type: profileData.nutritionist ? 'clients' : 'data' })
    }
  }, [profileData.nutritionist])

  const filterControlsButtonsList = profileData.nutritionist
    ? [
        {
          title: styles[0]?.TextIDsRepository.personalAccountMyClients,
          currentFilterType: 'clients',
        },
        {
          title: styles[0]?.TextIDsRepository.personalAccountMyData,
          currentFilterType: 'data',
        },
        {
          title: styles[0]?.TextIDsRepository.personalAccountMyEarnings,
          currentFilterType: 'earnings',
        },
        {
          title: styles[0]?.TextIDsRepository.personalAccountMyPayments,
          currentFilterType: 'payments',
        },
      ]
    : [
        {
          title: styles[0]?.TextIDsRepository.personalAccountMyData,
          currentFilterType: 'data',
        },
      ]

  if (isMobile) {
    return (
      <div
        className={clsx('main-mobile', 'personal-cabinet-container__mobile')}
      >
        <PersonalCabinetContainer
          currentFilter={currentFilter}
          setCurrentFilter={setCurrentFilter}
          filterControlsButtonsList={filterControlsButtonsList}
        />

        {currentFilter.type === 'data' && (
          <InfoSideBar currentFilter={currentFilter.type} />
        )}

        {currentFilter.type === 'clients' && currentClient.data && (
          <InfoSideBar profileDataFilter={currentFilter.type} />
        )}

        {(currentFilter.type === 'earnings' ||
          currentFilter.type === 'payments') && <InfoSideBar />}

        <Outlet />
      </div>
    )
  }

  return (
    <>
      <PersonalCabinetContainer
        currentFilter={currentFilter}
        setCurrentFilter={setCurrentFilter}
        filterControlsButtonsList={filterControlsButtonsList}
      />

      {currentFilter.type === 'data' && (
        <InfoSideBar currentFilter={currentFilter.type} />
      )}

      {currentFilter.type === 'clients' && currentClient.data && (
        <InfoSideBar profileDataFilter={currentFilter.type} />
      )}

      {(currentFilter.type === 'earnings' ||
        currentFilter.type === 'payments') && <InfoSideBar />}

      <Outlet />
    </>
  )
}

export default PersonalCabinet
