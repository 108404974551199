import React, { useState } from 'react'
import { Info, X } from 'react-feather'
import clsx from 'clsx'

import Button from '../UI/Button'

import './styles.scss'

type ModalPaymentProps = {
  showModalPayment: boolean
  setShowModalPayment: (value: boolean) => void
}

const ModalPayment: React.FC<ModalPaymentProps> = ({
  showModalPayment,
  setShowModalPayment,
}) => {
  const [fullContent, setFullContent] = useState(false)

  const closeModal = () => {
    setShowModalPayment(false)
  }

  return (
    <div
      className={clsx('modal', 'modal-payment', {
        'modal-payment-active': showModalPayment,
      })}
      role='button'
      tabIndex={0}
      onClick={closeModal}
    >
      <div
        role='presentation'
        onClick={(e) => e.stopPropagation()}
        className='modal-payment__content'
      >
        <div className='modal-payment__content__title-container'>
          <span className='modal-payment__content__title'>
            Заявка на выплату / вывод
          </span>
        </div>

        {fullContent && (
          <>
            <div className='modal-payment__content__info'>
              <Info />

              <span className='modal-payment__content__description'>
                Чтобы создать заявку добавьте реквизиты в{' '}
                <button
                  onClick={() => setFullContent((prevState) => !prevState)}
                  className='modal-payment__content__link-to-account'
                >
                  Личном кабинете
                </button>
              </span>
            </div>

            <div className='modal-payment__content__requisites-first'>
              <span>
                ООО “КИЕВСКИЙ ЦЕНТР ПОДГОТОВКИ, ПЕРЕПОДГОТовки и повы...
              </span>

              <span>ИНН: 7707083893</span>

              <span>КПП: 771401001</span>
            </div>

            <div className='modal-payment__content__requisites-second'>
              <div>
                <span>Тинькоф, руб</span>

                <span>Р/с: 40802810064580000000</span>

                <span>К/с: 30100000000000000977</span>

                <span>БИК: 045525977</span>
              </div>

              <div>
                <label>
                  Сумма (мин. 1500 Р)
                  <input
                    className={clsx(
                      'modal-register__form-container__label-input',
                      'modal-payment__content__requisites-second__input'
                    )}
                  />
                </label>
              </div>
            </div>
          </>
        )}

        {!fullContent && (
          <>
            <span
              className={clsx('modal-payment__content__description', {
                'modal-payment__content__description__short': !fullContent,
              })}
            >
              Чтобы создать заявку добавьте реквизиты в <br />
              <button
                onClick={() => setFullContent((prevState) => !prevState)}
                className={clsx('modal-payment__content__link-to-account', {
                  'modal-payment__content__link-to-account__short':
                    !fullContent,
                })}
              >
                Личном кабинете
              </button>
            </span>
          </>
        )}

        <Button
          title='Создать'
          click={() => setShowModalPayment(true)}
          classNames='modal-payment__create-button'
        />

        <button className='modal-payment__close-btn' onClick={closeModal}>
          <X />
        </button>
      </div>
    </div>
  )
}

export default ModalPayment
