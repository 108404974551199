import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import { Supplement } from './supplementsSlice'
import {
  createValueFromFrequency,
  initialRecipeDosageValue,
} from './supplementOfDaySlice'
import {
  CourseItemToServer,
  initialRecipeState,
  RecipeFrequencyType,
} from './modalSupplementSlice'
import { CheckboxType } from '../../components/UI/Checkbox/Checkbox'
import {
  CreateCourseResponseType,
  SupplementCourseItem,
  SupplementCourseResponseType,
} from '../../utils/axiosManager'
import { deepCopyOfObject } from '../../utils/deepCopyOfObject'
import {
  formatDayName,
  returnToOriginalFormatDayName,
} from '../../utils/formatDayName'
import { CoursePriceType } from '../../enums/course'

export type Course = {
  id: number | null
  supplementsList: SupplementInCourse[]
  filteredByTime: FilteredByTimeType
  supplementCourseCost: number
  productCostServicePrice: number
  variablePackageServicePrice: number
  duration: number
}

export type FilteredByTimeType = {
  [key: string]: FilteredByTimeItemType
}

type FilteredByTimeItemType = {
  [key: string]: TimeOfDaySupplementsType[]
}

export type SupplementInCourse = {
  supplement: Supplement
  recipes: RecipeOfDay[]
}

export type RecipeOfDay = {
  id: string
  recipeFrequency: string
  recipeDosage: RecipeDosageType[]
}

export type RecipeDosageType = {
  id: string
  time: string
  dosage: string
}

export type TimeOfDaySupplementsType = {
  recipeId: string
  supplement: {
    Article: string
    Picture: string
    GoodsCommercialName: string
    SupplementForm: string
  }
  dosage: RecipeDosageType
}

type CourseState = {
  course: Course
}

const initialState: CourseState = {
  course: {
    id: null,
    supplementsList: [],
    filteredByTime: {},
    supplementCourseCost: 0,
    productCostServicePrice: 0,
    variablePackageServicePrice: 0,
    duration: 1,
  },
}

const createDayInCourse = (day: string, quantity: number, time: string) => {
  const dosages = [createDosage(quantity, time)]

  return {
    id: formatDayName(day.toLowerCase()),
    recipeFrequency: dosages.length.toString(),
    recipeDosage: dosages,
  }
}

const createDosage = (quantity: number, time: string) => {
  return {
    id: Math.random().toString(),
    dosage: quantity.toString(),
    time: time.slice(0, -3),
  }
}

export const reformatDataForServerRequest = (list: SupplementInCourse[]) => {
  const arrayToServer: CourseItemToServer[] = []

  list.forEach((supplement) => {
    supplement.recipes.forEach((recipe) => {
      recipe.recipeDosage.forEach((dosage) => {
        arrayToServer.push({
          Article: supplement.supplement.Article,
          SupplementQuantity: Number(dosage.dosage[0]),
          SupplementPrice: supplement.supplement.CurrentPrices,
          ReceptionTime: dosage.time,
          ReceptionDay: returnToOriginalFormatDayName(recipe.id),
        })
      })
    })
  })

  return arrayToServer
}

export const reformatDataFromServerResponse = (
  listFromServer: SupplementCourseItem[],
  supplementsList: SupplementInCourse[]
) => {
  const currentSupplementsInCourse = deepCopyOfObject(supplementsList)

  listFromServer.forEach((recipeFromServer) => {
    currentSupplementsInCourse.forEach((supplement) => {
      if (recipeFromServer.Article === supplement.supplement.Article) {
        if (!supplement.recipes.length) {
          supplement.recipes.push(
            createDayInCourse(
              recipeFromServer.ReceptionDay,
              recipeFromServer.SupplementQuantity,
              recipeFromServer.ReceptionTime
            )
          )

          return
        }

        supplement.recipes.forEach((recipe) => {
          const recipeDayName = formatDayName(
            recipeFromServer.ReceptionDay.toLowerCase()
          )

          if (recipeDayName === recipe.id) {
            recipe.recipeDosage.push(
              createDosage(
                recipeFromServer.SupplementQuantity,
                recipeFromServer.ReceptionTime
              )
            )
            recipe.recipeFrequency = (
              Number(recipe.recipeFrequency) + 1
            ).toString()
          } else {
            supplement.recipes.push(
              createDayInCourse(
                recipeFromServer.ReceptionDay,
                recipeFromServer.SupplementQuantity,
                recipeFromServer.ReceptionTime
              )
            )
          }
        })
      }
    })
  })

  return currentSupplementsInCourse
}

export const sortedByTimeSupplementsList = (
  supplementsList: SupplementInCourse[]
) => {
  const separateListOfDays = (supplementList: SupplementInCourse[]): any => {
    const identityMap: any = {}

    supplementList.forEach((supplement) => {
      supplement.recipes.forEach((recipe) => {
        recipe.recipeDosage.forEach((dosage) => {
          if (identityMap[recipe.id] === undefined) {
            identityMap[recipe.id] = {}
          }

          const timeOfDayValues: TimeOfDaySupplementsType = {
            recipeId: recipe.id,
            supplement: {
              Article: supplement.supplement.Article,
              Picture: supplement.supplement.Picture,
              GoodsCommercialName: supplement.supplement.GoodsCommercialName,
              SupplementForm: supplement.supplement.SupplementForm,
            },
            dosage,
          }

          if (dosage.time in identityMap[recipe.id]) {
            identityMap[recipe.id][dosage.time] = [
              ...identityMap[recipe.id][dosage.time],
              timeOfDayValues,
            ]
          } else {
            identityMap[recipe.id][dosage.time] = [timeOfDayValues]
          }
        })
      })
    })

    return identityMap
  }

  const listOfDays = separateListOfDays(supplementsList)

  const days = [
    'daily',
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
    'Воскресенье',
  ]

  return Object.keys(listOfDays)
    .sort((x, y) => {
      if (days.indexOf(x) < days.indexOf(y)) {
        return -1
      }

      if (days.indexOf(x) > days.indexOf(y)) {
        return 1
      }

      return 0
    })
    .reduce((obj: FilteredByTimeType, key) => {
      obj[key] = Object.keys(listOfDays[key])
        .sort((x, y) => {
          const firstTempTime = Number(x.replace(':', ''))
          const secondTempTime = Number(y.replace(':', ''))

          if (firstTempTime < secondTempTime) {
            return -1
          }

          if (firstTempTime > secondTempTime) {
            return 1
          }

          return 0
        })
        .reduce((object: FilteredByTimeItemType, index) => {
          object[index] = listOfDays[key][index]
          return object
        }, {})

      return obj
    }, {})
}

export const sortedByNameSupplementsList = (
  supplementList: SupplementInCourse[]
) => {
  return supplementList.sort((a, b) => {
    if (a.supplement.GoodsCommercialName > b.supplement.GoodsCommercialName) {
      return 1
    }

    if (a.supplement.GoodsCommercialName < b.supplement.GoodsCommercialName) {
      return -1
    }

    return 0
  })
}

const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    addSupplementToCourse(
      state,
      action: PayloadAction<SupplementInCourse & { courseId?: number }>
    ) {
      const isSupplementToCourse = state.course.supplementsList
        .map((supplement) => supplement.supplement.Article)
        .includes(action.payload.supplement.Article)

      if (!isSupplementToCourse) {
        return {
          course: {
            ...state.course,
            id: action.payload.courseId || state.course.id,
            supplementsList: [
              ...state.course.supplementsList,
              {
                supplement: action.payload.supplement,
                recipes: action.payload.recipes,
              },
            ],
          },
        }
      }

      const updatedSupplementList = state.course.supplementsList.map(
        (supplement) =>
          supplement.supplement.Article === action.payload.supplement.Article
            ? {
                ...supplement,
                recipes: supplement.recipes.flatMap((recipe) => {
                  const currentRecipe = action.payload.recipes.filter(
                    (currentRecipe) => currentRecipe.id === recipe.id
                  )[0]

                  if (currentRecipe) {
                    const dosages = Object.values(
                      [
                        ...current(recipe.recipeDosage),
                        ...currentRecipe.recipeDosage,
                      ].reduce(
                        (acc: { [key: string]: RecipeDosageType }, value) => {
                          const tempDosage =
                            (acc[value.time]
                              ? Number(acc[value.time].dosage[0])
                              : 0) + Number(value.dosage[0])

                          acc[value.time] = {
                            ...value,
                            dosage:
                              tempDosage > 5 ? '5' : tempDosage.toString(),
                          }

                          return acc
                        },
                        {}
                      )
                    )

                    return {
                      ...recipe,
                      recipeDosage: dosages,
                    }
                  } else {
                    return [...supplement.recipes, ...action.payload.recipes]
                  }
                }),
              }
            : supplement
      )

      return {
        course: {
          ...state.course,
          id: action.payload.courseId || state.course.id,
          supplementsList: updatedSupplementList,
        },
      }
    },
    addConvertedDataToCourse(
      state,
      action: PayloadAction<SupplementCourseResponseType>
    ) {
      const currentSupplementsInCourse = reformatDataFromServerResponse(
        action.payload.SupplementCourse.SupplementCourseItems,
        state.course.supplementsList
      )

      return {
        course: {
          ...state.course,
          supplementsList: currentSupplementsInCourse,
          duration: action.payload.SupplementCourse.CourseDuration,
        },
      }
    },
    updateCourseServiceValues(
      state,
      action: PayloadAction<CreateCourseResponseType>
    ) {
      if (action.payload.hasOwnProperty('Success')) {
        return {
          course: {
            ...state.course,
            id: null,
            supplementCourseCost: 0,
            productCostServicePrice: 0,
            variablePackageServicePrice: 0,
            duration: 1,
          },
        }
      }

      const currentProductCostServicePrice =
        action.payload.SupplementCourseServices.filter(
          (item) => item.PriceType === CoursePriceType.ProductsCost
        )[0].ServicePrice
      const currentVariablePackageServicePrice =
        action.payload.SupplementCourseServices.filter(
          (item) => item.PriceType === CoursePriceType.VariablePackage
        )[0].ServicePrice

      return {
        course: {
          ...state.course,
          id: action.payload.SupplementCourseID || state.course.id,
          supplementCourseCost: action.payload.SupplementCourseCost,
          productCostServicePrice: currentProductCostServicePrice,
          variablePackageServicePrice: currentVariablePackageServicePrice,
        },
      }
    },
    sortedByTime(state): CourseState {
      const listOfDaysSortedByDay = sortedByTimeSupplementsList(
        state.course.supplementsList
      )

      return {
        course: {
          ...state.course,
          filteredByTime: listOfDaysSortedByDay,
        },
      }
    },
    sortedBySupplement(state): CourseState {
      const sortedList = sortedByNameSupplementsList([
        ...current(state).course.supplementsList,
      ])

      return { course: { ...state.course, supplementsList: sortedList } }
    },
    deleteSupplementFromCourse(state, action: PayloadAction<string>) {
      const updatedSupplementList = state.course.supplementsList.filter(
        (supplement) => supplement.supplement.Article !== action.payload
      )

      return {
        course: {
          ...state.course,
          supplementsList: updatedSupplementList,
        },
      }
    },
    deleteTimeFromDay(
      state,
      action: PayloadAction<{ dayId: string; time: string }>
    ) {
      const clearSupplementList = (
        list: SupplementInCourse[],
        item: TimeOfDaySupplementsType
      ) => {
        return list
          .map((supplement) =>
            supplement.supplement.Article === item.supplement.Article
              ? {
                  ...supplement,
                  recipes: supplement.recipes
                    .map((recipe) =>
                      recipe.id === action.payload.dayId
                        ? {
                            ...recipe,
                            recipeFrequency: (
                              Number(recipe.recipeFrequency) - 1
                            ).toString(),
                            recipeDosage: recipe.recipeDosage.filter(
                              (dosage) => {
                                return dosage.time !== action.payload.time
                              }
                            ),
                          }
                        : recipe
                    )
                    .filter((recipe) => recipe.recipeDosage.length),
                }
              : supplement
          )
          .filter((supplement) => supplement.recipes.length)
      }

      const removedElements =
        state.course.filteredByTime[action.payload.dayId][action.payload.time]

      const filteredByTimeDeep = deepCopyOfObject(state.course.filteredByTime)

      delete filteredByTimeDeep[action.payload.dayId][action.payload.time]

      if (!Object.keys(filteredByTimeDeep[action.payload.dayId]).length) {
        delete filteredByTimeDeep[action.payload.dayId]
      }

      let updatedSupplementsList: SupplementInCourse[] = []

      removedElements.forEach((item, index) => {
        if (index === 0) {
          updatedSupplementsList = clearSupplementList(
            state.course.supplementsList,
            item
          )
        } else {
          updatedSupplementsList = clearSupplementList(
            updatedSupplementsList,
            item
          )
        }
      })

      return {
        course: {
          ...state.course,
          supplementsList: updatedSupplementsList,
          filteredByTime: filteredByTimeDeep,
        },
      }
    },
    changeRecipeFrequencySupplementInCourse(
      state,
      action: PayloadAction<RecipeFrequencyType & { article: string }>
    ) {
      const payloadValue = Number(action.payload.value)
      const recipeId = action.payload.recipeId
      const currentSupplement = state.course.supplementsList.filter(
        (supplement) => supplement.supplement.Article === action.payload.article
      )[0]
      const currentRecipe = currentSupplement.recipes.filter(
        (recipe) => recipe.id === recipeId
      )[0]
      const currentRecipeFrequency = Number(currentRecipe.recipeFrequency)

      if (payloadValue > currentRecipeFrequency) {
        const newRecipeDosage = createValueFromFrequency(
          payloadValue,
          currentRecipeFrequency,
          initialRecipeDosageValue
        ) as RecipeDosageType[]

        return {
          course: {
            ...state.course,
            supplementsList: state.course.supplementsList.map((supplement) =>
              supplement.supplement.Article === action.payload.article
                ? {
                    ...supplement,
                    recipes: supplement.recipes.map((recipe) =>
                      recipe.id === recipeId
                        ? {
                            ...recipe,
                            recipeFrequency: action.payload.value,
                            recipeDosage: [
                              ...recipe.recipeDosage,
                              ...newRecipeDosage,
                            ],
                          }
                        : recipe
                    ),
                  }
                : supplement
            ),
          },
        }
      } else {
        const recipeDosageWithoutLast = currentRecipe.recipeDosage.slice(
          0,
          payloadValue
        )

        return {
          course: {
            ...state.course,
            supplementsList: state.course.supplementsList.map((supplement) =>
              supplement.supplement.Article === action.payload.article
                ? {
                    ...supplement,
                    recipes: supplement.recipes.map((recipe) =>
                      recipe.id === recipeId
                        ? {
                            ...recipe,
                            recipeFrequency: action.payload.value,
                            recipeDosage: recipeDosageWithoutLast,
                          }
                        : recipe
                    ),
                  }
                : supplement
            ),
          },
        }
      }
    },
    changeClockTimeSupplementInCourse(
      state,
      action: PayloadAction<
        RecipeDosageType & { recipeId: string; article: string }
      >
    ) {
      return {
        course: {
          ...state.course,
          supplementsList: state.course.supplementsList.map((supplement) =>
            supplement.supplement.Article === action.payload.article
              ? {
                  ...supplement,
                  recipes: supplement.recipes.map((recipe) =>
                    recipe.id === action.payload.recipeId
                      ? {
                          ...recipe,
                          recipeDosage: recipe.recipeDosage.map((dosage) =>
                            dosage.id === action.payload.id
                              ? {
                                  ...dosage,
                                  time: action.payload.time,
                                }
                              : dosage
                          ),
                        }
                      : recipe
                  ),
                }
              : supplement
          ),
        },
      }
    },
    changeRecipeDosageSupplementInCourse(
      state,
      action: PayloadAction<
        RecipeDosageType & { recipeId: string; article: string }
      >
    ) {
      return {
        course: {
          ...state.course,
          supplementsList: state.course.supplementsList.map((supplement) =>
            supplement.supplement.Article === action.payload.article
              ? {
                  ...supplement,
                  recipes: supplement.recipes.map((recipe) =>
                    recipe.id === action.payload.recipeId
                      ? {
                          ...recipe,
                          recipeDosage: recipe.recipeDosage.map((dosage) =>
                            dosage.id === action.payload.id
                              ? { ...dosage, dosage: action.payload.dosage[0] }
                              : dosage
                          ),
                        }
                      : recipe
                  ),
                }
              : supplement
          ),
        },
      }
    },
    changeCourseReceptionDuration(state, action: PayloadAction<number>) {
      return {
        course: {
          ...state.course,
          duration: action.payload,
        },
      }
    },
    deleteDosageFromRecipeSupplementInCourse(
      state,
      action: PayloadAction<{
        id: string
        recipeId: string
        article: string
      }>
    ) {
      const currentSupplement = state.course.supplementsList.filter(
        (supplement) => supplement.supplement.Article === action.payload.article
      )[0]
      const currentRecipe = currentSupplement.recipes.filter(
        (recipe) => recipe.id === action.payload.recipeId
      )[0]

      const updatedFrequency = (
        Number(currentRecipe.recipeFrequency) - 1
      ).toString()
      const updatedDosage = currentRecipe.recipeDosage.filter(
        (dosage) => dosage.id !== action.payload.id
      )

      return {
        course: {
          ...state.course,
          supplementsList: state.course.supplementsList
            .map((supplement) =>
              supplement.supplement.Article === action.payload.article
                ? {
                    ...supplement,
                    recipes: supplement.recipes
                      .map((recipe) =>
                        recipe.id === action.payload.recipeId
                          ? {
                              ...recipe,
                              recipeFrequency: updatedFrequency,
                              recipeDosage: updatedDosage,
                            }
                          : recipe
                      )
                      .filter((recipe) => recipe.recipeDosage.length),
                  }
                : supplement
            )
            .filter((supplement) => supplement.recipes.length),
        },
      }
    },
    deleteRecipeFromDaySupplementInCourse(
      state,
      action: PayloadAction<{ recipeId: string; article: string }>
    ) {
      const currentSupplement = state.course.supplementsList.filter(
        (supplement) => supplement.supplement.Article === action.payload.article
      )[0]
      const filteredRecipes = currentSupplement.recipes.filter(
        (recipe) => recipe.id !== action.payload.recipeId
      )

      if (filteredRecipes.length === 0) {
        return {
          course: {
            ...state.course,
            supplementsList: state.course.supplementsList.map((supplement) =>
              supplement.supplement.Article === action.payload.article
                ? {
                    ...supplement,
                    recipes: initialRecipeState(),
                  }
                : supplement
            ),
          },
        }
      }

      return {
        course: {
          ...state.course,
          supplementsList: state.course.supplementsList.map((supplement) =>
            supplement.supplement.Article === action.payload.article
              ? {
                  ...supplement,
                  recipes: filteredRecipes,
                }
              : supplement
          ),
        },
      }
    },
    controlRecipeOfDayInSupplementRecipesInCourse(
      state,
      action: PayloadAction<CheckboxType & { article: string }>
    ) {
      const currentSupplement = state.course.supplementsList.filter(
        (supplement) => supplement.supplement.Article === action.payload.article
      )[0]

      if (action.payload.checked) {
        const recipesWithoutDaily = currentSupplement.recipes.filter(
          (item) => item.id !== 'daily'
        )

        return {
          course: {
            ...state.course,
            supplementsList: state.course.supplementsList.map((supplement) =>
              supplement.supplement.Article === action.payload.article
                ? {
                    ...supplement,
                    recipes: !recipesWithoutDaily.length
                      ? [
                          {
                            id: action.payload.value,
                            recipeFrequency: '1',
                            recipeDosage: [initialRecipeDosageValue()],
                          },
                        ]
                      : [
                          ...recipesWithoutDaily,
                          {
                            id: action.payload.value,
                            recipeFrequency: '1',
                            recipeDosage: [initialRecipeDosageValue()],
                          },
                        ],
                  }
                : supplement
            ),
          },
        }
      }

      const recipesWithoutDay = currentSupplement.recipes.filter(
        (recipe) => recipe.id !== action.payload.value
      )

      if (!recipesWithoutDay.length) {
        return {
          course: {
            ...state.course,
            supplementsList: state.course.supplementsList.map((supplement) =>
              supplement.supplement.Article === action.payload.article
                ? {
                    ...supplement,
                    recipes: initialRecipeState(),
                  }
                : supplement
            ),
          },
        }
      }

      return {
        course: {
          ...state.course,
          supplementsList: state.course.supplementsList.map((supplement) =>
            supplement.supplement.Article === action.payload.article
              ? {
                  ...supplement,
                  recipes: recipesWithoutDay,
                }
              : supplement
          ),
        },
      }
    },
    resetCourse(state) {
      state.course = initialState.course
    },
  },
})

export const {
  addSupplementToCourse,
  addConvertedDataToCourse,
  updateCourseServiceValues,
  sortedByTime,
  sortedBySupplement,
  deleteSupplementFromCourse,
  deleteTimeFromDay,
  controlRecipeOfDayInSupplementRecipesInCourse,
  changeRecipeFrequencySupplementInCourse,
  changeClockTimeSupplementInCourse,
  changeRecipeDosageSupplementInCourse,
  changeCourseReceptionDuration,
  deleteDosageFromRecipeSupplementInCourse,
  deleteRecipeFromDaySupplementInCourse,
  resetCourse,
} = courseSlice.actions

export default courseSlice.reducer
