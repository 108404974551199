import React from 'react'
import clsx from 'clsx'

import './styles.scss'

type Spinner = {
  classNames?: string
  classNamesSpinner?: string
}
const Spinner: React.FC<Spinner> = ({ classNames, classNamesSpinner }) => {
  return (
    <div className={clsx('spinner-container', classNames)}>
      <div className={clsx('spinner', classNamesSpinner)} />
    </div>
  )
}

export default Spinner
