import React from 'react'
import { Outlet } from 'react-router-dom'

import SupplementsList from './components/SupplementsList'
import CoursePanel from './components/CoursePanel'
import { useMatchMedia } from './hooks/useMatchMedia'
import ErrorPage from './components/ErrorPage'
import { useAppSelector } from './redux/hook'

const Main = () => {
  const { isMobile } = useMatchMedia()
  const { error } = useAppSelector((state) => state.errors)

  return (
    <>
      {error ? (
        <ErrorPage />
      ) : isMobile ? (
        <div className='main-mobile'>
          <SupplementsList />
          <CoursePanel />
        </div>
      ) : (
        <>
          <SupplementsList />
          <CoursePanel />
        </>
      )}

      <Outlet />
    </>
  )
}

export default Main
