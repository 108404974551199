import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AnalysisEntity } from './analysisSlice'

export type OrderEntity = AnalysisEntity & {
  history: { guid: string; name: string }[]
}

type AnalysisOrderState = {
  list: OrderEntity[]
  orderByGuid: { [key: string]: AnalysisEntity[] }
  orderByBiomaterial: {
    [key: string]: { nameBiomaterial: string; list: AnalysisEntity[] }
  }
  orderByResearch: {
    [key: string]: { nameResearch: string; list: AnalysisEntity[] }
  }
}

const initialState: AnalysisOrderState = {
  list: [],
  orderByGuid: {},
  orderByBiomaterial: {},
  orderByResearch: {},
}

const analysisOrderSlice = createSlice({
  name: 'analyzesOrder',
  initialState,
  reducers: {
    addAnalysisToOrder(state, action: PayloadAction<OrderEntity>) {
      const payload = action.payload
      const { history, ...entity } = payload
      const biomaterialId = entity.biomaterialId
      const idCurrentAnalysisGroup =
        payload.history[payload.history.length - 1].guid
      const nameCurrentAnalysisGroup =
        payload.history[payload.history.length - 1].name

      state.list.push(payload)

      payload.history.forEach((item) => {
        if (state.orderByGuid[item.guid]) {
          state.orderByGuid[item.guid].push(entity)
        } else {
          state.orderByGuid[item.guid] = [entity]
        }
      })

      if (state.orderByBiomaterial[biomaterialId]) {
        state.orderByBiomaterial[biomaterialId].list.push(entity)
      } else {
        state.orderByBiomaterial[biomaterialId] = {
          nameBiomaterial: '',
          list: [],
        }

        state.orderByBiomaterial[biomaterialId].nameBiomaterial =
          entity.nameBiomaterial
        state.orderByBiomaterial[biomaterialId].list = [entity]
      }

      if (state.orderByResearch[idCurrentAnalysisGroup]) {
        state.orderByResearch[idCurrentAnalysisGroup].list.push(entity)
      } else {
        state.orderByResearch[idCurrentAnalysisGroup] = {
          nameResearch: '',
          list: [],
        }

        state.orderByResearch[idCurrentAnalysisGroup].nameResearch =
          nameCurrentAnalysisGroup
        state.orderByResearch[idCurrentAnalysisGroup].list = [entity]
      }
    },
    deleteAnalysisFromOrder(
      state,
      action: PayloadAction<{ serviceId: number }>
    ) {
      const serviceId = action.payload.serviceId
      const deletedAnalysis = state.list.filter(
        (entity) => entity.serviceId === serviceId
      )[0]
      const biomaterialId = deletedAnalysis.biomaterialId
      const groupGuid =
        deletedAnalysis.history[deletedAnalysis.history.length - 1].guid

      // remove from order list
      state.list = state.list.filter((item) => item.serviceId !== serviceId)

      // remove from orderByGuid
      deletedAnalysis.history.forEach((item) => {
        state.orderByGuid[item.guid] = state.orderByGuid[item.guid].filter(
          (item) => item.serviceId !== serviceId
        )

        if (state.orderByGuid[item.guid].length === 0) {
          delete state.orderByGuid[item.guid]
        }
      })

      // remove from orderByBiomaterial
      state.orderByBiomaterial[biomaterialId].list = state.orderByBiomaterial[
        biomaterialId
      ].list.filter((item) => item.serviceId !== serviceId)

      if (state.orderByBiomaterial[biomaterialId].list.length === 0) {
        delete state.orderByBiomaterial[biomaterialId]
      }

      // remove from orderByResearch
      state.orderByResearch[groupGuid].list = state.orderByResearch[
        groupGuid
      ].list.filter((item) => item.serviceId !== serviceId)

      if (state.orderByResearch[groupGuid].list.length === 0) {
        delete state.orderByResearch[groupGuid]
      }
    },
  },
})

export const { addAnalysisToOrder, deleteAnalysisFromOrder } =
  analysisOrderSlice.actions

export default analysisOrderSlice.reducer
