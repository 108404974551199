import React from 'react'
import Select from '../UI/Select'
import {
  changeRecipeDosageSupplementInCourse,
  deleteDosageFromRecipeSupplementInCourse,
  TimeOfDaySupplementsType,
} from '../../redux/slices/courseSlice'
import { X } from 'react-feather'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import { useDefineCurrentPathName } from '../../hooks/useDefineCurrentPathName'
import clsx from 'clsx'
import { modalSupplementInfoControl } from '../../redux/slices/modalSupplementInfoSlice'
import { useWordDeclension } from '../../hooks/useWordDeclension'
import { CourseDragVariants } from '../../redux/slices/partnerInterfaceSlice'

type ItemProps = {
  supplement: TimeOfDaySupplementsType
  numberOfItems: number
}

const SortedByTimeCardItem: React.FC<ItemProps> = ({
  supplement,
  numberOfItems,
}) => {
  const { styles } = useAppSelector((state) => state.partnerInterface)
  const dispatch = useAppDispatch()
  const isCabinetPathName = useDefineCurrentPathName('/cabinet/*')
  const dosageOptions = useWordDeclension(
    styles[0]?.TextIDsRepository.myCourseDrag[
      supplement.supplement.SupplementForm as CourseDragVariants
    ]
  )

  const handleRecipeDosage = (id: string, value: string) => {
    dispatch(
      changeRecipeDosageSupplementInCourse({
        ...supplement.dosage,
        recipeId: supplement.recipeId,
        dosage: value,
        article: supplement.supplement.Article,
      })
    )
  }

  const handleDelete = () => {
    dispatch(
      deleteDosageFromRecipeSupplementInCourse({
        id: supplement.dosage.id,
        article: supplement.supplement.Article,
        recipeId: supplement.recipeId,
      })
    )
  }

  const handleModalInfo = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    dispatch(
      modalSupplementInfoControl({
        isOpen: true,
        supplementId: supplement.supplement.Article,
        supplementPicture: supplement.supplement.Picture,
        supplementGoodsCommercialName:
          supplement.supplement.GoodsCommercialName,
      })
    )
  }

  return (
    <div className='time-card-item-container'>
      <img
        src={supplement.supplement.Picture}
        alt=''
        className='time-card-item-container__image'
      />

      <span
        className='time-card-container__product-name'
        role='button'
        tabIndex={0}
        onClick={handleModalInfo}
      >
        {supplement.supplement.GoodsCommercialName}
      </span>

      <Select
        classNames={clsx('time-card-item-container__select', {
          'select-text-right': isCabinetPathName,
        })}
        value={dosageOptions[Number(supplement.dosage.dosage[0]) - 1]}
        onChange={(event) => handleRecipeDosage(supplement.dosage.id, event)}
        options={dosageOptions}
        disabled={Boolean(isCabinetPathName)}
      />

      {!isCabinetPathName && (
        <button
          className='modal-content__controls-second__del-button'
          disabled={numberOfItems === 1}
          onClick={() => handleDelete()}
        >
          <X />
        </button>
      )}
    </div>
  )
}

export default SortedByTimeCardItem
