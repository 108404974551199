import React from 'react'

type ModalChooseLocationProps = {
  children: React.ReactNode
}

const ModalChooseLocation: React.FC<ModalChooseLocationProps> = ({
  children,
}) => {
  return <div className='modal-auth'>{children}</div>
}

export default ModalChooseLocation
