import React, { useEffect, useRef, useState } from 'react'
import { ChevronDown, Download, FileText, Trash2 } from 'react-feather'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'

import TextArea from '../../UI/TextArea'
import {
  updateEvent,
  deleteEvent,
  EventConsultation,
} from '../../../redux/slices/clientEventSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import {
  CustomerEventActions,
  EventType,
} from '../../../enums/customerEventActions'
import EventCardBody from '../EventCardBody'
import {
  customerDeleteEvent,
  customerUpdateConsultation,
} from '../../../utils/axiosManager'
import { ApiStatuses } from '../../../enums/apiRoutes'
import Spinner from '../../Spinner'
import { formatDate } from '../../../utils/formatDate'
import { smoothTransition } from '../../../utils/animations'

import './styles.scss'

type EventConsultationProps = {
  consultation: EventConsultation
}

type EventConsultationEditable = {
  [K in keyof Omit<
    EventConsultation,
    'eventId' | 'eventType' | 'date'
  >]: NonNullable<Omit<EventConsultation, 'eventId' | 'eventType' | 'date'>[K]>
}

const EventConsultationCard: React.FC<EventConsultationProps> = ({
  consultation,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [editableData, setEditableData] = useState<EventConsultationEditable>({
    header: consultation.header,
    complaints: consultation.complaints ?? '',
    diseases: consultation.diseases ?? '',
    allergy: consultation.allergy ?? '',
    information: consultation.information ?? '',
    dietHistory: consultation.dietHistory ?? '',
    diagnosis: consultation.diagnosis ?? '',
    recommendations: consultation.recommendations ?? '',
  })

  const firstRender = useRef(false)

  const dispatch = useAppDispatch()
  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const profileData = useAppSelector((state) => state.profileSlice)
  const currentClient = useAppSelector(
    (state) => state.personalCabinet.currentClient
  )

  useEffect(() => {
    if (!firstRender.current) {
      firstRender.current = true
      return
    }

    const changeConsultation = setTimeout(
      () => handleConsultationChange(CustomerEventActions.Edit),
      1000
    )

    return () => clearTimeout(changeConsultation)
  }, [editableData])

  const handleConsultationCardField = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    key: keyof Omit<EventConsultation, 'eventId' | 'eventType' | 'date'>
  ) => {
    setEditableData((prevState) => ({
      ...prevState,
      [key]: event.target.value,
    }))
  }

  const handleConsultationChange = (actionType: CustomerEventActions) => {
    switch (actionType) {
      case CustomerEventActions.Edit: {
        setIsLoading(true)

        customerUpdateConsultation(
          currentClient.data
            ? currentClient.data.CustomerID
            : profileData.customerID,
          {
            eventId: consultation.eventId,
            ...editableData,
          }
        )
          .then((response) => {
            if (response.eventId) {
              dispatch(
                updateEvent({
                  eventType: EventType.Consultation,
                  event: {
                    ...consultation,
                    ...editableData,
                  },
                })
              )
            }
          })
          .finally(() => setIsLoading(false))

        return
      }

      case CustomerEventActions.Delete: {
        setIsLoading(true)

        customerDeleteEvent(
          currentClient.data
            ? currentClient.data.CustomerID
            : profileData.customerID,
          {
            eventId: consultation.eventId,
          }
        )
          .then((response) => {
            if (response.status === ApiStatuses.Ok) {
              dispatch(
                deleteEvent({
                  eventType: EventType.Consultation,
                  id: consultation.eventId,
                })
              )
            }
          })
          .finally(() => setIsLoading(false))
        return
      }
    }
  }

  return (
    <motion.div className='event-card-container' layout {...smoothTransition}>
      <div
        className='event-card-container__header'
        role='button'
        tabIndex={0}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <button
          className={clsx('event-card-container__button', {
            'event-card-container__button-active': isOpen,
          })}
        >
          <ChevronDown />
        </button>

        <div className='event-card-container__icon'>
          <FileText />
        </div>

        <div className='event-card-container__date'>
          {formatDate(consultation.date.slice(0, 10))}
        </div>

        <input
          className='event-card-container__input'
          value={editableData.header}
          onChange={(event) => handleConsultationCardField(event, 'header')}
          onClick={(event) => event.stopPropagation()}
        />

        <AnimatePresence>
          {isLoading ? (
            <motion.div
              className='event-card-container__delete-button'
              {...smoothTransition}
            >
              <Spinner classNames='event-card-container__spinner' />
            </motion.div>
          ) : (
            <motion.div
              className='event-consultation__header__button-group'
              {...smoothTransition}
            >
              <button>
                <Download />
              </button>
              <button
                onClick={(event) => {
                  event.stopPropagation()
                  handleConsultationChange(CustomerEventActions.Delete)
                }}
              >
                <Trash2 />
              </button>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <EventCardBody isOpen={isOpen}>
        <div className='event-consultation__body__item'>
          <span>{styles[0]?.TextIDsRepository.consultationAnamnesisTitle}</span>

          <label>
            {styles[0]?.TextIDsRepository.consultationComplaintsTitle}
            <TextArea
              defaultValue={consultation.complaints ?? ''}
              onChange={(event) =>
                handleConsultationCardField(event, 'complaints')
              }
            />
          </label>

          <label>
            {styles[0]?.TextIDsRepository.consultationDiseasesTitle}
            <TextArea
              defaultValue={consultation.diseases ?? ''}
              onChange={(event) =>
                handleConsultationCardField(event, 'diseases')
              }
            />
          </label>

          <label>
            {styles[0]?.TextIDsRepository.consultationAllergyTitle}
            <TextArea
              defaultValue={consultation.allergy ?? ''}
              onChange={(event) =>
                handleConsultationCardField(event, 'allergy')
              }
            />
          </label>

          <label>
            {styles[0]?.TextIDsRepository.consultationDietHistoryTitle}
            <TextArea
              defaultValue={consultation.dietHistory ?? ''}
              onChange={(event) =>
                handleConsultationCardField(event, 'dietHistory')
              }
            />
          </label>

          <label>
            {styles[0]?.TextIDsRepository.consultationInformationTitle}
            <TextArea
              defaultValue={consultation.information ?? ''}
              onChange={(event) =>
                handleConsultationCardField(event, 'information')
              }
            />
          </label>
        </div>

        <div className='event-consultation__body__item'>
          <span>{styles[0]?.TextIDsRepository.consultationDiagnosisTitle}</span>
          <TextArea
            defaultValue={consultation.diagnosis ?? ''}
            onChange={(event) =>
              handleConsultationCardField(event, 'diagnosis')
            }
          />
        </div>

        <div className='event-consultation__body__item'>
          <span>
            {styles[0]?.TextIDsRepository.consultationRecommendationsTitle}
          </span>
          <TextArea
            defaultValue={consultation.recommendations ?? ''}
            onChange={(event) =>
              handleConsultationCardField(event, 'recommendations')
            }
          />
        </div>
      </EventCardBody>
    </motion.div>
  )
}

export default EventConsultationCard
