import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type PartnerInterfaceType = {
  LanguageID: number
  PartnerApiKey: string
  CssFile: string
  PartnerLogo: string
  Title: string
  Description: string
  TextIDsRepository: {
    localeId: string
    currency: string
    course: string
    prepositionIn: string
    agreeButtonTitle: string
    disagreeButtonTitle: string
    createButtonTitle: string
    deleteButtonTitle: string
    cancelButtonTitle: string
    completeButtonTitle: string
    sendButtonTitle: string
    sidebarMenuListItemCatalog: string
    sidebarMenuFooterLabel: string
    sidebarMenuCloseBtnTooltip: string
    sidebarMenuOpenBtnTooltip: string
    sidebarMenuNutritionists: string
    sidebarMenuDocuments: string
    formTableTHGoods: string
    formTableTHDesc: string
    formTableTHPrice: string
    formTableCartBtnLabel: string
    formTableCartBtnLabelDone: string
    modalAddSupplementBtnLabel: string
    myCourseOn: string
    myCourseLabel: string
    myCourseTooltip: string
    myCourseDeliveryTooltip: string
    myCourseServiceFeeTooltip: string
    myCourseAdditionalPackagingTooltip: string
    myCoursePersonalizationTooltip: string
    myCourseInvitation: string
    myCourseByAppointmentTime: string
    myCourseBySupplement: string
    myCoursePresenceEnded: string
    myCoursePresenceEnding: string
    myCourseHowToUse: string
    myCourseHowMuch: string
    myCourseWhere: string
    myCourseDosage: string
    myCourseWeek: WordVariants
    myCourseDrag: {
      tablet: WordVariants
      capsule: WordVariants
      caplet: WordVariants
    }
    myCourseAcceptance: WordVariants
    myCourseDaily: string
    myCourseWeekly: string
    myCourseDayOfWeek: string[]
    myCourseDayOfWeekAbbr: string[]
    myCourseQuantityAbbr: string
    myCourseDeliveryNPay: string
    myCourseDeliveryNPayCourier: string
    myCourseDeliveryNPayPVZ: string
    myCourseDeliveryNPayPostamat: string
    myCourseDelivery: string
    myCourseDeliveryComment: string
    myCoursePay: string
    myCourseCollapseAll: string
    myCourseExpandAll: string
    myCourseTotalPayable: string
    myCoursePortion: string
    myCourseOrder: string
    myCourseSupplements: string
    myCourseServiceFee: string
    myCourseAdditionalPackaging: string
    myCoursePersonalization: string
    myCourseSupplementPerWeek: string
    myCourseSupplementPrice: string
    myCourseOrderingTitle: string
    modalLoginError: string
    modalLoginTitle1: string
    modalLoginTitle2: string
    modalLoginGetCode: string
    modalLoginCofirmTooltipBeforeCounter: string
    modalLoginCofirmTooltipAfterCounter: string
    modalLogInWrongConfirmCallback: string
    modalLoginTitle3: string
    modalLoginExplanation: string
    modalLoginLastName: string
    modalLoginFirstName: string
    modalLoginSecondName: string
    modalLoginEMailName: string
    modalLoginEMailNamePlaceholder: string
    modalLoginBirthdateName: string
    modalLoginBirthdateNamePlaceholder: string
    modalLoginCity: string
    modalLoginAgreement: string
    modalLoginAgreementLink: string
    modalLoginEndBtn: string
    modalLoginLastNameWarnMessage: string
    modalLoginFirstNameWarnMessage: string
    modalLoginSecondNameWarnMessage: string
    modalLoginBirthdateWarnMessage: string
    modalLoginEMailWarnMessage: string
    modalLoginCityWarnMessage: string
    modalLoginPhoneWarnMessage: string
    modalLoginWrongCodeWarnMessage: string
    modalSupplementCardDescription: string
    modalSupplementCardActiveComponents: string
    modalSupplementCardOtherComponents: string
    modalSupplementCardContraindications: string
    modalSupplementCardSupplementForm: string
    modalSupplementCardSupplementDimentions: string
    modalSupplementCardSupplementColor: string
    modalSupplementCardRecommendedPerServing: string
    modalSupplementCardSupplementWeight: string
    modalSupplementCardSupplementFacts: string
    modalSupplementCardSuppFactsActiveComponentsAndRaw: string
    modalSupplementCardSuppFactsActiveComponentsWeigth: string
    modalSupplementCardSuppFactsAUPPercent: string
    modalSupplementCardNutrientCompositionTitle: string
    modalSupplementCardNutrientComposition: string
    personalAccountTitle: string
    personalAccountMyData: string
    personalAccountMyClients: string
    personalAccountMyEarnings: string
    personalAccountMyPayments: string
    personalAccountChangePhone: string
    personalAccountChangePhoneTooltip: string
    personalAccountMyCoursesTitle: string
    personalAccountSelectCourse: string
    personalAccountAcceptTitle: string
    personalAccountTimeOfReceipt: WordVariants
    personalAccountRequisitesTitle: string
    personalAccountRecipientName: string
    personalAccountINN: string
    personalAccountKPP: string
    personalAccountAccountName: string
    personalAccountCheckingAccount: string
    personalAccountCorrespondentAccount: string
    personalAccountBIK: string
    personalAccountStartDate: string
    personalAccountCompletionDate: string
    personalAccountBalance: string
    personalAccountBioAddTooltip: string
    personalAccountPersonalizationTooltip: string
    personalAccountLaboratoryTooltip: string
    personalAccountFacilitiesTooltip: string
    personalAccountMinPaymentTooltip: string
    personalAccountAboutTitle: string
    personalAccountAboutDataTooltip: string
    personalAccountPhotoTitle: string
    personalAccountChoosePhoto: string
    personalAccountPhotoRequirements: string
    personalAccountSpecializationTitle: string
    personalAccountDescriptionTitle: string
    personalAccountProfessionalActivity: string
    personalAccountSocialTitle: string
    personalAccountContactTitle: string
    personalAccountContactUsTitle: string
    personalAccountBannerTitle: string
    personalAccountBannerFact: string
    personalAccountReqTooltipFirst: string
    personalAccountReqTooltipSecond: string
    personalAccountConsultationTooltip: string
    personalAccountFilesTooltip: string
    personalAccountResearchTooltip: string
    personalAccountCourseTooltip: string
    personalAccountCourseContinue: string
    doctorsSpecializations: {
      nutritionist: string
      nutritionistDoctor: string
      pediatricNutritionist: string
      methodologist: string
    }
    errorPageTitle: string
    analysisTitle: string
    analysisPageTitle: string
    analysisDescription: string
    analysisTypeTitle: string
    analysisTypeDescription: string
    analysisDownloadTitle: string
    analysisPassTitle: string
    analysisTooltip: string
    analysisPanelTitle: string
    analysisTitleTooltip: string
    analysisOrderTitle: string
    analysisOrderTitleShort: string
    analysisOrderDescription: string
    analysisOrderingTitle: string
    analysisOrderTooltip: string
    analysisPageGeoPositionError: string
    analysisPageGeoPositionNoCity: string
    questionnaireQuickTitle: string
    questionnaireQuickAnswers: string
    questionnaireQuickAnswersFrom: string
    questionnaireQuickDescription: string
    infoSideBarHistoryTitle: string
    infoSideBarChronology: string
    infoSideBarAccruals: string
    clientsSearchPlaceholder: string
    clientsTableHeaderFIO: string
    clientsTableHeaderTags: string
    clientsTableHeaderPlan: string
    clientsTableHeaderCity: string
    clientsTableHeaderPhone: string
    clientsTableHeaderAge: string
    clientsTableNoDate: string
    clientsDeleteModalTitle: string
    clientsCreateDataTitle: string
    specialistPageDataAboutClient: string
    clientsCreateRequiredFields: string
    clientsCreateTitle: string
    clientsEmptyList: string
    consultationAnamnesisTitle: string
    consultationDietHistoryTitle: string
    consultationComplaintsTitle: string
    consultationDiseasesTitle: string
    consultationAllergyTitle: string
    consultationInformationTitle: string
    consultationDiagnosisTitle: string
    consultationRecommendationsTitle: string
    specialistPageTitle: string
    specialistPageSideBarTitle: string
    specialistPageSideBarEmptyDesc: string
    specialistPageAttachButton: string
    specialistPageProfileTitle: string
    specialistPageViewProfile: string
    specialistPageAttachComplete: string
    specialistPageAttachError: string
    specialistPageWorkExperience: string
    specialistPageInfo: string
    specialistPageMessage: string
    specialistPageMessagePlaceholder: string
    specialistPageSurveyStart: string
    specialistPageSurveyTitle: string
    specialistPageSurveySubTitle: string
    specialistPagePersonalSurveyTitle: string
    specialistPagePersonalSurveySubTitle: string
    specialistPageSendFilesTitle: string
    specialistPageSendFilesTypes: string
    specialistPageContacts: string
    specialistPageLoadErrorFirst: string
    specialistPageLoadErrorSecond: string
    specialistPageFilesTitle: string
    specialistPageSendToSpecialist: string
    specialistPageMoveToChronology: string
    specialistPageChooseSpecialist: string
    specialistPageSliderInfo: string
    yearDeclension: WordVariants
    assets: AssetsType
  }
  PartnerMenu: {
    menuItem01: {
      title: string
      type: string
      groupField: string
      flags: string[]
    }
    menuItem02: {
      title: string
      type: string
      listField: string
      flags: string[]
    }
    menuItem03: {
      title: string
      type: string
      children: {
        menuSubItem01: {
          title: string
          type: string
          flags: []
        }
        menuSubItem02: {
          title: string
          type: string
          flags: []
        }
        menuSubItem03: {
          title: string
          type: string
          flags: []
        }
      }
      flags: string[]
    }
    analysisPage?: {
      title: string
    }
  }
}

export type WordVariants = {
  count: number
  variants: {
    one: string
    few: string
    many: string
    other?: string
  }
  variantsGenitive?: {
    one: string
    few: string
    many: string
    other?: string
  }
}

export type CourseDragVariants = 'tablet' | 'capsule' | 'caplet'

type AssetsType = {
  capsulesIcon: string
  check: string
  flaskIcon: string
  mapMarker: string
  packageIcon: string
  pillsIcon: string
  tableIcon: string
  telegramIcon: string
  nutritionist: string
  balance: string
  analysisIcon: string
  microscopeIcon: string
  readyTimeIcon: string
  surveyIcon: string
  statusIcon: string
  bestFileSharingIcon: string
  facebookIcon: string
  greyTelegramIcon: string
  instagramIcon: string
  mailIcon: string
  vkIcon: string
  whatsAppIcon: string
}

type PartnerInterfaceState = {
  styles: PartnerInterfaceType[]
}

const initialState: PartnerInterfaceState = {
  styles: [],
}

const partnerInterfaceSlice = createSlice({
  name: 'partnerInterface',
  initialState,
  reducers: {
    addAllPartnerInterface(
      state,
      action: PayloadAction<PartnerInterfaceType[]>
    ) {
      return { styles: action.payload }
    },
  },
})

export const { addAllPartnerInterface } = partnerInterfaceSlice.actions

export default partnerInterfaceSlice.reducer
