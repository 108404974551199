import { SupplementCourseItem } from './axiosManager'
import {
  FilteredByTimeType,
  reformatDataFromServerResponse,
  sortedByNameSupplementsList,
  sortedByTimeSupplementsList,
  SupplementInCourse,
} from '../redux/slices/courseSlice'
import { Supplement } from '../redux/slices/supplementsSlice'

export const courseSortedBy = (
  supplementsList: Supplement[],
  supplementCourseItems: SupplementCourseItem[]
): [
  sortedByTimeList: FilteredByTimeType,
  sortedByNameList: SupplementInCourse[]
] => {
  const currentSupplements: SupplementInCourse[] = []

  supplementCourseItems.forEach((item) => {
    const currentSupplement = supplementsList.filter(
      (supplement) => supplement?.Article === item?.Article
    )[0]

    const currentSupplementInList = currentSupplements.some(
      (item) => item.supplement?.Article === currentSupplement?.Article
    )

    if (currentSupplementInList) {
      return
    }

    currentSupplements.push({ supplement: currentSupplement, recipes: [] })
  })

  const reformatList = reformatDataFromServerResponse(
    supplementCourseItems,
    currentSupplements
  )

  const sortedByTimeList = sortedByTimeSupplementsList(reformatList)
  const sortedByNameList = sortedByNameSupplementsList(reformatList)

  return [sortedByTimeList, sortedByNameList]
}
