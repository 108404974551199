import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type SurveyAnswerType = {
  int: number
  bool: boolean
  text: string
}

export type SurveyEntity = {
  id: string
  questionList: SurveyItem[]
}

export type SurveyItem = {
  id: string
  question: string
  answerType: keyof SurveyAnswerType
  value: SurveyAnswerType[keyof SurveyAnswerType] | null
}

const initialState: SurveyEntity = {
  id: '',
  questionList: [],
}

const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    addSurveyList(state, action: PayloadAction<SurveyEntity>) {
      state.id = action.payload.id
      state.questionList = [...action.payload.questionList]
    },
    editSurveyQuestion(state, action: PayloadAction<SurveyItem>) {
      state.questionList = state.questionList.map((question) => {
        if (question.id === action.payload.id) {
          return action.payload
        }

        return question
      })
    },
  },
})

export const { addSurveyList, editSurveyQuestion } = surveySlice.actions

export default surveySlice.reducer
