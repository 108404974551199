import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { X } from 'react-feather'

import { AnimatePresence } from 'framer-motion'
import { AxiosError } from 'axios'
import { ulid } from 'ulid'

import Button from '../UI/Button'
import { CheckboxType } from '../UI/Checkbox/Checkbox'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import {
  addSupplementToCourse,
  RecipeOfDay,
  updateCourseServiceValues,
} from '../../redux/slices/courseSlice'
import {
  convertDataForChangeCourse,
  CourseItemToServer,
  modalControl,
} from '../../redux/slices/modalSupplementSlice'
import { clearFields } from '../../redux/slices/supplementOfDaySlice'
import SupplementOfDay from '../SupplementOfDay'
import CurrentAcceptance from '../shared/CurrentAcceptance'
import { CookieEnum } from '../../enums/cookie'
import { changeSupplementCourse } from '../../utils/axiosManager'
import { setErrorState } from '../../redux/slices/errorsSlice'
import { ApiRoutes } from '../../enums/apiRoutes'
import { getSupplementAsync } from '../../redux/slices/supplementFactsSlice'
import { updateOrderField } from '../../redux/slices/orderSlice'
import { ModalWrapper } from '../ModalWrapper'

import './styles.scss'

const ModalSupplementToCourse: React.FC = () => {
  const [isCorrectData, setIsCorrectData] = useState(false)
  const [weekdaysCheckboxes, setWeekdayCheckboxes] = useState<CheckboxType[]>(
    []
  )
  const { controls, recipes } = useAppSelector((state) => state.modalSupplement)
  const { styles } = useAppSelector((state) => state.partnerInterface)
  const supplementList = useAppSelector((state) => state.supplements.list)
  const { course } = useAppSelector((state) => state.course)
  const profileData = useAppSelector((state) => state.profileSlice)
  const { supplementFactsList } = useAppSelector(
    (state) => state.supplementFacts
  )
  const [cookies, setCookies] = useCookies([CookieEnum.SupplementCourse])
  const dispatch = useAppDispatch()

  const supplement = supplementList.find(
    (item) => item.Article === controls.supplementId
  )

  useEffect(() => {
    checkIsCorrectDataToCourse()
  }, [recipes])

  useEffect(() => {
    if (styles[0]) {
      const weekdayCheckboxesInitialState =
        styles[0].TextIDsRepository.myCourseDayOfWeek.map((value, index) => ({
          id: index,
          checked: false,
          value,
        }))

      setWeekdayCheckboxes(weekdayCheckboxesInitialState)
    }
  }, [styles])

  const close = () => {
    dispatch(modalControl({ isOpen: false, supplementId: '' }))
  }

  const setCookieValueToStorage = (cookieValue: string) => {
    if (!cookies.SupplementCourse) {
      const date = new Date()
      date.setFullYear(
        date.getFullYear() + CookieEnum.SupplementCourseLifeTimeYears
      )

      setCookies(CookieEnum.SupplementCourse, cookieValue, {
        expires: date,
      })
    }
  }

  const addToCourse = () => {
    const cookieValue = ulid()
    setCookieValueToStorage(cookieValue)
    close()
    if (supplement) {
      dispatch(addSupplementToCourse({ supplement, recipes }))

      const articleInFactsCondition = supplementFactsList.some(
        (fact) => fact.Article === supplement.Article
      )

      if (!articleInFactsCondition) {
        dispatch(getSupplementAsync(supplement.Article))
      }

      if (!course.supplementsList.length) {
        const data = convertDataForChangeCourse([{ supplement, recipes }])

        updateSupplementCourse(data, 'CreateSupplementCourse', cookieValue)
      } else {
        const data = convertDataForChangeCourse([
          ...course.supplementsList,
          { supplement, recipes },
        ])

        updateSupplementCourse(data, 'EditSupplementCourse', cookieValue)
      }
    }
    dispatch(clearFields())
  }

  const updateSupplementCourse = (
    list: CourseItemToServer[],
    methodType: 'CreateSupplementCourse' | 'EditSupplementCourse',
    cookieValue: string
  ) => {
    const supplementCourseCookie = cookies.SupplementCourse || cookieValue

    changeSupplementCourse(
      supplementCourseCookie,
      course.id,
      profileData.customerID,
      course.duration,
      list,
      ApiRoutes[methodType]
    )
      .then((data) => {
        if (data instanceof AxiosError) {
          dispatch(setErrorState(true))
          return
        }

        if (typeof data.EditSupplementCourse === 'string') {
          return
        }

        dispatch(updateCourseServiceValues(data[methodType]))
        dispatch(
          updateOrderField({
            SupplementCourseID: data[methodType].SupplementCourseID,
          })
        )
      })
      .catch(() => dispatch(setErrorState(true)))
  }

  const checkIsCorrectDataToCourse = () => {
    let currentLength = 0

    const times = recipes
      .map((recipe) => {
        return recipe.recipeDosage
      })
      .flat()

    times.forEach((item) => {
      if (!isNaN(Number(item.time[item.time.length - 1]))) {
        currentLength++
      }
    })

    let timesIsNaN = false

    times.forEach((time) => {
      const timeInNumber = Number(time.time.replace(':', ''))

      if (isNaN(timeInNumber)) {
        timesIsNaN = true
      }
    })

    if (times.length === currentLength && !timesIsNaN) {
      setIsCorrectData(true)
      return
    }

    setIsCorrectData(false)
  }

  return (
    <AnimatePresence>
      {controls.isOpen && (
        <ModalWrapper
          close={close}
          containerClassNames='modal'
          contentClassNames='modal-content'
        >
          <div className='modal-content__header'>
            <img src={supplement?.Picture} alt='' />
            <span className='modal-content__header__name'>
              {supplement?.GoodsCommercialName}
            </span>

            <CurrentAcceptance
              recipes={recipes}
              classNames='modal-content__header__product-count'
            />

            <button
              className='modal-content__header__close-btn'
              onClick={close}
            >
              <X />
            </button>
          </div>

          {supplement && (
            <div className='modal-content__body'>
              <AnimatePresence>
                {recipes.map((recipe: RecipeOfDay, index) => {
                  return (
                    <SupplementOfDay
                      key={recipe.id}
                      index={index}
                      recipe={recipe}
                      form={supplement.SupplementForm}
                      weekdaysCheckboxes={weekdaysCheckboxes}
                      setWeekdayCheckboxes={setWeekdayCheckboxes}
                      article={supplement.Article}
                    />
                  )
                })}
              </AnimatePresence>

              <div className='modal-content__button'>
                <Button
                  title={
                    styles[0]?.TextIDsRepository.modalAddSupplementBtnLabel
                  }
                  click={() => addToCourse()}
                  disabled={!isCorrectData}
                />
              </div>
            </div>
          )}
        </ModalWrapper>
      )}
    </AnimatePresence>
  )
}

export default ModalSupplementToCourse
