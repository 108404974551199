import React, { useEffect, useRef, useState } from 'react'
import { ChevronDown, Trash2 } from 'react-feather'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'

import {
  CustomerEventActions,
  EventType,
} from '../../../enums/customerEventActions'
import {
  deleteEvent,
  EventSurveys,
  updateEvent,
} from '../../../redux/slices/clientEventSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import { SurveyItem } from '../../../redux/slices/surveySlice'
import ShortQuestionnaireItem from '../../ShortQuestionnaireItem'
import EventCardBody from '../EventCardBody'
import {
  customerDeleteEvent,
  customerUpdateSurvey,
} from '../../../utils/axiosManager'
import { ApiStatuses } from '../../../enums/apiRoutes'
import Spinner from '../../Spinner'
import { formatDate } from '../../../utils/formatDate'
import { smoothTransition } from '../../../utils/animations'

import './styles.scss'

type EventSurveyCardProps = {
  survey: EventSurveys
}

const EventSurveyCard: React.FC<EventSurveyCardProps> = ({ survey }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [header, setHeader] = useState(survey.header)

  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const profileData = useAppSelector((state) => state.profileSlice)
  const currentClient = useAppSelector(
    (state) => state.personalCabinet.currentClient
  )

  const firstRender = useRef(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!firstRender.current) {
      firstRender.current = true
      return
    }

    const changeConsultation = setTimeout(
      () => handleChangeSurvey(CustomerEventActions.Edit),
      1000
    )

    return () => clearTimeout(changeConsultation)
  }, [header])

  const handleChangeSurvey = (actionType: CustomerEventActions) => {
    switch (actionType) {
      case CustomerEventActions.Edit: {
        setIsLoading(true)

        customerUpdateSurvey(
          currentClient.data
            ? currentClient.data.CustomerID
            : profileData.customerID,
          {
            eventId: survey.eventId,
            header,
          }
        )
          .then((response) => {
            if (response.eventId) {
              dispatch(
                updateEvent({
                  eventType: EventType.Survey,
                  event: {
                    ...survey,
                    header,
                  },
                })
              )
            }
          })
          .finally(() => setIsLoading(false))

        return
      }

      case CustomerEventActions.Delete: {
        setIsLoading(true)

        customerDeleteEvent(
          currentClient.data
            ? currentClient.data.CustomerID
            : profileData.customerID,
          {
            eventId: survey.eventId,
          }
        )
          .then((response) => {
            if (response.status === ApiStatuses.Ok) {
              dispatch(
                deleteEvent({
                  eventType: EventType.Survey,
                  id: survey.eventId,
                })
              )
            }
          })
          .finally(() => setIsLoading(false))
        return
      }
    }
  }

  return (
    <motion.div className='event-card-container' layout {...smoothTransition}>
      <div
        className='event-card-container__header'
        role='button'
        tabIndex={0}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <button
          className={clsx('event-card-container__button', {
            'event-card-container__button-active': isOpen,
          })}
        >
          <ChevronDown />
        </button>

        <div className='event-card-container__icon'>
          <img src={styles[0]?.TextIDsRepository.assets.surveyIcon} alt='' />
        </div>

        <div className='event-card-container__date'>
          {formatDate(survey.date.slice(0, 10))}
        </div>

        <input
          className='event-card-container__input'
          value={header}
          onChange={(event) => setHeader(event.target.value)}
          onClick={(event) => event.stopPropagation()}
        />

        <AnimatePresence>
          {isLoading ? (
            <motion.div
              className='event-card-container__delete-button'
              {...smoothTransition}
            >
              <Spinner classNames='event-card-container__spinner' />
            </motion.div>
          ) : (
            <motion.div
              className='event-consultation__header__button-group'
              {...smoothTransition}
            >
              <button
                className='event-card-container__delete-button'
                onClick={(event) => {
                  event.stopPropagation()
                  handleChangeSurvey(CustomerEventActions.Delete)
                }}
              >
                <Trash2 />
              </button>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <EventCardBody isOpen={isOpen} classNamePadding='event-survey-card__body'>
        <div
          className={clsx(
            'event-card-container__body',
            'event-survey-card__content',
            {
              'event-card-container__body-active': isOpen,
            }
          )}
        >
          {survey.answers.map((answer, index) => {
            const formattedAnswer: SurveyItem = {
              id: index.toString(),
              answerType:
                typeof answer.answer === 'string'
                  ? 'text'
                  : typeof answer.answer === 'number'
                  ? 'int'
                  : 'bool',
              value: answer.answer,
              question: answer.question,
            }

            return (
              <ShortQuestionnaireItem
                key={formattedAnswer.id}
                index={index + 1}
                question={formattedAnswer}
                readOnly={true}
              />
            )
          })}
        </div>
      </EventCardBody>
    </motion.div>
  )
}

export default EventSurveyCard
