import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ArrowLeft, X } from 'react-feather'
import { SubmitHandler, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { useCookies } from 'react-cookie'

import { AxiosError } from 'axios'
import clsx from 'clsx'

import { useAppDispatch, useAppSelector } from '../../redux/hook'
import {
  updateProfileCustomerField,
  updateProfileDataFromServer,
} from '../../redux/slices/profileSlice'
import {
  getCustomerData,
  getCustomerList,
  getSelectCustomerCity,
  SelectCustomerCityItem,
} from '../../utils/axiosManager'
import { dateInputMaskValidate } from '../../utils/dateInputMaskValidate'
import {
  replaceInputValue,
  replaceInputValueToNumbers,
} from '../../utils/replaceInputValue'
import { CookieEnum } from '../../enums/cookie'
import { Regexp } from '../../enums/regexp'
import Button from '../UI/Button'
import AutoCompleteInput from '../UI/AutoCompleteInput'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import { useMatchMedia } from '../../hooks/useMatchMedia'
import ModalChooseLocation from '../ModalChooseLocation'
import { setErrorState } from '../../redux/slices/errorsSlice'
import { RoutesEnum } from '../../enums/routes'
import { useDefineCurrentPathName } from '../../hooks/useDefineCurrentPathName'
import { createCustomer } from '../../models/auth/api'
import { ApiRoutes } from '../../enums/apiRoutes'
import { customerEditData } from '../../models/customer/api'

import './styles.scss'

export type RegisterFormFields = {
  CustomerSurname: string
  CustomerName: string
  CustomerPatronymic: string
  CustomerDateOfBirth: string
  CustomerEmail: string
  CustomerCityID: string
  CustomerCity: string
  CustomerPhone: string
}

const EmailRegExpPattern = new RegExp(Regexp.Email)

const Register: React.FC = () => {
  const [dateInputMask, setDateInputMask] = useState<(string | RegExp)[]>([])
  const [locationsList, setLocationsList] = useState<SelectCustomerCityItem[]>(
    []
  )
  const [isOpenModalLocation, setIsOpenModalLocation] = useState(false)
  const auth = useAppSelector((state) => state.auth)
  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const [cookies, setCookies, removeCookie] = useCookies([
    CookieEnum.CustomerID,
  ])
  const editableClient = useAppSelector(
    (state) => state.specialists.editableClient
  )
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { isMobile } = useMatchMedia()
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { isValid, errors },
  } = useForm<RegisterFormFields>({
    mode: 'onChange',
    defaultValues: {
      CustomerName: editableClient ? editableClient.Name : '',
      CustomerSurname: editableClient ? editableClient.Surname : '',
      CustomerPatronymic: editableClient ? editableClient.Patronymic : '',
      CustomerEmail: editableClient ? editableClient.Email : '',
      CustomerCityID: '',
      CustomerCity: editableClient ? editableClient.CustomerCity : '',
      CustomerDateOfBirth: editableClient ? editableClient.DateOfBirth : '',
      CustomerPhone: '',
    },
  })
  const watchCustomerCityFullName = watch('CustomerCity')
  const isCabinetPathName = useDefineCurrentPathName(
    `${RoutesEnum.PersonalCabinet}/*`
  )

  useEffect(() => {
    const mask = dateInputMaskValidate('')
    setDateInputMask(mask)

    if (location.state && location.state.newPhoneNumber) {
      reset({
        CustomerPhone: location.state.newPhoneNumber,
      })
    }
  }, [])

  useEffect(() => {
    if (editableClient) {
      reset({
        CustomerDateOfBirth: editableClient.DateOfBirth,
      })
    }
  }, [editableClient])

  useEffect(() => {
    const debounceGetData = setTimeout(() => {
      if (!watchCustomerCityFullName || watchCustomerCityFullName.length < 4) {
        return
      }

      getSelectCustomerCity(watchCustomerCityFullName).then((data) => {
        if (data instanceof AxiosError) {
          dispatch(setErrorState(true))
          return
        }

        setLocationsList(data.SelectCustomerCity)
      })
    }, 500)

    return () => clearTimeout(debounceGetData)
  }, [watchCustomerCityFullName])

  useEffect(() => {
    if (!isMobile) {
      setIsOpenModalLocation(false)
    }
  }, [isMobile])

  const close = () => {
    if (isCabinetPathName) {
      navigate(RoutesEnum.PersonalCabinet)
      return
    }

    navigate(RoutesEnum.Main)
  }

  const onSubmit: SubmitHandler<RegisterFormFields> = (data) => {
    const phoneNumber = isCabinetPathName
      ? data.CustomerPhone
      : auth.customerPhone
    const nutritionistId = isCabinetPathName ? cookies.CustomerID : null

    if (editableClient && location.state.editableClient) {
      const requestEditClient = {
        CustomerName: data.CustomerName,
        CustomerSurname: data.CustomerSurname,
        CustomerPatronymic: data.CustomerPatronymic,
        CustomerDateOfBirth: data.CustomerDateOfBirth,
        CustomerEmail: data.CustomerEmail,
        CustomerCityID: data.CustomerCityID,
        CustomerCity: data.CustomerCity,
      }

      customerEditData(editableClient.CustomerID, requestEditClient).then(
        () => {
          getCustomerList(cookies.CustomerID).then((response) => {
            dispatch(updateProfileCustomerField({ clients: response }))
          })
          navigate(RoutesEnum.PersonalCabinet)
        }
      )
      return
    }

    createCustomer(phoneNumber, data, ApiRoutes.Customer, nutritionistId).then(
      (response) => {
        if (response instanceof AxiosError) {
          dispatch(setErrorState(true))
          return
        }

        if (isCabinetPathName) {
          navigate(RoutesEnum.PersonalCabinet)

          getCustomerList(cookies.CustomerID).then((response) => {
            dispatch(updateProfileCustomerField({ clients: response }))
          })

          return
        }

        const customerID = response.CustomerID
        removeCookie('CustomerID' as CookieEnum.CustomerID)
        setCookies(CookieEnum.CustomerID, customerID)
        dispatch(updateProfileCustomerField({ customerID: customerID }))

        getCustomerData(customerID).then((data) => {
          if (data instanceof AxiosError) {
            dispatch(setErrorState(true))
            return
          }

          if (data.CustomerName) {
            dispatch(updateProfileDataFromServer(data))
          }
        })

        navigate(RoutesEnum.Main)
      }
    )
  }

  const handleModalChooseLocation = () => {
    if (isMobile) {
      setIsOpenModalLocation(true)
    }
  }

  const backToRegister = () => {
    setIsOpenModalLocation(false)
  }

  return styles.length ? (
    <div className='modal-auth' role='button' tabIndex={0} onClick={close}>
      <div
        className={clsx('modal-auth__login-container', 'modal-register')}
        role='presentation'
        onClick={(event) => event.stopPropagation()}
      >
        <span
          className={clsx(
            'modal-auth__login-container__text',
            'modal-register__title'
          )}
        >
          {isCabinetPathName
            ? styles[0].TextIDsRepository.specialistPageDataAboutClient
            : styles[0].TextIDsRepository.modalLoginTitle3}
        </span>

        <span className={clsx('modal-enter-code__info')}>
          {isCabinetPathName
            ? styles[0].TextIDsRepository.clientsCreateRequiredFields
            : styles[0].TextIDsRepository.modalLoginExplanation}
        </span>

        <form
          className={clsx('modal-register__form-container', {
            'modal-register__form-container-cabinet': isCabinetPathName,
          })}
        >
          <label
            className={clsx('modal-register__form-container__label', {
              'modal-register__form-container-cabinet__label':
                isCabinetPathName,
            })}
          >
            <span
              className={clsx('modal-register__form-container__label-text', {
                'error-message-text': errors.CustomerSurname,
              })}
            >
              {styles[0].TextIDsRepository.modalLoginLastName}
            </span>
            <input
              className={clsx('modal-register__form-container__label-input', {
                'error-message-input': errors.CustomerSurname,
              })}
              placeholder={styles[0].TextIDsRepository.modalLoginLastName}
              onInput={replaceInputValue}
              {...register('CustomerSurname', {
                required: {
                  value: true,
                  message:
                    styles[0].TextIDsRepository.modalLoginLastNameWarnMessage,
                },
              })}
            />
          </label>

          <label
            className={clsx('modal-register__form-container__label', {
              'modal-register__form-container-cabinet__label':
                isCabinetPathName,
            })}
          >
            <span
              className={clsx('modal-register__form-container__label-text', {
                'error-message-text': errors.CustomerName,
              })}
            >
              {styles[0].TextIDsRepository.modalLoginFirstName}
            </span>
            <input
              className={clsx('modal-register__form-container__label-input', {
                'error-message-input': errors.CustomerName,
              })}
              placeholder={styles[0].TextIDsRepository.modalLoginFirstName}
              onInput={replaceInputValue}
              {...register('CustomerName', {
                required: {
                  value: true,
                  message:
                    styles[0].TextIDsRepository.modalLoginFirstNameWarnMessage,
                },
              })}
            />
          </label>

          <label
            className={clsx('modal-register__form-container__label', {
              'modal-register__form-container-cabinet__label':
                isCabinetPathName,
            })}
          >
            <span
              className={clsx('modal-register__form-container__label-text', {
                'error-message-text': errors.CustomerPatronymic,
              })}
            >
              {styles[0].TextIDsRepository.modalLoginSecondName}
            </span>
            <input
              className={clsx('modal-register__form-container__label-input', {
                'error-message-input': errors.CustomerPatronymic,
              })}
              placeholder={styles[0].TextIDsRepository.modalLoginSecondName}
              onInput={replaceInputValue}
              {...register('CustomerPatronymic', {
                required: {
                  value: true,
                  message:
                    styles[0].TextIDsRepository.modalLoginSecondNameWarnMessage,
                },
              })}
            />
          </label>

          {isCabinetPathName && !location.state && (
            <label
              className={clsx('modal-register__form-container__label', {
                'modal-register__form-container-cabinet__label':
                  isCabinetPathName,
              })}
            >
              <span
                className={clsx('modal-register__form-container__label-text', {
                  'error-message-text': errors.CustomerPhone,
                })}
              >
                {styles[0].TextIDsRepository.clientsTableHeaderPhone}
              </span>
              <input
                className={clsx('modal-register__form-container__label-input', {
                  'error-message-input': errors.CustomerPhone,
                })}
                placeholder={
                  styles[0].TextIDsRepository.clientsTableHeaderPhone
                }
                onInput={replaceInputValueToNumbers}
                maxLength={11}
                {...register('CustomerPhone', {
                  required: {
                    value: true,
                    message:
                      styles[0].TextIDsRepository.modalLoginPhoneWarnMessage,
                  },
                })}
              />
            </label>
          )}

          <label
            className={clsx('modal-register__form-container__label', {
              'modal-register__form-container-cabinet__label':
                isCabinetPathName,
            })}
          >
            <span
              className={clsx('modal-register__form-container__label-text', {
                'error-message-text': errors.CustomerDateOfBirth,
              })}
            >
              {styles[0].TextIDsRepository.modalLoginBirthdateName}

              <InputMask
                mask={dateInputMask}
                className={clsx('modal-register__form-container__label-input', {
                  'error-message-input': errors.CustomerDateOfBirth,
                })}
                maskPlaceholder={null}
                placeholder={
                  styles[0].TextIDsRepository.modalLoginBirthdateNamePlaceholder
                }
                {...register('CustomerDateOfBirth', {
                  required: {
                    value: true,
                    message:
                      styles[0].TextIDsRepository
                        .modalLoginBirthdateWarnMessage,
                  },
                  minLength: {
                    value: 10,
                    message:
                      styles[0].TextIDsRepository
                        .modalLoginBirthdateWarnMessage,
                  },
                  onChange: (event) => {
                    const mask = dateInputMaskValidate(event.target.value)
                    setDateInputMask(mask)
                  },
                })}
              />
            </span>
          </label>

          <label
            className={clsx('modal-register__form-container__label', {
              'modal-register__form-container-cabinet__label__email':
                location.state &&
                (location.state.editableClient ||
                  location.state.newPhoneNumber),
            })}
          >
            <span
              className={clsx('modal-register__form-container__label-text', {
                'error-message-text': errors.CustomerEmail,
              })}
            >
              {styles[0].TextIDsRepository.modalLoginEMailName}
            </span>
            <input
              className={clsx('modal-register__form-container__label-input', {
                'error-message-input': errors.CustomerEmail,
              })}
              placeholder='xiyq1vck7@temporaremail.com'
              {...register('CustomerEmail', {
                required: {
                  value: true,
                  message:
                    styles[0].TextIDsRepository.modalLoginEMailWarnMessage,
                },
                pattern: {
                  value: EmailRegExpPattern,
                  message:
                    styles[0].TextIDsRepository.modalLoginEMailWarnMessage,
                },
              })}
            />
          </label>

          <label
            className={clsx('modal-register__form-container__label', {
              'modal-register__form-container-cabinet__label':
                isCabinetPathName,
              'modal-register__form-container-cabinet__label__location':
                location.state &&
                (location.state.editableClient ||
                  location.state.newPhoneNumber),
            })}
          >
            <span
              className={clsx('modal-register__form-container__label-text', {
                'error-message-text': errors.CustomerCity,
              })}
            >
              {styles[0].TextIDsRepository.modalLoginCity}
            </span>

            {!isOpenModalLocation && (
              <AutoCompleteInput
                classNames={clsx(
                  'modal-register__form-container__label-input',
                  {
                    'error-message-input': errors.CustomerCity,
                  }
                )}
                placeholder={styles[0].TextIDsRepository.modalLoginCity}
                registerLabel='CustomerCity'
                optionalEditValue='CustomerCityID'
                register={register}
                value={watchCustomerCityFullName}
                setValue={setValue}
                dataList={locationsList}
                registerOptions={{
                  required: {
                    value: true,
                    message:
                      styles[0].TextIDsRepository.modalLoginCityWarnMessage,
                  },
                }}
                onClick={handleModalChooseLocation}
              />
            )}
          </label>

          {Object.keys(errors).length ? (
            <ErrorMessage
              classNames='modal-register__error'
              message={
                errors.CustomerCity?.message ||
                errors.CustomerName?.message ||
                errors.CustomerCityID?.message ||
                errors.CustomerEmail?.message ||
                errors.CustomerSurname?.message ||
                errors.CustomerPatronymic?.message ||
                errors.CustomerDateOfBirth?.message ||
                errors.CustomerPhone?.message
              }
            />
          ) : null}

          {!isCabinetPathName && (
            <label
              className={clsx('modal-register__form-container__label', {
                'modal-register__form-container-cabinet__label':
                  isCabinetPathName,
              })}
            >
              <span className='modal-register__form-container__privacy'>
                {styles[0].TextIDsRepository.modalLoginAgreement}
                {styles[0].TextIDsRepository.modalLoginAgreementLink}
              </span>
            </label>
          )}
        </form>

        <Button
          disabled={!isValid}
          title={
            isCabinetPathName
              ? styles[0].TextIDsRepository.clientsCreateTitle
              : styles[0].TextIDsRepository.modalLoginEndBtn
          }
          click={handleSubmit(onSubmit)}
          classNames='modal-register__create-button'
        />

        <button
          className='modal-auth__login-container__close-btn'
          onClick={close}
        >
          <X />
        </button>
      </div>

      {isOpenModalLocation && (
        <ModalChooseLocation>
          <div
            className={clsx('modal-auth__login-container', 'modal-register')}
            role='presentation'
            onClick={(event) => event.stopPropagation()}
          >
            <span
              className={clsx(
                'modal-auth__login-container__text',
                'modal-register__title',
                'modal-register__location__title'
              )}
            >
              {styles[0].TextIDsRepository.modalLoginCity}
            </span>

            <AutoCompleteInput
              classNames={clsx(
                'modal-register__form-container__label-input',
                'modal-register__location__input',
                {
                  'error-message-input': errors.CustomerCity,
                }
              )}
              placeholder={styles[0].TextIDsRepository.modalLoginCity}
              registerLabel='CustomerCityFullName'
              optionalEditValue='CustomerCityID'
              register={register}
              value={watchCustomerCityFullName}
              setValue={setValue}
              dataList={locationsList}
              registerOptions={{
                required: {
                  value: true,
                  message:
                    styles[0].TextIDsRepository.modalLoginCityWarnMessage,
                },
              }}
              onClick={handleModalChooseLocation}
              containerClassNames='modal-register__location__buttons-block'
            />

            <button
              className='modal-auth__login-container__back-btn'
              onClick={backToRegister}
            >
              <ArrowLeft />
            </button>

            <button
              className='modal-auth__login-container__close-btn'
              onClick={close}
            >
              <X />
            </button>
          </div>
        </ModalChooseLocation>
      )}
    </div>
  ) : null
}

export default Register
