import React from 'react'

import { useAppSelector } from '../../../redux/hook'

import './styles.scss'

type CheckboxProps = {
  checked: boolean
  onChange: () => void
  label?: string
}

export type CheckboxType = {
  id: number
  value: string
  checked: boolean
}
const Checkbox: React.FC<CheckboxProps> = ({ label, checked, onChange }) => {
  const { styles } = useAppSelector((state) => state.partnerInterface)

  return (
    <div className='checkbox-container'>
      <label>
        <input type='checkbox' checked={checked} onChange={onChange} />
        <span>{label}</span>
        {checked ? (
          <div className='checkbox-container__icon'>
            <img src={styles[0]?.TextIDsRepository.assets.check} alt='' />
          </div>
        ) : null}
      </label>
    </div>
  )
}

export default Checkbox
