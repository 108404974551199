import React, { useEffect, useRef, useState } from 'react'
import { ChevronDown, MessageSquare, Trash2 } from 'react-feather'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'

import {
  updateEvent,
  deleteEvent,
  EventComment,
} from '../../../redux/slices/clientEventSlice'
import TextArea from '../../UI/TextArea'
import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import {
  customerDeleteEvent,
  customerUpdateComment,
} from '../../../utils/axiosManager'
import {
  CustomerEventActions,
  EventType,
} from '../../../enums/customerEventActions'
import EventCardBody from '../EventCardBody'
import { ApiStatuses } from '../../../enums/apiRoutes'
import Spinner from '../../Spinner'
import { formatDate } from '../../../utils/formatDate'
import { smoothTransition } from '../../../utils/animations'

type EventCommentCardProps = {
  comment: EventComment
}

const EventCommentCard: React.FC<EventCommentCardProps> = ({ comment }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [header, setHeader] = useState(comment.header)
  const [body, setBody] = useState(comment.body)
  const [isLoading, setIsLoading] = useState(false)
  const firstRender = useRef(false)

  const profileData = useAppSelector((state) => state.profileSlice)
  const currentClient = useAppSelector(
    (state) => state.personalCabinet.currentClient
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!firstRender.current) {
      firstRender.current = true
      return
    }

    const changeComment = setTimeout(
      () => handleChangeComment(CustomerEventActions.Edit),
      1000
    )

    return () => clearTimeout(changeComment)
  }, [header, body])

  const handleChangeComment = (actionType: CustomerEventActions) => {
    switch (actionType) {
      case CustomerEventActions.Edit: {
        setIsLoading(true)

        customerUpdateComment(
          currentClient.data
            ? currentClient.data.CustomerID
            : profileData.customerID,
          {
            eventId: comment.eventId,
            header,
            body,
          }
        )
          .then((response) => {
            if (response.eventId) {
              dispatch(
                updateEvent({
                  eventType: EventType.Comment,
                  event: {
                    ...comment,
                    body: body,
                  },
                })
              )
            }
          })
          .finally(() => setIsLoading(false))

        return
      }

      case CustomerEventActions.Delete: {
        setIsLoading(true)

        customerDeleteEvent(
          currentClient.data
            ? currentClient.data.CustomerID
            : profileData.customerID,
          {
            eventId: comment.eventId,
          }
        )
          .then((response) => {
            if (response.status === ApiStatuses.Ok) {
              dispatch(
                deleteEvent({
                  eventType: EventType.Comment,
                  id: comment.eventId,
                })
              )
            }
          })
          .finally(() => setIsLoading(false))
        return
      }
    }
  }

  return (
    <motion.div className='event-card-container' layout {...smoothTransition}>
      <div
        className='event-card-container__header'
        role='button'
        tabIndex={0}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <button
          className={clsx('event-card-container__button', {
            'event-card-container__button-active': isOpen,
          })}
        >
          <ChevronDown />
        </button>

        <div className='event-card-container__icon'>
          <MessageSquare />
        </div>

        <div className='event-card-container__date'>
          {formatDate(comment.date.slice(0, 10))}
        </div>

        <input
          className='event-card-container__input'
          value={header}
          onChange={(event) => setHeader(event.target.value)}
          onClick={(event) => event.stopPropagation()}
        />

        <AnimatePresence>
          {isLoading ? (
            <motion.div
              className='event-card-container__delete-button'
              {...smoothTransition}
            >
              <Spinner classNames='event-card-container__spinner' />
            </motion.div>
          ) : (
            <motion.button
              className='event-card-container__delete-button'
              onClick={(event) => {
                event.stopPropagation()
                handleChangeComment(CustomerEventActions.Delete)
              }}
              {...smoothTransition}
            >
              <Trash2 />
            </motion.button>
          )}
        </AnimatePresence>
      </div>

      <EventCardBody isOpen={isOpen}>
        <TextArea
          value={body ?? ''}
          onChange={(event) => setBody(event.target.value)}
        />
      </EventCardBody>
    </motion.div>
  )
}

export default EventCommentCard
