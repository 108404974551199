import React from 'react'

import clsx from 'clsx'

import './styles.scss'

type RadioButtonProps = {
  label: string
  checked: boolean
  onChange: () => void
  disabled?: boolean
}
const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  checked,
  onChange,
  disabled,
}) => {
  return (
    <label
      className={clsx('radio-button-label', {
        'radio-button-label__disabled': disabled,
      })}
    >
      <input
        className='radio-button'
        type='radio'
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      {label}
    </label>
  )
}

export default RadioButton
