import React, { useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import clsx from 'clsx'

import { CookieEnum } from '../../../enums/cookie'
import { RoutesEnum } from '../../../enums/routes'

import './styles.scss'

type CoursePanelDropdownMenuProps = {
  Header: React.FC<any> | React.ReactElement
  Body: React.FC<any> | React.ReactElement
  classNames?: string
  checkAuth?: boolean
  headerProps?: Partial<Record<string, unknown>>
  bodyProps?: Partial<Record<string, unknown>>
}

const CoursePanelDropdownMenu: React.FC<CoursePanelDropdownMenuProps> = ({
  Header,
  Body,
  classNames,
  headerProps,
  bodyProps,
  checkAuth,
}) => {
  const [isOpenPanel, setIsOpenPanel] = useState(false)
  const [cookie] = useCookies([CookieEnum.CustomerID])
  const navigate = useNavigate()

  const handlePanelContainer = () => {
    if (checkAuth) {
      const isAuth = checkAuthorization()

      if (!isAuth) {
        navigate(RoutesEnum.Auth)
        return
      }
    }

    setIsOpenPanel((prevState) => !prevState)
  }

  const checkAuthorization = () => {
    return !!cookie.CustomerID
  }

  return (
    <div className={clsx('card-container', classNames)}>
      <div
        className='dropdown-menu-container__header'
        tabIndex={0}
        role='button'
        onClick={handlePanelContainer}
      >
        <button className='dropdown-menu-container__control-button'>
          {!isOpenPanel ? <ChevronDown /> : <ChevronUp />}
        </button>

        {React.isValidElement(Header) ? Header : <Header {...headerProps} />}
      </div>

      {isOpenPanel &&
        (React.isValidElement(Body) ? Body : <Body {...bodyProps} />)}
    </div>
  )
}

export default CoursePanelDropdownMenu
