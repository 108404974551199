import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Purpose } from './supplementsSlice'
import { CourseDragVariants } from './partnerInterfaceSlice'

export type ModalSupplementInfoControl = {
  isOpen: boolean
  supplementId: string
  supplementPicture: string
  supplementGoodsCommercialName: string
}

export type SupplementFacts = {
  ActiveComponents: string
  ActiveComponentsRaw: string
  RawTypeWeigth: number
  ActiveComponentsWeigth: number
  Units: string
  RecomendatedWeigth: number
}

type CurrentPrices = {
  DatePriceAction: string
  CostPrice: number
  MRP: number
  RRP: number
}

export type SupplementInfo = {
  Article: string
  Picture: string
  GoodsStatus: string
  GoodsStatusDate: string
  GoodsCommercialName: string
  CommercialDescription: string
  Description: string
  SupplementForm: string
  SupplementDimentions: string
  SupplementColor: string
  SupplementWeight: number
  RecommendedPerServing: number
  SupplementContraindications: string
  ActiveComponentsRaw: string
  OtherComponents: string
  Supplementsfacts: SupplementFacts[]
  Purposes: Purpose[]
  CurrentPrices: CurrentPrices
  FuturePrices: number | null
}

export type ModalSupplementInfoState = {
  controls: ModalSupplementInfoControl
  info: SupplementInfo[]
}

const initialState: ModalSupplementInfoState = {
  controls: {
    isOpen: false,
    supplementId: '',
    supplementPicture: '',
    supplementGoodsCommercialName: '',
  },
  info: [],
}

const modalSupplementInfoSlice = createSlice({
  name: 'modalSupplement',
  initialState,
  reducers: {
    modalSupplementInfoControl(
      state,
      action: PayloadAction<ModalSupplementInfoControl>
    ) {
      return {
        controls: action.payload,
        info: [],
      }
    },
  },
})

export const { modalSupplementInfoControl } = modalSupplementInfoSlice.actions

export default modalSupplementInfoSlice.reducer
