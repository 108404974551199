export enum RoutesEnum {
  Main = '/',
  Auth = '/auth',
  Login = '/auth/login',
  Register = '/auth/register',
  PersonalCabinet = '/cabinet',
  ChangePhone = '/change-phone',
  EnterCode = '/code',
  Analysis = '/analysis',
  ShortQuestionnaire = '/short-questionnaire',
  Specialists = '/specialists',
}
