import React, { useState } from 'react'

import clsx from 'clsx'

import './styles.scss'
import { ChevronDown, ChevronUp } from 'react-feather'

type AnalysisFileLoadCardProps = {
  header: React.ReactNode
  body: React.ReactNode
  hideChevron?: boolean
}

const AnalysisFileLoadCard: React.FC<AnalysisFileLoadCardProps> = ({
  header,
  body,
  hideChevron,
}) => {
  const [isOpenSelectFileCard, setIsOpenSelectFileCard] = useState(
    hideChevron ?? false
  )

  return (
    <div className='analysis-card'>
      <div
        className={clsx('analysis-card__header', {
          'analysis-card__open-header': isOpenSelectFileCard,
        })}
        role='button'
        tabIndex={0}
        onClick={() =>
          !hideChevron && setIsOpenSelectFileCard((prevState) => !prevState)
        }
      >
        {!hideChevron &&
          (isOpenSelectFileCard ? <ChevronUp /> : <ChevronDown />)}

        {header}
      </div>

      {isOpenSelectFileCard && (
        <div
          className={clsx('analysis-card__body', {
            'analysis-card__body-table': !hideChevron,
          })}
        >
          {body}
        </div>
      )}
    </div>
  )
}

export default AnalysisFileLoadCard
