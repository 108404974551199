import { useEffect } from 'react'
import { AxiosError } from 'axios'

import { setData } from '../utils/axiosManager'
import { useAppDispatch, useAppSelector } from '../redux/hook'
import {
  setCity,
  setCoords,
  setPositionError,
} from '../redux/slices/geoPositionSlice'

type PositionResponse = {
  suggestions: {
    data: {
      city: string
    }
  }[]
}

export const usePosition = (): void => {
  const dispatch = useAppDispatch()

  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const geoPosition = useAppSelector((state) => state.geoPosition)

  useEffect(() => {
    const geo = navigator.geolocation

    if (!geo) {
      dispatch(
        setPositionError(
          styles[0].TextIDsRepository.analysisPageGeopositionError
        )
      )
      return
    }

    geo.getCurrentPosition(
      onSuccessPosition,
      (error) => {
        console.error(error.message)
      },
      {
        enableHighAccuracy: true,
      }
    )
  }, [])

  const onSuccessPosition = (position: GeolocationPosition) => {
    dispatch(
      setCoords({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      })
    )

    if (!geoPosition.city) {
      setData(
        '',
        JSON.stringify({
          lat: position.coords.latitude,
          lon: position.coords.longitude,
          count: 1,
          language: 'ru',
        }),
        false,
        process.env.REACT_APP_REVERSE_GEOCODING_URL,
        {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization:
            'Token ' + process.env.REACT_APP_REVERSE_GEOCODING_TOKEN,
        }
      ).then((response: PositionResponse) => {
        if (response instanceof AxiosError) {
          return
        }

        dispatch(setCity(response.suggestions[0].data.city))
      })
    }
  }
}
