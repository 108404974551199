import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { ChevronDown, ChevronUp, Trash2, X } from 'react-feather'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'

import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import { AnalysisEntity } from '../../../redux/slices/analysisSlice'
import ModalAnalysisInfo from '../ModalAnalysisInfo'
import { useMatchMedia } from '../../../hooks/useMatchMedia'
import { deleteAnalysisFromOrder } from '../../../redux/slices/analysisOrderSlice'
import { analyzesDeleteCart } from '../../../utils/axiosManager'
import { ApiStatuses } from '../../../enums/apiRoutes'
import Spinner from '../../Spinner'
import { smoothTransition } from '../../../utils/animations'

import './styles.scss'

type AnalysisOrderCardProps = {
  groupDeleteEvent: { id: string; type: 'guid' | 'biomaterialId' }
  cardTitle: string
  entityList: AnalysisEntity[]
  research?: boolean
}

const AnalysisOrderCard: React.FC<AnalysisOrderCardProps> = ({
  groupDeleteEvent,
  cardTitle,
  entityList,
  research = false,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenName, setIsOpenName] = useState(false)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const elementRef = useRef(null)

  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const customerID = useAppSelector((state) => state.profileSlice.customerID)

  const dispatch = useAppDispatch()
  const { isMobile } = useMatchMedia()

  useEffect(() => {
    if (elementRef.current == null) {
      return
    }

    const resizeObserver = new ResizeObserver(([entry]) => {
      const childrenWidth = (entry.target.children[0] as HTMLElement)
        .offsetWidth

      setIsOverflowing(childrenWidth > entry.target.clientWidth)
    })

    resizeObserver.observe(elementRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [isOpen])

  const cardPrice = useMemo(() => {
    return entityList.reduce((acc, value) => {
      return acc + value.price
    }, 0)
  }, [entityList])

  const handleDeleteItemFromGroup = (analysis: AnalysisEntity) => {
    analyzesDeleteCart(customerID, { serviceId: analysis.serviceId }).then(
      (response) => {
        if (response.status === ApiStatuses.Ok) {
          dispatch(deleteAnalysisFromOrder({ serviceId: analysis.serviceId }))
        }
      }
    )
  }

  const handleDeleteGroup = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    setIsLoading(true)
    analyzesDeleteCart(customerID, {
      [groupDeleteEvent.type]: groupDeleteEvent.id,
    })
      .then((response) => {
        if (response.status === ApiStatuses.Ok) {
          entityList.forEach((entity) => {
            dispatch(deleteAnalysisFromOrder({ serviceId: entity.serviceId }))
          })
        }
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <div className={clsx('card-container', 'analysis-study-card')}>
      <div
        className={clsx('card-header', {
          'analysis-study-card__header': isOpen,
        })}
        role='button'
        tabIndex={0}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <button className='card-header__button'>
          {isOpen ? <ChevronUp /> : <ChevronDown />}
        </button>

        <span className='analysis-study-card__title'>{cardTitle}</span>

        <span className='analysis-study-card__title-price'>
          {cardPrice} {styles[0]?.TextIDsRepository.currency}
        </span>

        <AnimatePresence>
          {isLoading ? (
            <motion.div {...smoothTransition}>
              <Spinner classNames='event-card-container__spinner' />
            </motion.div>
          ) : (
            <motion.button
              className='analysis-study-card__trash'
              onClick={handleDeleteGroup}
              {...smoothTransition}
            >
              <Trash2 />
            </motion.button>
          )}
        </AnimatePresence>
      </div>

      {isOpen &&
        entityList.map((entity) => (
          <div
            key={entity.serviceId}
            className='analysis-study-card__content-item'
          >
            <ClickableName
              item={entity}
              research={research}
              isOpenName={isOpenName}
              ref={elementRef}
            />

            {research && (isOverflowing || isOpenName) && (
              <button
                onClick={() => setIsOpenName((prevState) => !prevState)}
                className='analysis-study-card__content-item__info-icon'
              >
                {isOpenName ? <ChevronUp /> : <ChevronDown />}
              </button>
            )}

            {research && !isMobile && (
              <span className='analysis-study-card__content-item__type-name'>
                <img
                  src={styles[0]?.TextIDsRepository.assets.analysisIcon}
                  alt=''
                />

                {entity.nameBiomaterial}
              </span>
            )}

            <span className='analysis-study-card__content-item__price'>
              {entity.price} {styles[0]?.TextIDsRepository.currency}
            </span>

            <button
              className='analysis-study-card__content-item__delete-button'
              onClick={() => handleDeleteItemFromGroup(entity)}
            >
              <X />
            </button>
          </div>
        ))}
    </div>
  )
}

const ClickableName = forwardRef<
  HTMLSpanElement,
  {
    item: AnalysisEntity
    research: boolean
    isOpenName: boolean
  }
>(function ClickableName({ item, research, isOpenName }, ref) {
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false)
  const { isMobile } = useMatchMedia()

  return (
    <>
      <span
        className={clsx('analysis-study-card__content-item__name', {
          'analysis-study-card__content-item__ammunition-name': research,
        })}
        ref={ref}
        style={{ whiteSpace: isOpenName ? 'initial' : 'nowrap' }}
        tabIndex={0}
        role='button'
        onClick={() => setIsOpenModalInfo(true)}
      >
        <span>{item.nameService}</span>
      </span>

      <ModalAnalysisInfo
        isOpenModal={isOpenModalInfo}
        setIsOpenModal={setIsOpenModalInfo}
        analysis={item}
        containerClassName='analysis-study-card__modal'
        fullScreen={isMobile}
      />
    </>
  )
})

export default AnalysisOrderCard
