import React, { useEffect, useState } from 'react'

import clsx from 'clsx'
import { AnimatePresence } from 'framer-motion'

import { useAppSelector } from '../../redux/hook'
import SupplementsListItem from '../SupplementsListItem'
import Spinner from '../Spinner'
import ModalSupplementToCourse from '../ModalSupplementToCourse'
import ModalSupplementInfo from '../ModalSupplementInfo'
import { Supplement } from '../../redux/slices/supplementsSlice'
import { useMatchMedia } from '../../hooks/useMatchMedia'

import './styles.scss'

enum SortingFilterValues {
  NameUp = 'name-up',
  NameDown = 'name-down',
  PriceUp = 'price-up',
  PriceDown = 'price-down',
  Default = '',
}

type SortingFilterType = {
  type: SortingFilterValues
}

const SupplementsList: React.FC = () => {
  const [supplementsList, setSupplementsList] = useState<Supplement[]>([])
  const [sortingFilter, setSortingFilter] = useState<SortingFilterType>({
    type: SortingFilterValues.Default,
  })
  const { purposesList, activePurpose } = useAppSelector(
    (state) => state.supplements
  )

  const error = useAppSelector((state) => state.errors.error)
  const { styles } = useAppSelector((state) => state.partnerInterface)
  const allSupplements = useAppSelector((state) => state.supplements.list)
  const { isMobile, isTablet, isDesktop } = useMatchMedia()

  useEffect(() => {
    if (activePurpose === 'catalog') {
      setSupplementsList(allSupplements)
      return
    }

    if (activePurpose && purposesList) {
      setSupplementsList(
        purposesList.filter((item) => item.purpose.Purpose === activePurpose)[0]
          .supplements
      )
    }
  }, [activePurpose])

  useEffect(() => {
    sortingMethod(sortingFilter.type)
  }, [sortingFilter])

  const handleSortingByName = () => {
    if (sortingFilter.type === SortingFilterValues.NameUp) {
      setSortingFilter({ type: SortingFilterValues.NameDown })
    } else {
      setSortingFilter({ type: SortingFilterValues.NameUp })
    }
  }

  const handleSortingByPrice = () => {
    if (sortingFilter.type === SortingFilterValues.PriceUp) {
      setSortingFilter({ type: SortingFilterValues.PriceDown })
    } else {
      setSortingFilter({ type: SortingFilterValues.PriceUp })
    }
  }

  const sortingMethod = (type: SortingFilterValues) => {
    switch (type) {
      case SortingFilterValues.NameUp: {
        const sortedSupplementList = [...supplementsList].sort((a, b) => {
          const nameA = a.GoodsCommercialName.toUpperCase()
          const nameB = b.GoodsCommercialName.toUpperCase()

          if (nameA < nameB) return -1

          if (nameA > nameB) return 1

          return 0
        })

        setSupplementsList(sortedSupplementList)
        break
      }
      case SortingFilterValues.PriceUp: {
        const sortedSupplementList = [...supplementsList].sort((a, b) => {
          const nameA = Number(a.CurrentPrices)
          const nameB = Number(b.CurrentPrices)

          if (nameA < nameB) return -1

          if (nameA > nameB) return 1

          return 0
        })

        setSupplementsList(sortedSupplementList)
        break
      }
      case SortingFilterValues.NameDown:
      case SortingFilterValues.PriceDown: {
        const sortedSupplementList = [...supplementsList].reverse()

        setSupplementsList(sortedSupplementList)
        break
      }
      default:
        return
    }
  }

  return (
    <section>
      {supplementsList.length ? (
        <div className='list-container'>
          <div className='list-container__header__row'>
            <div className='list-container__header__column'>
              <span>{styles[0]?.TextIDsRepository.formTableTHGoods}</span>

              <button
                className={clsx('list-container__sort-button', {
                  'list-container__sort-button-active':
                    sortingFilter.type === SortingFilterValues.NameUp,
                  'list-container__sort-button-active-reverse':
                    sortingFilter.type === SortingFilterValues.NameDown,
                })}
                onClick={() => handleSortingByName()}
              >
                <img
                  src={styles[0]?.TextIDsRepository.assets.tableIcon}
                  alt=''
                />
              </button>
            </div>

            {(isTablet || isDesktop) && (
              <div className='list-container__header__column'>
                {styles[0]?.TextIDsRepository.formTableTHDesc}
              </div>
            )}

            <div className='list-container__header__column'>
              {styles[0]?.TextIDsRepository.formTableTHPrice}

              <button
                className={clsx('list-container__sort-button', {
                  'list-container__sort-button-active':
                    sortingFilter.type === SortingFilterValues.PriceUp,
                  'list-container__sort-button-active-reverse':
                    sortingFilter.type === SortingFilterValues.PriceDown,
                })}
                onClick={() => handleSortingByPrice()}
              >
                <img
                  src={styles[0]?.TextIDsRepository.assets.tableIcon}
                  alt=''
                />
              </button>
            </div>

            {!isMobile && (
              <div className='list-container__header__column'></div>
            )}
          </div>

          <div className='list-container__body'>
            <AnimatePresence>
              {supplementsList.map((supplement, index) => (
                <SupplementsListItem key={index} supplement={supplement} />
              ))}
            </AnimatePresence>
          </div>
        </div>
      ) : error ? (
        <div className='error-container'>{error}</div>
      ) : (
        <Spinner classNames={clsx('spinner-full-screen')} />
      )}

      <ModalSupplementToCourse />
      <ModalSupplementInfo />
    </section>
  )
}

export default SupplementsList
