import React, { useEffect, useRef, useState } from 'react'
import { ChevronDown } from 'react-feather'
import clsx from 'clsx'
import { LayoutGroup, motion } from 'framer-motion'

import { useAppSelector } from '../../../redux/hook'
import { CurrentFilter } from '../../CoursePanel/CoursePanel'
import {
  FilteredByTimeType,
  RecipeOfDay,
  SupplementInCourse,
} from '../../../redux/slices/courseSlice'
import FilterControls from '../../shared/FilterControls'
import SortedByTimeCard from '../../SortedByTimeCard'
import SortedByTimeCardItem from '../../SortedByTimeCard/SortedByTimeCardItem'
import { pluralizeWords } from '../../../utils/pluralizeWords'
import Card from '../../UI/Card'
import ListBodyTime from '../ListBodyTime'
import { EventCourse } from '../../../redux/slices/clientEventSlice'
import { courseSortedBy } from '../../../utils/courseSortedBy'
import { getOrdersList } from '../../../utils/axiosManager'
import EventCardBody from '../EventCardBody'
import { smoothTransition } from '../../../utils/animations'
import { formatDate } from '../../../utils/formatDate'

import './styles.scss'

type EventCourseCardProps = {
  course: EventCourse
}

const EventCourseCard: React.FC<EventCourseCardProps> = ({ course }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentFilter, setCurrentFilter] = useState<CurrentFilter>({
    type: 'time',
  })
  const [timeOfReceipt, setTimeOfReceipt] = useState('')
  const [sortedByTimeList, setSortedByTimeList] = useState<FilteredByTimeType>()
  const [sortedByNameList, setSortedByNameList] = useState<
    SupplementInCourse[]
  >([])
  const [weekDeclension, setWeekDeclension] = useState('')

  const selectedRef = useRef<HTMLInputElement>(null)

  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const profileData = useAppSelector((state) => state.profileSlice)
  const supplementsList = useAppSelector((state) => state.supplements.list)

  useEffect(() => {
    getOrdersList(profileData.customerID, course.orderNumber).then(
      (response) => {
        if (supplementsList.length > 0 && response.OrdersList.length > 0) {
          const [sortedByTimeList, sortedByNameList] = courseSortedBy(
            supplementsList,
            response.OrdersList[0].SupplementCourseItems
          )
          setSortedByTimeList(sortedByTimeList)
          setSortedByNameList(sortedByNameList)
        }
      }
    )
    if (styles[0].TextIDsRepository.myCourseWeek.variantsGenitive) {
      pluralizeWords(
        styles[0]?.TextIDsRepository.localeId,
        course.courseDuration,
        styles[0]?.TextIDsRepository.myCourseWeek.variantsGenitive
      ).then((res) => setWeekDeclension(res.toLowerCase()))
    }
  }, [])

  useEffect(() => {
    if (
      profileData.selectedDeliveryCourseOrderNumberPlatform ===
        course.orderNumber.slice(0, -5) &&
      sortedByNameList.length > 0 &&
      selectedRef.current
    ) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }

    const timer = setTimeout(() => {
      if (!selectedRef.current) {
        return
      }

      if (isOpen) {
        selectedRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [
    profileData.selectedDeliveryCourseOrderNumberPlatform,
    sortedByNameList,
    course.orderNumber,
  ])

  const filterControlsButtonsList = [
    {
      title: styles[0]?.TextIDsRepository.myCourseByAppointmentTime,
      currentFilterType: 'time',
    },
    {
      title: styles[0]?.TextIDsRepository.myCourseBySupplement,
      currentFilterType: 'supplement',
    },
  ]

  return (
    <motion.div
      className={clsx('event-card-container', 'event-course-container', {
        'event-course-container__active':
          profileData.selectedDeliveryCourseOrderNumberPlatform ===
          course.orderNumber.slice(0, -5),
      })}
      ref={selectedRef}
      {...smoothTransition}
    >
      <div
        className='event-card-container__header'
        role='button'
        tabIndex={0}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <button
          className={clsx('event-card-container__button', {
            'event-card-container__button-active': isOpen,
          })}
        >
          <ChevronDown />
        </button>

        <div className='event-card-container__icon'>
          <img src={styles[0]?.TextIDsRepository.assets.packageIcon} alt='' />
        </div>

        <div className='event-card-container__date'>
          {formatDate(course.date.slice(0, 10))}
        </div>

        <div className='event-card-container__title'>
          {`${styles[0].TextIDsRepository.course} №${course.orderNumber.slice(
            0,
            -5
          )} ${styles[0].TextIDsRepository.myCourseOn} ${weekDeclension}`}
        </div>

        <span className='event-card-container__status'>
          {course.deliveryStatusForwarder}
        </span>
      </div>

      <EventCardBody
        isOpen={isOpen}
        classNamePadding='event-course-container__body'
      >
        <LayoutGroup id='eventCourseCard'>
          <FilterControls
            classNames='event-course-container__controls'
            buttonsList={filterControlsButtonsList}
            state={currentFilter}
            changeState={setCurrentFilter}
          />
        </LayoutGroup>

        {currentFilter.type === 'time' &&
          sortedByTimeList &&
          Object.entries(sortedByTimeList).map((day) => {
            return Object.entries(day[1]).map((time) => {
              return (
                <SortedByTimeCard
                  key={time[0]}
                  day={day}
                  time={time}
                  classNames='event-course-container__time-card'
                >
                  {time[1].map((supplement) => {
                    return (
                      <SortedByTimeCardItem
                        key={supplement.supplement.Article}
                        supplement={supplement}
                        numberOfItems={time[1].length}
                      />
                    )
                  })}
                </SortedByTimeCard>
              )
            })
          })}

        {currentFilter.type === 'supplement' &&
          sortedByNameList.length > 0 &&
          sortedByNameList.map((supplement) => {
            return (
              <Card
                key={supplement.supplement.Article}
                supplementInCourse={supplement}
                title={supplement.supplement.GoodsCommercialName}
                classNames='event-course-container__supplement-card'
              >
                {supplement.recipes.map((recipe: RecipeOfDay) => {
                  const allAcceptances = recipe.recipeDosage.length

                  pluralizeWords(
                    styles[0].TextIDsRepository.localeId,
                    allAcceptances,
                    styles[0].TextIDsRepository.personalAccountTimeOfReceipt
                      .variants
                  )
                    .then((result) => setTimeOfReceipt(result))
                    .catch((error) => console.log(error))
                  return (
                    <div
                      key={recipe.id}
                      className='info-side-bar__list__item-body'
                    >
                      <span>
                        {`${styles[0].TextIDsRepository.personalAccountAcceptTitle} `}

                        {`${
                          recipe.id === 'daily'
                            ? styles[0].TextIDsRepository.myCourseDaily
                            : recipe.id
                        }, `}

                        {timeOfReceipt}
                      </span>

                      <div className='info-side-bar__list__item-body__time'>
                        {recipe.recipeDosage.map((dosage) => {
                          return (
                            <ListBodyTime
                              key={dosage.id}
                              supplement={supplement}
                              dosage={dosage}
                            />
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </Card>
            )
          })}
      </EventCardBody>
    </motion.div>
  )
}

export default EventCourseCard
