export const appendLinkToDOM = (href: string) => {
  const link = document.createElement('link')
  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = href

  document.head.appendChild(link)
}

export const appendMetaDescriptionToDOM = (description: string) => {
  const metaDescription = document.createElement('meta')
  metaDescription.name = 'description'
  metaDescription.content = description

  document.head.appendChild(metaDescription)
}
