import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { PlusCircle, Trash2 } from 'react-feather'
import { ulid } from 'ulid'
import clsx from 'clsx'
import { AxiosError } from 'axios'

import {
  addCustomerTag,
  AddCustomerTagRequest,
  createTag,
  CreateTagRequest,
  deleteCustomerTag,
  deleteSpecialistTag,
  DeleteTagRequest,
  editSpecialistTag,
  EditSpecialistTagRequest,
  NutritionistClientTag,
} from '../../../utils/axiosManager'
import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import { updateProfileCustomerField } from '../../../redux/slices/profileSlice'
import Checkbox from '../../UI/Checkbox'
import { ApiStatuses } from '../../../enums/apiRoutes'
import { setErrorState } from '../../../redux/slices/errorsSlice'

import './styles.scss'

type TagsListCardProps = {
  clientTags: NutritionistClientTag[] | null
  clientId?: string
  selectedClientTags?: NutritionistClientTag['TagID'][]
  setSelectedClientTags?: (
    value: (
      prevState: NutritionistClientTag['TagID'][]
    ) => NutritionistClientTag['TagID'][]
  ) => void
}

type InitialTag = {
  id: string
  value: string
}

const initialTag = (): InitialTag => ({
  id: ulid(),
  value: '',
})

const TagsListCard = forwardRef<HTMLDivElement, TagsListCardProps>(
  function TagsListCard(
    { clientTags, clientId, selectedClientTags, setSelectedClientTags },
    ref
  ) {
    const [editable, setEditable] = useState(false)
    const [createdTag, setCreatedTag] = useState<InitialTag | null>(null)
    const [activeClientTags, setActiveClientTags] = useState<
      (NutritionistClientTag & { checked: boolean })[]
    >([])
    const currentTagInitValueRef = useRef('')

    const currentClient = useAppSelector(
      (state) => state.personalCabinet.currentClient
    )
    const profileData = useAppSelector((state) => state.profileSlice)
    const styles = useAppSelector((state) => state.partnerInterface.styles)

    const dispatch = useAppDispatch()

    useEffect(() => {
      updateActiveClientTags()

      return () => {
        setEditable(false)
      }
    }, [])

    useEffect(() => {
      updateActiveClientTags()
    }, [profileData.tags, profileData.clients, selectedClientTags])

    const updateActiveClientTags = () => {
      if (clientId) {
        const tempActiveTags = profileData.tags.map((tag) => ({
          ...tag,
          checked:
            clientTags?.some((item) => item.TagID === tag.TagID) ?? false,
        }))

        setActiveClientTags(tempActiveTags)
      }

      if (selectedClientTags) {
        const tempActiveTags = profileData.tags.map((tag) => ({
          ...tag,
          checked:
            selectedClientTags?.some((item) => item === tag.TagID) ?? false,
        }))

        setActiveClientTags(tempActiveTags)
      }
    }

    const handleControls = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      event.stopPropagation()

      if (editable && createdTag) {
        if (createdTag.value.length === 0) {
          setEditable((prevState) => !prevState)
          setCreatedTag(null)

          return
        }

        const request: CreateTagRequest = {
          NutritionistID: profileData.customerID,
          TagName: createdTag.value,
        }

        createTag(request).then((response) => {
          if (response instanceof AxiosError) {
            dispatch(setErrorState(true))
            return
          }

          dispatch(updateProfileCustomerField({ tags: response }))

          setEditable((prevState) => !prevState)
          setCreatedTag(null)
        })

        return
      }

      if (clientTags?.length) {
        setCreatedTag(null)

        setEditable((prevState) => !prevState)

        return
      }

      setEditable((prevState) => !prevState)
    }

    const handleDeleteCreatedTag = () => {
      if (createdTag) {
        setCreatedTag(initialTag())

        return
      }
    }

    const handleDeleteTag = (tagId: number) => {
      const request: DeleteTagRequest = {
        NutritionistID: profileData.customerID,
        TagID: tagId,
      }

      deleteSpecialistTag(request).then((response) => {
        if (response.DeleteTag.TagID) {
          const changedTagsList = profileData.tags.filter(
            (tagItem) => tagItem.TagID !== tagId
          )

          dispatch(updateProfileCustomerField({ tags: changedTagsList }))
        }
      })

      if (!profileData.tags.length) {
        setEditable(false)
      }
    }

    const changeSpecialistTag = (tag: NutritionistClientTag) => {
      const request: EditSpecialistTagRequest = {
        NutritionistID: profileData.customerID,
        TagID: tag.TagID,
        TagName: tag.TagName,
      }

      if (currentTagInitValueRef.current !== tag.TagName) {
        editSpecialistTag(request).then((response) => {
          if (response instanceof AxiosError) {
            dispatch(setErrorState(true))
            return
          }

          const changedTagsList = profileData.tags.map((tagItem) =>
            tagItem.TagID === tag.TagID
              ? { ...tag, ...response.EditTag }
              : tagItem
          )

          const updatedClients = profileData.clients.map((client) => {
            let clientTags = []

            clientTags = client.Tags
              ? client.Tags.map((tagItem) => {
                  const updatedTag = changedTagsList.find(
                    (findTag) => findTag.TagID === tagItem.TagID
                  )

                  if (updatedTag && tagItem.TagID === updatedTag.TagID) {
                    return { ...tagItem, TagName: updatedTag.TagName }
                  }

                  return tagItem
                })
              : []

            return { ...client, Tags: clientTags }
          })

          dispatch(
            updateProfileCustomerField({
              clients: updatedClients,
            })
          )

          dispatch(updateProfileCustomerField({ tags: changedTagsList }))
        })
      }
    }

    const handleCustomerTag = (
      tag: NutritionistClientTag & { checked: boolean }
    ) => {
      if (currentClient.data && clientId) {
        const requestTag: AddCustomerTagRequest = {
          NutritionistID: profileData.customerID,
          CustomerID: clientId,
          TagID: tag.TagID,
        }

        const updatedClients = profileData.clients.map((client) => {
          if (currentClient.data && client.CustomerID === clientId) {
            let clientTags = []

            if (tag.checked) {
              clientTags = client.Tags
                ? client.Tags.filter(
                    (clientTag) => clientTag.TagID !== tag.TagID
                  )
                : []
            } else {
              clientTags = [
                ...(client.Tags || []),
                { TagID: tag.TagID, TagName: tag.TagName },
              ]
            }

            return { ...client, Tags: clientTags }
          }

          return client
        })

        dispatch(
          updateProfileCustomerField({
            clients: updatedClients,
          })
        )

        if (!tag.checked) {
          addCustomerTag(requestTag)
            .then((response) => {
              if (response instanceof AxiosError) {
                dispatch(setErrorState(true))
                return
              }
            })
            .catch((error) => console.error(error))
        } else {
          deleteCustomerTag(requestTag).then((response) => {
            if (response.status !== ApiStatuses.Ok) {
              return
            }
          })
        }

        return
      }

      if (selectedClientTags && setSelectedClientTags) {
        setSelectedClientTags((prevState) => {
          const updatedTags =
            !tag.checked && !selectedClientTags.includes(tag.TagID)
              ? [...prevState, tag.TagID]
              : prevState.filter((value) => value !== tag.TagID)

          return updatedTags
        })
      }
    }

    return (
      <div
        className='tags-container'
        ref={ref}
        tabIndex={0}
        role='button'
        onClick={(event) => event.stopPropagation()}
      >
        <div className='tags-container__controls'>
          <button
            className='tags-container__controls__control-button'
            onClick={handleControls}
          >
            {editable
              ? styles[0].TextIDsRepository.completeButtonTitle
              : styles[0].TextIDsRepository.personalAccountChangePhone}
          </button>

          {editable && (
            <button
              className='tags-container__controls__add-button'
              onClick={() => {
                if (!createdTag) {
                  setCreatedTag(initialTag())
                }
              }}
            >
              <PlusCircle />
            </button>
          )}
        </div>

        {editable && createdTag && (
          <div className='tags-container__new-tag'>
            <input
              className='tags-container__new-tag__input'
              value={createdTag.value}
              onChange={(event) => {
                setCreatedTag({ ...createdTag, value: event.target.value })
              }}
            />
            <button
              className='tags-container__new-tag__trash-button'
              onClick={handleDeleteCreatedTag}
              disabled={createdTag && createdTag.value.length === 0}
            >
              <Trash2 />
            </button>
          </div>
        )}

        {activeClientTags.length > 0 &&
          activeClientTags.map((tag) => {
            return (
              <div
                key={tag.TagID}
                className='tags-container__new-tag__tag-item'
              >
                <Checkbox
                  key={tag.TagID}
                  checked={tag.checked}
                  onChange={() => handleCustomerTag(tag)}
                />

                {editable ? (
                  <input
                    className={clsx(
                      'event-card-container__input',
                      'tags-container__new-tag__tag-item__input'
                    )}
                    value={tag.TagName}
                    onFocus={() =>
                      (currentTagInitValueRef.current = tag.TagName)
                    }
                    onBlur={() => changeSpecialistTag(tag)}
                    onChange={(event) => {
                      const newCurrentTags = activeClientTags.map((item) =>
                        tag.TagID === item.TagID
                          ? { ...tag, TagName: event.target.value }
                          : item
                      )

                      dispatch(
                        updateProfileCustomerField({ tags: newCurrentTags })
                      )
                    }}
                    onClick={(event) => event.stopPropagation()}
                  />
                ) : (
                  <button
                    className='tags-container__new-tag__tag-item__button'
                    onClick={() => handleCustomerTag(tag)}
                  >
                    {tag.TagName}
                  </button>
                )}

                {editable && (
                  <button
                    className='tags-container__new-tag__trash-button'
                    onClick={() => handleDeleteTag(tag.TagID)}
                  >
                    <Trash2 />
                  </button>
                )}
              </div>
            )
          })}
      </div>
    )
  }
)

export default TagsListCard
