import React, { useRef } from 'react'
import './styles.scss'
import { RecipeOfDay } from '../../../redux/slices/courseSlice'
import Checkbox from '../../UI/Checkbox'
import { CheckboxType } from '../../UI/Checkbox/Checkbox'
import { useAppSelector } from '../../../redux/hook'
import { useOutsideClick } from '../../../hooks/useOutsideClick'

type CheckboxProps = {
  weekdaysCheckboxes: CheckboxType[]
  setShowCheckboxes: (state: boolean) => void
  handleCheckboxState: (checkboxId: number) => void
  article: string
}

const CheckboxContainer: React.FC<CheckboxProps> = ({
  weekdaysCheckboxes,
  setShowCheckboxes,
  handleCheckboxState,
  article,
}) => {
  const { controls, recipes } = useAppSelector((state) => state.modalSupplement)
  const { supplementsList } = useAppSelector((state) => state.course.course)
  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, setShowCheckboxes)

  return (
    <div className='modal-content__checkbox-container' ref={wrapperRef}>
      {weekdaysCheckboxes.map((checkbox) => {
        const currentSupplement = supplementsList.filter(
          (supplement) => supplement.supplement.Article === article
        )[0]

        const checked = controls.isOpen
          ? recipes.some((item: RecipeOfDay) => item.id === checkbox.value)
          : currentSupplement.recipes.some(
              (item: RecipeOfDay) => item.id === checkbox.value
            )

        return (
          <Checkbox
            key={checkbox.id}
            label={checkbox.value}
            checked={checked}
            onChange={() => handleCheckboxState(checkbox.id)}
          />
        )
      })}
    </div>
  )
}

export default CheckboxContainer
