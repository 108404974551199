export enum ApiRoutes {
  PartnerInterface = '/PartnerInterface',
  Supplements = '/Supplements',
  SupplementsList = '/SupplementsList',
  SmallSupplementCourse = '/SmallSupplementCourse',
  SupplementCourse = '/SupplementCourse',
  CreateSupplementCourse = '/CreateSupplementCourse',
  EditSupplementCourse = '/EditSupplementCourse',
  CustomerCountry = '/CustomerCountry',
  CustomerData = '/CustomerData',
  SelectCustomerCity = '/SelectCustomerCity',
  OrdersList = '/OrdersList',
  DeliveryAndPayment = '/DeliveryAndPayment',
  CreateOrder = '/CreateOrder',
  CustomerCreateComment = '/CustomerCreateComment',
  CustomerUpdateComment = '/CustomerUpdateComment',
  CustomerCreateConsultation = '/CustomerCreateConsultation',
  CustomerUpdateConsultation = '/CustomerUpdateConsultation',
  GetCustomerList = '/GetCustomerList',
  GetSurvey = '/SurveyGet',
  CustomerUpdateSurvey = '/CustomerUpdateSurvey',
  CustomerUpdateFile = '/CustomerUpdateFile',
  UploadCustomersFiles = '/CustomerUploadFile',
  AddCustomerTag = '/AddCustomerTag',
  EditTag = '/EditTag',
  DeleteTag = '/DeleteTag',
  DeleteCustomerTag = '/DeleteCustomerTag',
  GetTagsList = '/GetTagsList',
  CreateTag = '/CreateTag',
  GetCustomerEvents = '/CustomerGetEvents',
  EditCustomersFiles = '/EditCustomersFiles',
  FillSurvey = '/CustomerCreateSurvey',
  GetGroupParentAnalyzes = '/GroupParentAnalyzes',
  GetGroupChildAnalyzes = '/GroupChildAnalyzes',
  GetAnalyzes = '/Analyzes',
  GetSearchAnalyzesName = '/SearchAnalyzesName',
  GetCustomerBankAccount = '/CustomerGetBankAccount',
  CustomerCreateBankAccount = '/CustomerCreateBankAccount',
  CustomerUpdateBankAccount = '/CustomerUpdateBankAccount',
  AnalyzesAddCart = '/AnalyzesAddCart',
  AnalyzesDeleteCart = '/AnalyzesDeleteCart',
  AnalyzesGetCart = '/AnalyzesGetCart',
  Specializations = '/Specializations',
  GetCustomerInfo = '/CustomerGetInfo',
  CustomerUpdateInfo = '/CustomerUpdateInfo',
  CustomerAddInfo = '/CustomerAddInfo',
  CustomerUploadPhoto = '/CustomerUploadPhoto',
  GetCustomerPhoto = '/CustomerPhoto',
  GetSpecialists = '/CustomerGetNutritionist',
  DeleteFileEvent = '/CustomerDeleteFile',
  DeleteEvent = '/CustomerEvent',
  AnalyzesFile = '/AnalyzesFile',
  SearchAddressName = '/SearchAddressName',
  PhoneCustomer = '/PhoneCustomer',
  ValidatePhoneCustomer = '/ValidatePhoneCustomer',
  Customer = '/Customer',
  CustomerAttach = '/CustomerAttach',
  CustomerSendMail = '/CustomerSendSms',
}

export enum ApiStatuses {
  Ok = 200,
  NotFound = 404,
  ServerError = 500,
}
