import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import {
  RecipeOfDay,
  TimeOfDaySupplementsType,
} from '../../../redux/slices/courseSlice'
import { useAppSelector } from '../../../redux/hook'
import { useMatchMedia } from '../../../hooks/useMatchMedia'
import { pluralizeWords } from '../../../utils/pluralizeWords'

import './styles.scss'

type CurrentAcceptance = {
  recipes?: RecipeOfDay[]
  supplements?: TimeOfDaySupplementsType[]
  classNames?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

const CurrentAcceptance: React.FC<CurrentAcceptance> = ({
  recipes,
  supplements,
  classNames,
  onClick,
}) => {
  const [currentAcceptance, setCurrentAcceptance] = useState('')
  const { styles } = useAppSelector((state) => state.partnerInterface)
  const { isMobile } = useMatchMedia()

  useEffect(() => {
    if (!styles.length || typeof recipes === 'undefined') {
      return
    }

    const style = styles[0].TextIDsRepository
    const recipesCount = recipes?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.recipeDosage.length,
      0
    )

    pluralizeWords(
      style.localeId,
      recipesCount,
      style.myCourseAcceptance.variants
    ).then((result) => setCurrentAcceptance(result))
  }, [recipes])

  return (
    <span
      className={clsx('text-container', classNames)}
      role='button'
      tabIndex={0}
      onClick={onClick}
    >
      {supplements ? (
        <>
          <span className='text-container__count'>
            {`${supplements.length} ${styles[0].TextIDsRepository.myCourseQuantityAbbr}. `}
          </span>
          {!isMobile &&
            supplements.map((supplement, index) => {
              return (
                <span
                  key={supplement.supplement.Article}
                  className='text-container__description'
                >
                  {supplement.supplement.GoodsCommercialName}
                  {index !== supplements?.length - 1 ? ', ' : null}
                </span>
              )
            })}
        </>
      ) : (
        <>
          <span className='text-container__count'>
            {currentAcceptance}
            {!isMobile ? ': ' : ''}
          </span>
          {!isMobile &&
            recipes?.map((recipe) => {
              return recipe.recipeDosage.map((dosage, index) => {
                return (
                  <span key={dosage.id} className='text-container__description'>
                    {dosage.time}{' '}
                    {recipe.recipeDosage[index].dosage.split(' ')[0]}{' '}
                    {styles[0].TextIDsRepository.myCourseQuantityAbbr}.
                  </span>
                )
              })
            })}
        </>
      )}
    </span>
  )
}

export default CurrentAcceptance
