import React, { useState } from 'react'
import { HelpCircle } from 'react-feather'
import InputMask from 'react-input-mask'

import Checkbox from '../UI/Checkbox'
import { PaymentType } from '../../utils/axiosManager'
import { useAppDispatch } from '../../redux/hook'
import RadioButton from '../UI/RadioButton/RadioButton'
import { HeaderOptionsText } from '../ShippingPayment/ShippingPayment'
import { updateOrderField } from '../../redux/slices/orderSlice'

import './styles.scss'

type PaymentProps = {
  paymentOptions: PaymentType[]
  setCurrentPaymentMethodText: (
    value: (prevState: HeaderOptionsText) => HeaderOptionsText
  ) => void
}

const Payment: React.FC<PaymentProps> = ({
  paymentOptions,
  setCurrentPaymentMethodText,
}) => {
  const [activeDeliveryOption, setActiveDeliveryOption] = useState<number>(-1)
  const [isCardRemember, setIsCardRemember] = useState(false)
  const dispatch = useAppDispatch()

  const handleDeliveryOption = (id: number) => {
    setActiveDeliveryOption(id)
  }

  const handleCardRemember = () => {
    setIsCardRemember((prevState) => !prevState)
  }

  const deliveryOptionOnChange = (
    option: number,
    label: string,
    id: number
  ) => {
    handleDeliveryOption(option)
    setCurrentPaymentMethodText((prevState) => ({
      ...prevState,
      payment: label,
    }))
    dispatch(updateOrderField({ PaymentOptionID: id }))
  }

  return (
    <div className='delivery-container'>
      <div className='delivery-container__map-controls'>
        {/* Временно обрезаем массив пока нет оплаты на сайте */}
        {!!paymentOptions &&
          paymentOptions
            .slice(0, paymentOptions.length - 1)
            .map((payment, index: number) => (
              <RadioButton
                key={payment.ID}
                label={payment.Name}
                checked={activeDeliveryOption === index}
                onChange={() =>
                  deliveryOptionOnChange(index, payment.Name, payment.ID)
                }
              />
            ))}
      </div>

      {activeDeliveryOption === 2 && (
        <div className='payment-card-template'>
          <label className='payment-card-template__label'>
            <span>Номер карты</span>
            <InputMask
              className='payment-card-template__input'
              mask='9999 9999 9999 9999'
              maskPlaceholder='_'
              placeholder='_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _'
            />
            <div className='payment-card-template__icon' />
          </label>

          <label className='payment-card-template__label'>
            <span>Годен до</span>
            <div>
              <InputMask
                className='payment-card-template__input'
                mask='99'
                maskPlaceholder='мм'
                placeholder='мм'
              />
              <span>/</span>
              <InputMask
                className='payment-card-template__input'
                mask='99'
                maskPlaceholder='гг'
                placeholder='гг'
              />
            </div>
          </label>

          <label className='payment-card-template__label'>
            <span>CVC</span>
            <InputMask
              className='payment-card-template__input'
              mask='999'
              maskPlaceholder='___'
              placeholder='_ _ _'
            />
          </label>

          <div className='payment-card-template__remember-button'>
            <Checkbox
              label='Запомнить карту'
              checked={isCardRemember}
              onChange={handleCardRemember}
            />

            <HelpCircle />
          </div>
        </div>
      )}
    </div>
  )
}

export default Payment
