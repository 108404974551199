import { AxiosError } from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { ChevronDown, HelpCircle } from 'react-feather'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'

import PortalTooltip from '../../UI/PortalTooltip'
import TextArea from '../../UI/TextArea'
import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import ContactContainer from '../ContactContainer'
import Switch from '../../UI/Switch'
import { useMatchMedia } from '../../../hooks/useMatchMedia'
import {
  customerAddInfo,
  customerUpdateInfo,
  customerUploadPhoto,
  getCustomerInfo,
  getCustomerPhoto,
  getSpecializations,
} from '../../../utils/axiosManager'
import Checkbox from '../../UI/Checkbox'
import { CheckboxType } from '../../UI/Checkbox/Checkbox'
import { ApiStatuses } from '../../../enums/apiRoutes'
import {
  CustomerProfile,
  updateProfileCustomerField,
} from '../../../redux/slices/profileSlice'

import './styles.scss'

enum InfoStatus {
  Create = 'create',
  Edit = 'edit',
}

const ProfileAbout = () => {
  const [specializations, setSpecializations] = useState<CheckboxType[]>([])
  const [isOpenSpecList, setIsOpenSpecList] = useState(false)
  const [infoStatus, setInfoStatus] = useState<InfoStatus>(InfoStatus.Create)
  const [currentChangeField, setCurrentChangeField] = useState<{
    field: keyof CustomerProfile
    value: CustomerProfile[keyof CustomerProfile]
  }>()

  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const profileData = useAppSelector((state) => state.profileSlice)

  const dispatch = useAppDispatch()

  const firstRender = useRef(false)

  const { isMobile } = useMatchMedia()

  useEffect(() => {
    getCustomerInfo(profileData.customerID).then((response) => {
      if (
        response instanceof AxiosError &&
        response.response?.status === ApiStatuses.NotFound
      ) {
        setInfoStatus(InfoStatus.Create)

        return
      }

      setInfoStatus(InfoStatus.Edit)

      Object.entries(response).map(([field, value]) => {
        dispatch(updateProfileCustomerField({ [field]: value }))
      })
    })

    getSpecializations().then((response) => {
      if (Array.isArray(response)) {
        response.forEach((specialization, index) => {
          setSpecializations((prevState) => [
            ...prevState,
            { id: index, value: specialization, checked: false },
          ])
        })
      }
    })

    getCustomerPhoto(profileData.customerID).then((response) => {
      if (
        response instanceof AxiosError &&
        response.response?.status === ApiStatuses.NotFound
      ) {
        return
      }

      dispatch(updateProfileCustomerField({ photo: response.pathLink }))
    })
  }, [])

  useEffect(() => {
    if (
      !firstRender.current &&
      profileData.specializations.length > 0 &&
      specializations.length > 0
    ) {
      profileData.specializations.forEach((specialization: string) => {
        setSpecializations((prevState) =>
          prevState.map((spec) =>
            spec.value === specialization ? { ...spec, checked: true } : spec
          )
        )
      })

      firstRender.current = true

      return
    }
  }, [profileData.specializations, specializations])

  useEffect(() => {
    const debounceGetData = setTimeout(() => {
      if (infoStatus === InfoStatus.Edit && currentChangeField) {
        customerUpdateInfo(profileData.customerID, {
          [currentChangeField.field]: currentChangeField.value,
        }).then((response) => {
          if (
            response instanceof AxiosError &&
            response.response?.status === ApiStatuses.NotFound
          ) {
            return
          }

          Object.entries(response).map(([field, value]) => {
            dispatch(updateProfileCustomerField({ [field]: value }))
          })
        })

        return
      }

      if (infoStatus === InfoStatus.Create && currentChangeField) {
        customerAddInfo(profileData.customerID, {
          [currentChangeField.field]: currentChangeField.value,
        }).then((response) => {
          if (
            response instanceof AxiosError &&
            response.response?.status === ApiStatuses.NotFound
          ) {
            return
          }

          Object.entries(response).map(([field, value]) => {
            dispatch(updateProfileCustomerField({ [field]: value }))
          })

          setInfoStatus(InfoStatus.Edit)
        })
      }
    }, 1000)

    return () => clearTimeout(debounceGetData)
  }, [currentChangeField])

  const handleUploadProfilePhoto = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files as FileList
    const formData = new FormData()
    formData.append('image', files[0])

    const imageRequest = formData.get('image')

    if (files.length && imageRequest) {
      customerUploadPhoto(profileData.customerID, {
        photo: imageRequest,
      }).then((response) => {
        if (
          response instanceof AxiosError &&
          response.response?.status === ApiStatuses.NotFound
        ) {
          return
        }

        dispatch(updateProfileCustomerField({ photo: response.pathLink }))
      })
    }
  }

  const handleChangeSpecializations = (id: number) => {
    const result = specializations.map((specialization) => {
      if (specialization.id === id) {
        const checked = !specialization.checked
        const specs = checked
          ? [...profileData.specializations, specialization.value]
          : profileData.specializations.filter(
              (item) => item !== specialization.value
            )

        if (checked) {
          dispatch(
            updateProfileCustomerField({
              specializations: specs,
            })
          )
        } else {
          dispatch(
            updateProfileCustomerField({
              specializations: specs,
            })
          )
        }

        setCurrentChangeField({
          field: 'specializations',
          value: specs,
        })
        return { ...specialization, checked }
      }

      return specialization
    })

    setSpecializations(result)
  }

  const handleChangeInfoField = (
    field: keyof CustomerProfile,
    value: CustomerProfile[keyof CustomerProfile]
  ) => {
    dispatch(updateProfileCustomerField({ [field]: value }))

    setCurrentChangeField({ field, value })
  }

  return (
    <div className='profile-about'>
      <div className='profile-about__title-container'>
        <span className='profile-about__title'>
          {styles[0].TextIDsRepository.personalAccountAboutTitle}
        </span>

        <PortalTooltip
          text={styles[0].TextIDsRepository.personalAccountAboutDataTooltip}
          classNames='profile-about__about-tooltip'
        >
          <div className='profile-about__about-tooltip__icon'>
            <HelpCircle />
          </div>
        </PortalTooltip>
      </div>

      <div className='profile-about__photo-info'>
        <div className='profile-about__photo-container'>
          <label>
            <div className='profile-about__photo-container__choose-template'>
              {profileData.photo ? (
                <img src={profileData.photo} alt='' />
              ) : (
                <span>
                  {styles[0].TextIDsRepository.personalAccountPhotoTitle}
                </span>
              )}
            </div>

            <input
              type='file'
              onChange={handleUploadProfilePhoto}
              accept='image/*'
            />
          </label>
        </div>

        <div className='profile-about__photo-info__choose'>
          <label
            className={clsx('profile-about__photo-info__choose__label', {
              'profile-about__photo-info__choose__active':
                profileData.photo && !isMobile,
            })}
          >
            {profileData.photo ? (
              <>
                <span className='profile-about__photo-info__choose__button'>
                  {styles[0].TextIDsRepository.personalAccountChangePhone}
                </span>

                {!isMobile ? (
                  <PortalTooltip
                    text={`${styles[0].TextIDsRepository.personalAccountReqTooltipFirst} ${styles[0].TextIDsRepository.personalAccountReqTooltipSecond}`}
                    classNames='profile-about__about-tooltip'
                  >
                    <div className='profile-about__about-tooltip__icon'>
                      <HelpCircle />
                    </div>
                  </PortalTooltip>
                ) : (
                  <span className='profile-about__photo-info__choose__req'>
                    {styles[0].TextIDsRepository.personalAccountReqTooltipFirst}

                    <br />

                    {
                      styles[0].TextIDsRepository
                        .personalAccountReqTooltipSecond
                    }
                  </span>
                )}
              </>
            ) : (
              <>
                <span className='profile-about__photo-info__choose__button'>
                  {styles[0].TextIDsRepository.personalAccountChoosePhoto}
                </span>

                <span className='profile-about__photo-info__choose__req'>
                  {styles[0].TextIDsRepository.personalAccountPhotoRequirements}
                </span>
              </>
            )}

            <input
              type='file'
              onChange={handleUploadProfilePhoto}
              accept='image/*'
            />
          </label>
        </div>

        <div className='profile-about__photo-info__info'>
          <span>
            {styles[0].TextIDsRepository.personalAccountSpecializationTitle}
          </span>

          {/* TODO: отключил чтобы ветка bank-account работала*/}

          <button
            className='profile-about__specializations'
            onClick={() => setIsOpenSpecList((prevState) => !prevState)}
          >
            <div>{profileData.specializations.join(', ')}</div>
            <motion.div
              className='profile-about__specializations__chevron'
              animate={isOpenSpecList ? { rotateX: 180 } : { rotateX: 0 }}
            >
              <ChevronDown />
            </motion.div>
          </button>

          <AnimatePresence>
            {isOpenSpecList && (
              <motion.div
                className='profile-about__specializations__list'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.2,
                }}
              >
                {specializations.map((specialization) => (
                  <Checkbox
                    key={specialization.id}
                    checked={specialization.checked}
                    onChange={() =>
                      handleChangeSpecializations(specialization.id)
                    }
                    label={specialization.value}
                  />
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>

      <div className='profile-about__description'>
        <label>
          <span>
            {styles[0].TextIDsRepository.personalAccountDescriptionTitle}
          </span>

          <TextArea
            value={profileData.description}
            onChange={(event) =>
              handleChangeInfoField('description', event.target.value)
            }
            withCounter={500}
          />
        </label>
      </div>

      <div className='profile-about__professional-contacts'>
        <div className='profile-about__professional'>
          <span className='profile-about__professional__title'>
            {styles[0].TextIDsRepository.personalAccountProfessionalActivity}
          </span>

          <span className='profile-about__professional__desc'>
            {styles[0].TextIDsRepository.personalAccountSocialTitle}
          </span>

          <ContactContainer
            icon={styles[0].TextIDsRepository.assets.greyTelegramIcon}
            value={profileData.telegram}
            setValue={(event) =>
              handleChangeInfoField('telegram', event.target.value)
            }
          />
          <ContactContainer
            icon={styles[0].TextIDsRepository.assets.instagramIcon}
            value={profileData.instagram}
            setValue={(event) =>
              handleChangeInfoField('instagram', event.target.value)
            }
          />
          <ContactContainer
            icon={styles[0].TextIDsRepository.assets.vkIcon}
            value={profileData.vkontakte}
            setValue={(event) =>
              handleChangeInfoField('vkontakte', event.target.value)
            }
          />
          <ContactContainer
            icon={styles[0].TextIDsRepository.assets.whatsAppIcon}
            value={profileData.whatsapp}
            setValue={(event) =>
              handleChangeInfoField('whatsapp', event.target.value)
            }
          />
          <ContactContainer
            icon={styles[0].TextIDsRepository.assets.facebookIcon}
            value={profileData.facebook}
            setValue={(event) =>
              handleChangeInfoField('facebook', event.target.value)
            }
          />
        </div>

        <div className='profile-about__contacts'>
          <span className='profile-about__contacts__title'>
            {styles[0].TextIDsRepository.personalAccountContactTitle}
          </span>

          <span className='profile-about__professional__desc'>
            {styles[0].TextIDsRepository.personalAccountContactUsTitle}
          </span>

          <ContactContainer
            icon={styles[0].TextIDsRepository.assets.greyTelegramIcon}
            value={profileData.telegram}
            setValue={(event) =>
              handleChangeInfoField('telegram', event.target.value)
            }
          />
          <ContactContainer
            icon={styles[0].TextIDsRepository.assets.instagramIcon}
            value={profileData.instagram}
            setValue={(event) =>
              handleChangeInfoField('instagram', event.target.value)
            }
          />
          <ContactContainer
            icon={styles[0].TextIDsRepository.assets.bestFileSharingIcon}
            value={profileData.skype}
            setValue={(event) =>
              handleChangeInfoField('skype', event.target.value)
            }
          />
          <ContactContainer
            icon={styles[0].TextIDsRepository.assets.mailIcon}
            value={profileData.email}
            setValue={(event) =>
              handleChangeInfoField('email', event.target.value)
            }
          />
          <ContactContainer
            icon={styles[0].TextIDsRepository.assets.vkIcon}
            value={profileData.vkontakte}
            setValue={(event) =>
              handleChangeInfoField('vkontakte', event.target.value)
            }
          />
          <ContactContainer
            icon={styles[0].TextIDsRepository.assets.whatsAppIcon}
            value={profileData.whatsapp}
            setValue={(event) =>
              handleChangeInfoField('whatsapp', event.target.value)
            }
          />
          <ContactContainer
            icon={styles[0].TextIDsRepository.assets.facebookIcon}
            value={profileData.facebook}
            setValue={(event) =>
              handleChangeInfoField('facebook', event.target.value)
            }
          />
        </div>
      </div>

      <div className='profile-about__banner'>
        <div className='profile-about__banner__header'>
          <span className='profile-about__title'>
            {styles[0].TextIDsRepository.personalAccountBannerTitle}
          </span>

          <Switch
            checked={profileData.banner ? profileData.banner : false}
            setChecked={() =>
              handleChangeInfoField('banner', !profileData.banner)
            }
          />

          <PortalTooltip
            text={styles[0].TextIDsRepository.personalAccountAboutDataTooltip}
            classNames='profile-about__about-tooltip'
          >
            <div className='profile-about__about-tooltip__icon'>
              <HelpCircle />
            </div>
          </PortalTooltip>
        </div>

        {profileData.banner && (
          <div className='profile-about__banner__body'>
            <label>
              <span className='profile-about__banner__body__fact-title'>
                {`${styles[0].TextIDsRepository.personalAccountBannerFact} 1`}
              </span>

              <TextArea
                value={profileData.factOne ? profileData.factOne : ''}
                onChange={(event) =>
                  handleChangeInfoField('factOne', event.target.value)
                }
                withCounter={100}
              />
            </label>

            <label>
              <span className='profile-about__banner__body__fact-title'>
                {`${styles[0].TextIDsRepository.personalAccountBannerFact} 2`}
              </span>

              <TextArea
                value={profileData.factTwo ? profileData.factTwo : ''}
                onChange={(event) =>
                  handleChangeInfoField('factTwo', event.target.value)
                }
                withCounter={100}
              />
            </label>

            <label>
              <span className='profile-about__banner__body__fact-title'>
                {`${styles[0].TextIDsRepository.personalAccountBannerFact} 3`}
              </span>

              <TextArea
                value={profileData.factThree ? profileData.factThree : ''}
                onChange={(event) =>
                  handleChangeInfoField('factThree', event.target.value)
                }
                withCounter={100}
              />
            </label>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProfileAbout
