import React from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

import clsx from 'clsx'

import { replaceInputValueToNumbers } from '../../../utils/replaceInputValue'

import './styles.scss'

type InputPropsCommon = {
  title?: string
  classNameContainer?: string
  classNameInput?: string
  maxLength?: number
  error?: boolean
  numeric?: boolean
}

type InputPropsRegistered = {
  register: UseFormRegisterReturn
  value?: never
  onChange?: never
} & InputPropsCommon

type InputPropsControlled = {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  register?: never
} & InputPropsCommon

type InputProps = InputPropsControlled | InputPropsRegistered

const Input: React.FC<InputProps> = ({
  value,
  onChange,
  register,
  title,
  classNameContainer,
  classNameInput,
  maxLength,
  error,
  numeric,
}) => {
  const handleOnInput = (event: React.FormEvent<HTMLInputElement>) => {
    if (numeric) {
      replaceInputValueToNumbers(event)
    }
  }

  return (
    <label className={clsx('input-container', classNameContainer)}>
      {title && (
        <span
          className={clsx('input-title', {
            'input-title-error': error,
          })}
        >
          {title}
        </span>
      )}

      {register ? (
        <input
          className={clsx('input', classNameInput, {
            'input-error': error,
          })}
          onInput={handleOnInput}
          maxLength={maxLength}
          {...register}
        />
      ) : (
        <input
          className={clsx('input', classNameInput, {
            'input-error': error,
          })}
          value={value}
          onChange={onChange}
          onInput={handleOnInput}
          maxLength={maxLength}
        />
      )}
    </label>
  )
}

export default Input
