import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RecipeDosageType, RecipeOfDay } from './courseSlice'

export const initialRecipeDosageValue = (): RecipeDosageType => {
  return {
    id: Math.random().toString(),
    time: '',
    dosage: '1 дозировка',
  }
}

export const createValueFromFrequency = (
  payload: number,
  frequency: number,
  func: () => RecipeDosageType
) => {
  return Array.from({ length: payload - frequency }, () => func())
}

const initialState: RecipeOfDay = {
  id: 'daily',
  recipeFrequency: '1',
  recipeDosage: [initialRecipeDosageValue()],
}

const supplementOfDaySlice = createSlice({
  name: 'modalSupplement',
  initialState,
  reducers: {
    changeRecipeTime(state, action: PayloadAction<string>) {
      return { ...state, id: action.payload }
    },
    clearFields() {
      return initialState
    },
  },
})

export const { changeRecipeTime, clearFields } = supplementOfDaySlice.actions

export default supplementOfDaySlice.reducer
