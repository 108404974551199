import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ClientEventState } from './clientEventSlice'
import { NutritionistClient } from '../../utils/axiosManager'

export type CurrentClientType = {
  data: NutritionistClient | null
  events: ClientEventState | null
}

type PersonalCabinetState = {
  currentClient: CurrentClientType
}

const initialState: PersonalCabinetState = {
  currentClient: {
    data: null,
    events: null,
  },
}

const personalCabinetSlice = createSlice({
  name: 'personalCabinet',
  initialState,
  reducers: {
    setCurrentClientData(
      state,
      action: PayloadAction<{ data: NutritionistClient }>
    ) {
      if (state.currentClient) {
        state.currentClient.data = action.payload.data
        return
      }

      state.currentClient = {
        data: action.payload.data,
        events: null,
      }
    },
    setCurrentClientEvents(
      state,
      action: PayloadAction<{ events: ClientEventState }>
    ) {
      if (state.currentClient) {
        state.currentClient.events = action.payload.events
      }
    },
    setCurrentClient(
      state,
      action: PayloadAction<{
        data: NutritionistClient | null
        events: ClientEventState | null
      }>
    ) {
      state.currentClient = action.payload
    },
  },
})

export const {
  setCurrentClient,
  setCurrentClientData,
  setCurrentClientEvents,
} = personalCabinetSlice.actions

export default personalCabinetSlice.reducer
