import React from 'react'

export const replaceInputValue = (event: React.FormEvent<HTMLInputElement>) => {
  const target = event.target as HTMLInputElement
  target.value = target.value.replace(/[^a-zA-Zа-яёЁА-Я-]+/, '')
}

export const replaceInputValueToNumbers = (
  event: React.FormEvent<HTMLInputElement>
) => {
  const target = event.target as HTMLInputElement
  target.value = target.value.replace(/[^0-9]/g, '')
}
