import { AxiosError } from 'axios'

import { CustomerRequest, CustomerResponse } from '../auth/interface'
import { ApiRoutes } from '../../enums/apiRoutes'
import { putData, setData } from '../../utils/axiosManager'
import { CustomerSendMailRequest } from './interface'

export const customerEditData = async (
  customerId: string,
  data: CustomerRequest['put'],
  baseURL = ApiRoutes.Customer
): Promise<CustomerResponse['put']> => {
  return putData(baseURL + `?customerId=${customerId}`, data)
}

export const customerEditPhone = async (
  oldPhone: string,
  data: { customerId: string; newPhone: number },
  baseURL = ApiRoutes.PhoneCustomer
): Promise<{ status: number }> => {
  return putData(baseURL + `?oldPhone=${oldPhone}`, data).then((response) => {
    if (response instanceof AxiosError && response.response?.status) {
      return { status: response.response.status }
    }

    return { status: response }
  })
}

export const customerSendMail = async (
  clientId: string,
  data: CustomerSendMailRequest,
  baseURL = ApiRoutes.CustomerSendMail
): Promise<{ status: number }> => {
  return setData(baseURL + `?clientId=${clientId}`, data).then((response) => {
    if (response instanceof AxiosError && response.response?.status) {
      return { status: response.response.status }
    }

    return { status: response }
  })
}
