import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import Spinner from '../components/Spinner'
import Auth from '../components/Auth'
import Main from '../Main'
import Register from '../components/Register'
import Login from '../components/Login'
import PersonalCabinet from '../components/PersonalCabinet'
import NavigationPanel from '../components/NavigationPanel'
import Analysis from '../components/Analysis'
import { RoutesEnum } from '../enums/routes'
import { useAppSelector } from '../redux/hook'
import ShortQuestionnaire from '../components/ShortQuestionnaire'
import SpecialistsPage from '../components/SpecialistsPage'
import { AnimatePresence } from 'framer-motion'

const RootRoutes = () => {
  const { styles } = useAppSelector((state) => state.partnerInterface)

  if (styles.length === 0) {
    return <main style={{ height: '100vh', width: '100%' }}></main>
  }

  return (
    <Suspense fallback={<Spinner />}>
      <main>
        <NavigationPanel />

        <Routes>
          <Route path={RoutesEnum.Main} element={<Main />}>
            <Route
              path={RoutesEnum.Auth}
              element={
                <AnimatePresence>
                  <Auth />
                </AnimatePresence>
              }
            />
            <Route path={RoutesEnum.Login} element={<Login />} />
            <Route path={RoutesEnum.Register} element={<Register />} />
          </Route>

          <Route
            path={RoutesEnum.PersonalCabinet}
            element={<PersonalCabinet />}
          >
            <Route
              path={RoutesEnum.PersonalCabinet + RoutesEnum.Auth}
              element={<Auth />}
            />
            <Route
              path={RoutesEnum.PersonalCabinet + RoutesEnum.Register}
              element={<Register />}
            />
            <Route
              path={RoutesEnum.PersonalCabinet + RoutesEnum.ChangePhone}
              element={<Auth />}
            />
            <Route
              path={RoutesEnum.PersonalCabinet + RoutesEnum.EnterCode}
              element={<Login />}
            />
          </Route>

          <Route path={RoutesEnum.Analysis} element={<Analysis />}>
            <Route
              path={RoutesEnum.Analysis + RoutesEnum.Auth}
              element={<Auth />}
            />
            <Route
              path={RoutesEnum.Analysis + RoutesEnum.Login}
              element={<Login />}
            />
            <Route
              path={RoutesEnum.Analysis + RoutesEnum.Register}
              element={<Register />}
            />
          </Route>

          <Route
            path={RoutesEnum.ShortQuestionnaire}
            element={<ShortQuestionnaire />}
          />

          <Route path={RoutesEnum.Specialists} element={<SpecialistsPage />} />

          <Route path='*' element={<Navigate to={RoutesEnum.Main} replace />} />
        </Routes>
      </main>
    </Suspense>
  )
}

export default RootRoutes
