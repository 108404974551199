import React from 'react'
import clsx from 'clsx'
import { Info } from 'react-feather'

import Button from '../../UI/Button'
import { useAppSelector } from '../../../redux/hook'
import { SpecialistEntity } from '../../../redux/slices/specialistsSlice'

type AnalysisPanelBody = {
  currentSpecialist: SpecialistEntity
}

const AnalysisPanelBody: React.FC<AnalysisPanelBody> = ({
  currentSpecialist,
}) => {
  const styles = useAppSelector((state) => state.partnerInterface.styles)

  return (
    <>
      <div
        className={clsx(
          'analysis-panel__body__first-block',
          'analysis-panel__fade-in'
        )}
      >
        <ul>
          <li>{currentSpecialist.firstFact}</li>
          <li>{currentSpecialist.secondFact}</li>
          <li>{currentSpecialist.thirdFact}</li>
        </ul>
      </div>

      <div
        className={clsx(
          'analysis-panel__body__second-block',
          'analysis-panel__fade-in'
        )}
      >
        <div className='analysis-panel__body__second-block__info'>
          <Info />

          <span>{styles[0]?.TextIDsRepository.specialistPageSliderInfo}</span>
        </div>

        <div className='analysis-panel__body__form-button'>
          <Button
            title={styles[0]?.TextIDsRepository.specialistPageChooseSpecialist}
            click={() => console.log('Выбрать')}
          />
        </div>
      </div>
    </>
  )
}

export default AnalysisPanelBody
