import React from 'react'
import { Outlet } from 'react-router-dom'

import ErrorPage from '../ErrorPage'
import { useMatchMedia } from '../../hooks/useMatchMedia'
import { useAppSelector } from '../../redux/hook'
import AnalysisContainer from './AnalysisContainer'

import './styles.scss'

const Analysis = () => {
  const error = useAppSelector((state) => state.errors.error)

  const { isMobile } = useMatchMedia()

  return (
    <>
      {/*{error ? (*/}
      {/*  <ErrorPage />*/}
      {/*) : */}
      {isMobile ? (
        <div className='main-mobile'>
          <AnalysisContainer />
        </div>
      ) : (
        <AnalysisContainer />
      )}

      <Outlet />
    </>
  )
}

export default Analysis
