import { useEffect, useState } from 'react'
import { MediaEndpoints } from '../enums/mediaEndpoints'

export const useMatchMedia = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  function changeWindowSize() {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }

  useEffect(() => {
    window.addEventListener('resize', changeWindowSize)

    return () => {
      window.removeEventListener('resize', changeWindowSize)
    }
  }, [])

  return {
    isMobile: windowSize.width < MediaEndpoints.Mobile,
    isTablet:
      windowSize.width > MediaEndpoints.Mobile + 1 &&
      windowSize.width < MediaEndpoints.Tablet,
    isDesktop: windowSize.width > MediaEndpoints.Tablet,
  }
}
