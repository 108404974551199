import { NameOfDays } from '../enums/nameOfDays'

export const capitalizeString = (string: string) => {
  return string[0].toUpperCase() + string.substring(1)
}

export const formatDayName = (name: string) => {
  return NameOfDays[name as keyof typeof NameOfDays]
}

export const returnToOriginalFormatDayName = (name: string) => {
  const enumKey =
    Object.keys(NameOfDays)[
      Object.values(NameOfDays).indexOf(name as NameOfDays)
    ]

  return capitalizeString(enumKey)
}
