import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type Coordinates = {
  latitude: number
  longitude: number
} | null

type PositionState = {
  coords: Coordinates
  city: string | null
  error: string | null
}

const initialState: PositionState = {
  coords: null,
  city: null,
  error: null,
}

const geoPositionSlice = createSlice({
  name: 'geoPosition',
  initialState,
  reducers: {
    setCity(state, action: PayloadAction<string>) {
      state.city = action.payload
    },
    setCoords(state, action: PayloadAction<Coordinates>) {
      state.coords = action.payload
    },
    setPositionError(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
  },
})

export const { setCity, setCoords, setPositionError } = geoPositionSlice.actions

export default geoPositionSlice.reducer
