import React from 'react'

import clsx from 'clsx'
import { motion } from 'framer-motion'

import './styles.scss'

type TooltipProps = {
  text: string
  classNames?: string
}

export type CurrentTooltip = {
  isShow: boolean
  text: string
}

export const currentTooltipInitialState = {
  isShow: false,
  text: '',
}

const Tooltip: React.FC<TooltipProps> = ({ text, classNames }) => {
  return (
    <motion.div
      className={clsx('tooltip-container', classNames)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {text}
    </motion.div>
  )
}

export default Tooltip
