import React, { useEffect, useMemo, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import clsx from 'clsx'
import { AxiosError } from 'axios'

import {
  getOrdersList,
  OrdersListItem,
  OrdersListItemSmall,
  SupplementCourseItem,
} from '../../utils/axiosManager'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import Spinner from '../Spinner'
import { updateProfileCustomerField } from '../../redux/slices/profileSlice'
import { useMatchMedia } from '../../hooks/useMatchMedia'
import { setErrorState } from '../../redux/slices/errorsSlice'
import { formatDate } from '../../utils/formatDate'
import { courseSortedBy } from '../../utils/courseSortedBy'

import './styles.scss'

type CourseInDeliveryCardProps = {
  order: OrdersListItem & OrdersListItemSmall
  index: number
  currentOpenCourseDeliveryCard: number
  setCurrentOpenCourseDeliveryCard: (index: number) => void
}

const CourseInDeliveryCard: React.FC<CourseInDeliveryCardProps> = ({
  order,
  index,
  currentOpenCourseDeliveryCard,
  setCurrentOpenCourseDeliveryCard,
}) => {
  const [deliveryStatusForwarderList, setDeliveryStatusForwarderList] =
    useState(order.DeliveryStatusForwarder)
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [currentOrder, setCurrentOrder] = useState<OrdersListItem>(order)

  const profileData = useAppSelector((state) => state.profileSlice)
  const supplementsList = useAppSelector((state) => state.supplements.list)
  const styles = useAppSelector((state) => state.partnerInterface.styles)

  const dispatch = useAppDispatch()
  const { isMobile } = useMatchMedia()

  const isOpenCondition = useMemo(
    () => isOpen && Array.isArray(deliveryStatusForwarderList),
    [deliveryStatusForwarderList, isOpen]
  )

  useEffect(() => {
    if (index === 0) {
      setIsOpen(true)
      setActiveCourseInDeliveryCardState(order.SupplementCourseItems)
      dispatch(
        updateProfileCustomerField({
          selectedDeliveryCourseReceptionDuration:
            order.SupplementCourseDuration,
        })
      )
    }
  }, [])

  useEffect(() => {
    if (index !== currentOpenCourseDeliveryCard) {
      setIsOpen(false)
    }
  }, [currentOpenCourseDeliveryCard])

  const orderNumberPlatformSliced = order.OrderNumberPlatform.slice(0, -5)

  const handleIsOpen = () => {
    if (!isOpenCondition) {
      setCurrentOpenCourseDeliveryCard(index)
      if (!Array.isArray(deliveryStatusForwarderList)) {
        setIsLoading(true)
      }

      getOrdersList(profileData.customerID, order.OrderNumberPlatform).then(
        (data) => {
          setIsLoading(false)
          if (data instanceof AxiosError) {
            dispatch(setErrorState(true))
            return
          }

          setCurrentOrder(data.OrdersList[0])

          setDeliveryStatusForwarderList(
            data.OrdersList[0].DeliveryStatusForwarder
          )
          setActiveCourseInDeliveryCardState(
            data.OrdersList[0].SupplementCourseItems
          )
          dispatch(
            updateProfileCustomerField({
              selectedDeliveryCourseReceptionDuration:
                data.OrdersList[0].SupplementCourseDuration,
            })
          )
        }
      )
    }

    setIsOpen((prevState) => !prevState)
  }

  const setActiveCourseInDeliveryCardState = (
    supplementCourseItems: SupplementCourseItem[]
  ) => {
    const [sortedByTimeList, sortedByNameList] = courseSortedBy(
      supplementsList,
      supplementCourseItems
    )

    dispatch(
      updateProfileCustomerField({
        sortedBySupplementSelectedDeliveryCourse: sortedByNameList,
      })
    )

    dispatch(
      updateProfileCustomerField({
        sortedByTimeSelectedDeliveryCourse: sortedByTimeList,
      })
    )

    dispatch(
      updateProfileCustomerField({
        selectedDeliveryCourseOrderNumberPlatform: orderNumberPlatformSliced,
      })
    )
  }

  return (
    <div
      className={clsx('delivery-course-card', {
        'delivery-course-card__opened': isOpenCondition,
      })}
    >
      <div
        className={clsx('delivery-course-card__header', {
          'delivery-course-card__header__opened': isOpenCondition,
        })}
        role='button'
        tabIndex={0}
        onClick={handleIsOpen}
      >
        {isLoading ? (
          <Spinner classNames='delivery-course-card__header__spinner' />
        ) : !isOpenCondition ? (
          <ChevronDown />
        ) : (
          <ChevronUp />
        )}

        <span className='delivery-course-card__header__title'>
          №{orderNumberPlatformSliced}
        </span>

        {!isMobile && (
          <span className='delivery-course-card__header__status'>
            {Array.isArray(deliveryStatusForwarderList)
              ? deliveryStatusForwarderList[
                  deliveryStatusForwarderList.length - 1
                ].Status
              : deliveryStatusForwarderList}
          </span>
        )}

        <span className='delivery-course-card__header__price'>
          {currentOrder.OrderPrice} {styles[0]?.TextIDsRepository.currency}
        </span>
      </div>

      {isOpenCondition && currentOrder && (
        <div className='delivery-course-card__body'>
          <div className='delivery-course-card__body__statuses'>
            {Array.isArray(deliveryStatusForwarderList) &&
              deliveryStatusForwarderList.map((status, index) => (
                <div
                  key={index}
                  className='delivery-course-card__body__statuses__item'
                >
                  <div
                    className={clsx(
                      'delivery-course-card__body__statuses__icon',
                      {
                        'delivery-course-card__body__statuses__icon-active':
                          deliveryStatusForwarderList.length - 1 === index,
                      }
                    )}
                  />

                  <span className='delivery-course-card__body__statuses__status'>
                    {status.Status}
                  </span>

                  <span className='delivery-course-card__body__statuses__date'>
                    {formatDate(status.Date)} {status.Time}
                  </span>
                </div>
              ))}

            <div className='delivery-course-card__body__statuses__dotted-line' />
          </div>
          <div className='delivery-course-card__body__description'>
            <p>
              <span>{styles[0]?.TextIDsRepository.myCourseDelivery}</span>

              <span className='delivery-course-card__body__description__address'>
                {` ${currentOrder.DeliveryAddress}`}
              </span>
            </p>
            <p>
              {`${styles[0]?.TextIDsRepository.myCoursePay} ${currentOrder.PaymentOption} ${currentOrder.OrderPayment}`}
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default CourseInDeliveryCard
