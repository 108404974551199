import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { Trash2, ChevronDown, ChevronUp } from 'react-feather'

import CurrentAcceptance from '../../shared/CurrentAcceptance'
import {
  deleteSupplementFromCourse,
  SupplementInCourse,
} from '../../../redux/slices/courseSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import SupplementFactsTable from '../../shared/SupplementFactsTable/SupplementFactsTable'
import {
  modalSupplementInfoControl,
  SupplementFacts,
} from '../../../redux/slices/modalSupplementInfoSlice'
import { useDefineCurrentPathName } from '../../../hooks/useDefineCurrentPathName'

import './styles.scss'
import clsx from 'clsx'

type CardProps = {
  supplementInCourse: SupplementInCourse
  title: string
  children: ReactNode
  classNames?: string
  isCollapsedAll?: boolean
}

type CheckboxButtons = {
  id: string
  time: string
  dosage: string
  checked: boolean
}

const Card: React.FC<CardProps> = ({
  supplementInCourse,
  title,
  children,
  classNames,
  isCollapsedAll,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isOpenFacts, setIsOpenFacts] = useState<boolean>(false)
  const [supplementFacts, setSupplementFacts] = useState<SupplementFacts[]>([])
  const [checkboxButtons, setCheckboxButtons] = useState<CheckboxButtons[]>([])
  const { supplementFactsList } = useAppSelector(
    (state) => state.supplementFacts
  )
  const { styles } = useAppSelector((state) => state.partnerInterface)
  const dispatch = useAppDispatch()
  const isCabinetPathName = useDefineCurrentPathName('/cabinet/*')

  useEffect(() => {
    const newCheckboxes: CheckboxButtons[] = []

    supplementInCourse.recipes.forEach((recipe) => {
      if (
        (recipe.id !== 'daily' && supplementInCourse.recipes.length > 1) ||
        (recipe.id === 'daily' && recipe.recipeDosage.length > 1)
      ) {
        recipe.recipeDosage.forEach((dosage, index) => {
          if (
            isFinite(Number(dosage.time.replace(':', ''))) &&
            dosage.time.length
          ) {
            newCheckboxes.push({
              id: recipe.id,
              time: dosage.time,
              dosage: dosage.dosage,
              checked: checkboxButtons[index]?.checked ?? false,
            })
          }
        })
      }
    })

    setCheckboxButtons(newCheckboxes)
  }, [supplementInCourse])

  const currentFact = useMemo(() => {
    return supplementFactsList.filter(
      (fact) => fact.Article === supplementInCourse.supplement.Article
    )[0]
  }, [supplementFactsList])

  const allFacts = useMemo(() => {
    if (currentFact?.Supplementsfacts) {
      return supplementInCourse.recipes.flatMap((recipe) =>
        recipe.recipeDosage.map((dosage) =>
          currentFact.Supplementsfacts.map((fact) => ({
            ...fact,
            ActiveComponentsWeigth:
              fact.ActiveComponentsWeigth * Number(dosage.dosage[0]),
          }))
        )
      )
    }

    return []
  }, [supplementInCourse])

  useEffect(() => {
    const checkedButtons = checkboxButtons.filter((button) => button.checked)

    if (!checkedButtons.length && currentFact?.Supplementsfacts) {
      const facts = currentFact.Supplementsfacts.map((fact) => ({
        ...fact,
        ActiveComponentsWeigth:
          fact.ActiveComponentsWeigth * dosagesFromRecipes,
      }))

      setSupplementFacts(facts)
      return
    }

    const facts: SupplementFacts[] = []

    checkboxButtons.forEach((button, index) => {
      if (button.checked && !!allFacts[index]) {
        facts.push(...allFacts[index])
      }
    })

    const result = Object.values(
      facts.reduce((acc: { [key: string]: SupplementFacts }, value) => {
        acc[value.ActiveComponents] = acc[value.ActiveComponents]
          ? {
              ...value,
              ActiveComponentsWeigth:
                value.ActiveComponentsWeigth +
                acc[value.ActiveComponents].ActiveComponentsWeigth,
            }
          : value

        return acc
      }, {})
    )

    setSupplementFacts([...result])
  }, [supplementFactsList, checkboxButtons, supplementInCourse])

  useEffect(() => {
    if (isCollapsedAll) {
      setIsOpen(true)
      return
    }

    setIsOpen(false)
  }, [isCollapsedAll])

  const dosagesFromRecipes = useMemo(() => {
    return supplementInCourse?.recipes.reduce(
      (acc, currentValue) =>
        acc +
        currentValue.recipeDosage.reduce(
          (accDosage, currentValueDosage) =>
            accDosage + Number(currentValueDosage.dosage[0]),
          0
        ),
      0
    )
  }, [supplementInCourse])

  const handleDeleteCard = (event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(deleteSupplementFromCourse(supplementInCourse.supplement.Article))
  }

  const handleIsOpen = () => {
    setIsOpen((prevState) => !prevState)
    setIsOpenFacts(false)
  }

  const handleModalInfo = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    dispatch(
      modalSupplementInfoControl({
        isOpen: true,
        supplementId: supplementInCourse.supplement.Article,
        supplementPicture: supplementInCourse.supplement.Picture,
        supplementGoodsCommercialName:
          supplementInCourse.supplement.GoodsCommercialName,
      })
    )
  }

  const handleIsOpenWithFacts = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    if (isOpen && !isOpenFacts) {
      setIsOpenFacts(true)
      return
    } else if (isOpen && isOpenFacts) {
      setIsOpen(false)
      setIsOpenFacts(false)
      return
    }

    setIsOpen((prevState) => !prevState)
    setIsOpenFacts((prevState) => !prevState)
  }

  return (
    <div className={clsx('card-container', classNames)}>
      <div
        className='card-header'
        role='button'
        tabIndex={0}
        onClick={handleIsOpen}
      >
        <button className='card-header__button'>
          {isOpen ? <ChevronUp /> : <ChevronDown />}
        </button>

        <div className='card-header__image-container'>
          <img src={supplementInCourse.supplement.Picture} alt='' />
        </div>

        <span
          className='card-header__title'
          role='button'
          tabIndex={0}
          onClick={handleModalInfo}
        >
          {title}
        </span>

        <div className='time-card-container__product-count'>
          <CurrentAcceptance
            recipes={supplementInCourse.recipes}
            onClick={handleIsOpenWithFacts}
          />
        </div>

        {!isCabinetPathName && (
          <button
            onClick={handleDeleteCard}
            className='card-header__delete-button'
          >
            <Trash2 />
          </button>
        )}
      </div>
      {isOpen && <hr className='bottom-divider' />}

      {isOpen && (
        <div className='card-body'>
          {children}

          {isOpenFacts ? (
            <>
              <div className='card-body__time-filter'>
                {checkboxButtons.map((button, index) => (
                  <div key={index} className='card-body__time-filter__button'>
                    <label>
                      <input
                        type='checkbox'
                        value={`${button.id} в ${button.time}`}
                        onChange={() => {
                          const tempArray = [...checkboxButtons]
                          tempArray[index].checked = !tempArray[index].checked
                          setCheckboxButtons(tempArray)
                        }}
                        hidden
                      />
                      <span>{`${
                        button.id === 'daily'
                          ? styles[0]?.TextIDsRepository.myCourseDaily
                          : button.id
                      } в ${button.time}`}</span>
                    </label>
                  </div>
                ))}
              </div>

              {supplementFacts && (
                <SupplementFactsTable
                  supplementFacts={supplementFacts}
                  classNames='card-body__facts-table'
                />
              )}
            </>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default Card
