import React, { useEffect, useState } from 'react'

import { AxiosError } from 'axios'
import { AnimatePresence } from 'framer-motion'

import { useAppDispatch, useAppSelector } from '../../redux/hook'
import {
  modalSupplementInfoControl,
  SupplementInfo,
} from '../../redux/slices/modalSupplementInfoSlice'
import { getSupplement } from '../../utils/axiosManager'
import { setErrorState } from '../../redux/slices/errorsSlice'
import Spinner from '../Spinner'
import SupplementFactsTable from '../shared/SupplementFactsTable/SupplementFactsTable'
import { useMatchMedia } from '../../hooks/useMatchMedia'
import { pluralizeWords } from '../../utils/pluralizeWords'
import { CourseDragVariants } from '../../redux/slices/partnerInterfaceSlice'
import { ModalWrapper } from '../ModalWrapper'

import './styles.scss'

const ModalSupplementInfo: React.FC = () => {
  const { controls } = useAppSelector((state) => state.modalSupplementInfo)
  const { error } = useAppSelector((state) => state.errors)
  const { styles } = useAppSelector((state) => state.partnerInterface)
  const [supplement, setSupplement] = useState<SupplementInfo[]>([])
  const [supplementForm, setSupplementForm] = useState('')
  const dispatch = useAppDispatch()
  const { isMobile, isTablet } = useMatchMedia()

  useEffect(() => {
    if (controls.supplementId) {
      getSupplement(controls.supplementId).then((data) => {
        if (data instanceof AxiosError) {
          dispatch(setErrorState(true))
          return
        }

        setSupplement(data.Supplements)
        dispatch(setErrorState(false))
      })
    }
  }, [controls.supplementId])

  useEffect(() => {
    if (supplement.length && styles.length) {
      const form = supplement[0].SupplementForm as CourseDragVariants

      pluralizeWords(
        styles[0].TextIDsRepository.localeId,
        1,
        styles[0].TextIDsRepository.myCourseDrag[form].variants
      ).then((data) => {
        const result = data.split(' ')[1]
        setSupplementForm(result)
      })
    }
  }, [supplement])

  const close = () => {
    dispatch(
      modalSupplementInfoControl({
        isOpen: false,
        supplementId: '',
        supplementPicture: '',
        supplementGoodsCommercialName: '',
      })
    )
    setSupplement([])
  }

  return (
    <AnimatePresence>
      {controls.isOpen && (
        <ModalWrapper
          close={close}
          containerClassNames='modal-supplement-info'
          contentClassNames='modal-supplement-info-content'
        >
          {!isMobile && !isTablet && (
            <div className='modal-supplement-info-content__image-container'>
              <img src={controls.supplementPicture} alt='' />
            </div>
          )}

          <div className='modal-supplement-info-content__body'>
            {isMobile || isTablet ? (
              <>
                <span className='modal-supplement-info-content__title'>
                  {controls.supplementGoodsCommercialName}
                </span>
                <div className='modal-supplement-info-content__image-container'>
                  <img src={controls.supplementPicture} alt='' />
                </div>
              </>
            ) : (
              <span className='modal-supplement-info-content__title'>
                {controls.supplementGoodsCommercialName}
              </span>
            )}
            {supplement.length ? (
              <div className='modal-supplement-info-content__info-container'>
                <div className='modal-supplement-info-content__info-block'>
                  <span className='modal-supplement-info-content__info-block__title'>
                    {styles[0].TextIDsRepository.formTableTHDesc}
                  </span>
                  <span className='modal-supplement-info-content__info-block__text'>
                    {supplement[0].CommercialDescription}
                  </span>
                  <span className='modal-supplement-info-content__info-block__text'>
                    {supplement[0].Description}
                  </span>
                </div>

                <div className='modal-supplement-info-content__info-block'>
                  <span className='modal-supplement-info-content__info-block__title'>
                    {
                      styles[0].TextIDsRepository
                        .modalSupplementCardActiveComponents
                    }
                  </span>
                  <span className='modal-supplement-info-content__info-block__text'>
                    {supplement[0].ActiveComponentsRaw}
                  </span>
                </div>

                <div className='modal-supplement-info-content__info-block'>
                  <span className='modal-supplement-info-content__info-block__title'>
                    {
                      styles[0].TextIDsRepository
                        .modalSupplementCardOtherComponents
                    }
                  </span>
                  <span className='modal-supplement-info-content__info-block__text'>
                    {supplement[0].OtherComponents}
                  </span>
                </div>

                <div className='modal-supplement-info-content__info-block'>
                  <span className='modal-supplement-info-content__info-block__title'>
                    {
                      styles[0].TextIDsRepository
                        .modalSupplementCardContraindications
                    }
                  </span>
                  <span className='modal-supplement-info-content__info-block__text'>
                    {supplement[0].SupplementContraindications}
                  </span>
                </div>

                <div className='modal-supplement-info-content__info-block'>
                  <table>
                    <thead>
                      <tr>
                        <td>
                          {
                            styles[0].TextIDsRepository
                              .modalSupplementCardSupplementForm
                          }
                        </td>
                        <td>
                          {
                            styles[0].TextIDsRepository
                              .modalSupplementCardSupplementDimentions
                          }
                        </td>
                        {!isMobile && (
                          <td>
                            {
                              styles[0].TextIDsRepository
                                .modalSupplementCardSupplementColor
                            }
                          </td>
                        )}
                        <td>
                          {
                            styles[0].TextIDsRepository
                              .modalSupplementCardRecommendedPerServing
                          }
                        </td>
                        <td>
                          {
                            styles[0].TextIDsRepository
                              .modalSupplementCardSupplementWeight
                          }
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{supplementForm}</td>
                        <td>{supplement[0].SupplementDimentions}</td>
                        {!isMobile && <td>{supplement[0].SupplementColor}</td>}
                        <td>{supplement[0].RecommendedPerServing}</td>
                        <td>{supplement[0].SupplementWeight}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className='modal-supplement-info-content__facts-table-block'>
                  {supplement[0].Supplementsfacts && (
                    <SupplementFactsTable
                      supplementFacts={supplement[0].Supplementsfacts}
                    />
                  )}
                </div>
              </div>
            ) : error ? (
              <div className='error-container'>{error}</div>
            ) : (
              <Spinner />
            )}
          </div>
        </ModalWrapper>
      )}
    </AnimatePresence>
  )
}

export default ModalSupplementInfo
