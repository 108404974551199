import React, { useState } from 'react'
import { Plus, Search } from 'react-feather'
import clsx from 'clsx'

import Calendar from '../../UI/Calendar'
import { useAppSelector } from '../../../redux/hook'
import Button from '../../UI/Button'
import EventPaymentRequestCard from '../EventPaymentRequestCard'
import ModalPayment from '../../ModalPayment'
import { useMatchMedia } from '../../../hooks/useMatchMedia'

import './styles.scss'

const ProfilePayments = () => {
  const [showModalPayment, setShowModalPayment] = useState(false)
  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const { isMobile } = useMatchMedia()

  return (
    <div>
      <div
        className={clsx(
          'clients-container__header',
          'payments-container__header'
        )}
      >
        <Button
          title={isMobile ? <Plus /> : 'Создать'}
          click={() => setShowModalPayment(true)}
          classNames={clsx({
            'clients-container__header__button': isMobile,
          })}
        />

        {!isMobile && (
          <div className='accruals-container__calendar-wrapper'>
            <Calendar
              label={styles[0].TextIDsRepository.personalAccountStartDate}
            />

            <hr className='accruals-container__calendar-wrapper__separator' />

            <Calendar
              label={styles[0].TextIDsRepository.personalAccountCompletionDate}
            />
          </div>
        )}

        <label className='clients-container__header__label'>
          <input
            className='clients-container__header__search'
            placeholder='Поиск по ФИО'
          />

          <Search />
        </label>
      </div>

      <div>
        <EventPaymentRequestCard />
      </div>

      <ModalPayment
        showModalPayment={showModalPayment}
        setShowModalPayment={setShowModalPayment}
      />
    </div>
  )
}

export default ProfilePayments
