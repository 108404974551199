export const dateInputMaskValidate = (date: string) => {
  const separateDateString = date.split('.')
  const numberOfDay = separateDateString[0]
  const numberOfMonth = separateDateString[1]
  const numberOfYear = separateDateString[2]

  let secondNumberOfDayMask = /[0-9]/
  let secondNumberOfMonthMask = /[0-9]/
  let secondNumberOfYearMask = '9'
  let thirdNumberOfYearMask = /[0-9]/

  if (numberOfDay?.startsWith('0')) {
    secondNumberOfDayMask = /[1-9]/
  }

  if (numberOfDay?.startsWith('3')) {
    secondNumberOfDayMask = /[0-1]/
  }

  if (numberOfMonth?.startsWith('0')) {
    secondNumberOfMonthMask = /[1-9]/
  }

  if (numberOfMonth?.startsWith('1')) {
    secondNumberOfMonthMask = /[0-2]/
  }

  if (numberOfYear?.startsWith('2')) {
    secondNumberOfYearMask = '0'
    thirdNumberOfYearMask = /[0-2]/
  }

  return [
    /[0-3]/,
    secondNumberOfDayMask,
    '.',
    /[0-1]/,
    secondNumberOfMonthMask,
    '.',
    /[1-2]/,
    secondNumberOfYearMask,
    thirdNumberOfYearMask,
    /[0-9]/,
  ]
}
