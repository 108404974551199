import React, { useState } from 'react'

import {
  RecipeDosageType,
  SupplementInCourse,
} from '../../../redux/slices/courseSlice'
import { useAppSelector } from '../../../redux/hook'
import { CourseDragVariants } from '../../../redux/slices/partnerInterfaceSlice'
import { pluralizeWords } from '../../../utils/pluralizeWords'

const ListBodyTime = ({
  supplement,
  dosage,
}: {
  supplement: SupplementInCourse
  dosage: RecipeDosageType
}) => {
  const [declension, setDeclension] = useState('')
  const { styles } = useAppSelector((state) => state.partnerInterface)

  if (!styles.length) {
    return null
  }

  const form = supplement.supplement.SupplementForm as CourseDragVariants

  pluralizeWords(
    styles[0].TextIDsRepository.localeId,
    Number(dosage.dosage),
    styles[0].TextIDsRepository.myCourseDrag[form].variants
  ).then((data) => setDeclension(data))

  return (
    <>
      <div key={dosage.id}>
        <span className='info-side-bar__list__item-body__bold-time'>
          {dosage.time}
        </span>{' '}
        <span>{declension}</span>
      </div>
    </>
  )
}

export default ListBodyTime
