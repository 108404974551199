import React, { useMemo, useState } from 'react'
import { Download, Trash2 } from 'react-feather'
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { AnimatePresence } from 'framer-motion'
import { AxiosError } from 'axios'

import AnalysisFileLoadCard from './AnalysisFileLoadCard/AnalysisFileLoadCard'
import Button from '../UI/Button'
import { CookieEnum } from '../../enums/cookie'
import { useAppDispatch, useAppSelector } from '../../redux/hook'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import { RoutesEnum } from '../../enums/routes'
import Tooltip from '../UI/Tooltip'
import { deleteAnalyzesFile } from '../../utils/axiosManager'
import { deleteUploadFile } from '../../redux/slices/uploadFilesSlice'
import { setErrorState } from '../../redux/slices/errorsSlice'

type AnalysisContainerProps = {
  dragEnterHandler: (event: React.DragEvent<HTMLDivElement>) => void
  dragLeaveHandler: (event: React.DragEvent<HTMLDivElement>) => void
  handleUploadFiles: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const AnalysisLoad: React.FC<AnalysisContainerProps> = ({
  dragEnterHandler,
  dragLeaveHandler,
  handleUploadFiles,
}) => {
  const [currentTooltip, setCurrentTooltip] = useState<boolean>(false)
  const [cookies] = useCookies([CookieEnum.CustomerID])
  // const analyzes = useAppSelector((state) => state.clientEvent.analyzes)
  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const uploadFiles = useAppSelector((state) => state.uploadFiles.fileList)
  const profileData = useAppSelector((state) => state.profileSlice)

  const dispatch = useAppDispatch()

  const errorMessage = useMemo(() => {
    if (styles[0]) {
      return styles[0].TextIDsRepository.analysisTooltip.split(',')
    }

    return []
  }, [styles])

  const handleDeleteCard = (fileId: string) => {
    deleteAnalyzesFile(profileData.customerID, { id: fileId }).then(
      (response) => {
        if (response instanceof AxiosError) {
          dispatch(setErrorState(true))
          return
        }

        dispatch(deleteUploadFile({ fileId }))
      }
    )
  }

  return (
    <div
      className='analysis-container'
      onDragEnter={dragEnterHandler}
      onDragLeave={dragLeaveHandler}
      onDragOver={(event) => {
        event.preventDefault()
        event.stopPropagation()
      }}
    >
      <div className='analysis-container__content'>
        <span className='analysis-container__content__description'>
          {styles[0]?.TextIDsRepository.analysisDescription}
        </span>

        <AnalysisFileLoadCard
          hideChevron={true}
          header={
            <>
              <span>{styles[0]?.TextIDsRepository.analysisTypeTitle}</span>

              <div
                onMouseEnter={() => setCurrentTooltip(true)}
                onMouseLeave={() => setCurrentTooltip(false)}
              >
                <Button
                  title={styles[0]?.TextIDsRepository.analysisDownloadTitle}
                  click={() => console.log('download')}
                  classNames='file-upload-button'
                  disabled={!cookies.CustomerID && uploadFiles.length === 2}
                >
                  {cookies.CustomerID ||
                  (!cookies.CustomerID && uploadFiles.length < 2) ? (
                    <input type='file' multiple onChange={handleUploadFiles} />
                  ) : null}
                </Button>
              </div>

              <AnimatePresence>
                {currentTooltip &&
                  !cookies.CustomerID &&
                  uploadFiles.length === 2 && (
                    <Tooltip
                      text={styles[0]?.TextIDsRepository.analysisTooltip}
                      classNames='analysis-card__tooltip'
                    />
                  )}
              </AnimatePresence>
            </>
          }
          body={
            <>
              <span className='analysis-card__body-description'>
                {styles[0]?.TextIDsRepository.analysisTypeDescription}
              </span>
            </>
          }
        />

        <div className='analysis-container__content__cards'>
          {uploadFiles.map((file) => (
            <AnalysisFileLoadCard
              key={
                file.file
                  ? file.file.lastModified + file.file.size
                  : file.fileLink
              }
              header={
                <>
                  <span className='analysis-container__content__file-title'>
                    {file.file ? file.file.name : file.fileName}
                  </span>

                  <div className='event-file__body-item__button-group'>
                    <a
                      className='event-file__body-item__button-group__link'
                      href={file.fileLink}
                      onClick={(event) => event.stopPropagation()}
                      download={file.file ? file.file.name : file.fileName}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <Download />
                    </a>
                    <button
                      onClick={(event) => {
                        event.stopPropagation()

                        if (file.fileId) {
                          handleDeleteCard(file.fileId)
                        }
                      }}
                    >
                      <Trash2 />
                    </button>
                  </div>
                </>
              }
              body={
                <>
                  <div className='analysis-container__content__table'>
                    <div className='analysis-container__content__table-item'>
                      <span className='analysis-container__content__table-title'>
                        Название/Показатель
                      </span>
                      <span className='analysis-container__content__table-title'>
                        Результат
                      </span>
                      <span className='analysis-container__content__table-title'>
                        Референс
                      </span>
                    </div>
                    {/*{analyzes[0].AnalyzesResults.map((anal) => {*/}
                    {/*  return (*/}
                    {/*    <div*/}
                    {/*      key={anal.Result}*/}
                    {/*      className='analysis-container__content__table-item'*/}
                    {/*    >*/}
                    {/*      <span className='analysis-container__content__table-text'>*/}
                    {/*        {anal.Parameter}*/}
                    {/*      </span>*/}

                    {/*      <input*/}
                    {/*        className='event-card-container__small-input'*/}
                    {/*        defaultValue={anal.Result}*/}
                    {/*        onChange={() => console.log('edit')}*/}
                    {/*      />*/}

                    {/*      <span className='analysis-container__content__table-text'>*/}
                    {/*        {anal.Reference}*/}
                    {/*      </span>*/}
                    {/*    </div>*/}
                    {/*  )*/}
                    {/*})}*/}
                  </div>
                </>
              }
            />
          ))}
        </div>

        {!cookies.CustomerID && uploadFiles.length === 2 && (
          <ErrorMessage
            message={
              <>
                <Link to={RoutesEnum.Analysis + RoutesEnum.Auth}>
                  {errorMessage[0]}
                </Link>
                <span>, {errorMessage[1]}</span>
              </>
            }
            classNames={clsx('analysis-container__error', 'warning-message')}
          />
        )}

        {uploadFiles.length !== 0 && (
          <div
            className={clsx('error-message', 'analysis-container__file-error')}
          >
            <span className='analysis-container__file-error__bold-text'>
              Не удалось распознать:
            </span>

            <ul className='analysis-container__file-error__list'>
              {uploadFiles.map(
                ({ file }) => file && <li key={file.name}>{file.name}</li>
              )}
            </ul>

            <span className='analysis-container__file-error__bold-text'>
              Убедитесь, что загружаете файлы от “Инвитро”, “Гемотест” или
              “Helix”
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default AnalysisLoad
