import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Menu, X } from 'react-feather'
import { useCookies } from 'react-cookie'

import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'

import { useAppSelector } from '../../redux/hook'
import { changeActivePurpose } from '../../redux/slices/supplementsSlice'
import { useDefineCurrentPathName } from '../../hooks/useDefineCurrentPathName'
import Tooltip, {
  CurrentTooltip,
  currentTooltipInitialState,
} from '../UI/Tooltip'
import { useMatchMedia } from '../../hooks/useMatchMedia'
import { RoutesEnum } from '../../enums/routes'
import BottomContainer from './BottomContainer'
import { CookieEnum } from '../../enums/cookie'

import './styles.scss'

const NavigationPanel: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const isCabinetPathName = useDefineCurrentPathName('/cabinet/*')
  const isAnalysisPathName = useDefineCurrentPathName(
    `${RoutesEnum.Analysis}/*`
  )
  const isSpecialistsPathName = useDefineCurrentPathName(
    `${RoutesEnum.Specialists}/*`
  )
  const [currentTooltip, setCurrentTooltip] = useState<CurrentTooltip>(
    currentTooltipInitialState
  )

  const [activeCatalogItem, setActiveCatalogItem] = useState(
    location.state?.activeCatalogItem === -1 ? -1 : 0
  )
  const { styles } = useAppSelector((state) => state.partnerInterface)
  const { purposesList, activePurpose } = useAppSelector(
    (state) => state.supplements
  )
  const dispatch = useDispatch()
  const { isMobile, isTablet, isDesktop } = useMatchMedia()
  const [isOpenPanel, setIsOpenPanel] = useState(!isMobile)
  const [cookie] = useCookies([CookieEnum.CustomerID])

  const catalogList = purposesList
    .map((purpose) => {
      return {
        title: purpose.purpose.Purpose,
        icon: purpose.purpose.PurposePicture,
      }
    })
    .sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0))

  useEffect(() => {
    if (catalogList.length) {
      dispatch(changeActivePurpose('catalog'))

      if (!location.state) {
        return
      }

      if (location.state.activeCatalogItem === 0) {
        handleClickCatalogItem(0, 'catalog')
        return
      }

      if (location.state.activeCatalogItem !== -1) {
        handleClickCatalogItem(
          location.state.activeCatalogItem,
          catalogList[location.state.activeCatalogItem - 1].title
        )
      }
    }
  }, [])

  useEffect(() => {
    if (activePurpose.length) {
      return
    }

    if (catalogList.length) {
      dispatch(changeActivePurpose('catalog'))
    }
  }, [catalogList])

  useEffect(() => {
    if (isAnalysisPathName || isCabinetPathName || isSpecialistsPathName) {
      setActiveCatalogItem(-1)
    }
  }, [isAnalysisPathName, isCabinetPathName, isSpecialistsPathName])

  const handleClickCatalogItem = (index: number, item: string) => {
    dispatch(changeActivePurpose(item))
    setActiveCatalogItem(index)
  }

  const handleOnMouseEnter = (text: string) => {
    setCurrentTooltip({
      isShow: true,
      text,
    })
  }

  const handleOnMouseLeave = () => {
    setCurrentTooltip(currentTooltipInitialState)
  }

  const animate = {
    desktop: { width: isOpenPanel ? 280 : 76 },
    tablet: { width: isOpenPanel ? 230 : 48 },
    mobile: { width: '100%', height: isOpenPanel ? '100%' : '48px' },
  }

  const animateTransition = {
    type: 'spring',
    stiffness: 5000,
    damping: 100,
    mass: 0.2,
  }

  return (
    <motion.nav
      className={clsx({
        'nav-opened': isOpenPanel,
        'nav-opened-tablet': isOpenPanel && isTablet,
      })}
      animate={
        isDesktop ? animate.desktop : isTablet ? animate.tablet : animate.mobile
      }
      transition={animateTransition}
    >
      <div
        className={clsx({
          'top-container': isOpenPanel,
          'top-container-minimized': !isOpenPanel,
        })}
      >
        {(isOpenPanel && (isTablet || isDesktop)) ||
        (!isOpenPanel && isMobile) ? (
          <button
            className={clsx('top-container__logo')}
            onClick={() => {
              if (
                isCabinetPathName ||
                isAnalysisPathName ||
                isSpecialistsPathName
              ) {
                navigate(RoutesEnum.Main, {
                  state: {
                    activeCatalogItem: 0,
                  },
                })
              }

              handleClickCatalogItem(0, 'catalog')
            }}
          >
            <img src={styles[0]?.PartnerLogo} alt='' />
          </button>
        ) : null}

        <button
          className={clsx('top-container__button', {
            'top-container__button__mobile-collapsed': isMobile && isOpenPanel,
          })}
          onMouseEnter={() =>
            handleOnMouseEnter(
              isOpenPanel
                ? styles[0]?.TextIDsRepository.sidebarMenuCloseBtnTooltip
                : styles[0]?.TextIDsRepository.sidebarMenuOpenBtnTooltip
            )
          }
          onMouseLeave={handleOnMouseLeave}
          onClick={() => setIsOpenPanel((prevState) => !prevState)}
        >
          <Menu />

          <AnimatePresence>
            {currentTooltip.isShow &&
              (currentTooltip.text ===
                styles[0]?.TextIDsRepository.sidebarMenuCloseBtnTooltip ||
                currentTooltip.text ===
                  styles[0]?.TextIDsRepository.sidebarMenuOpenBtnTooltip) && (
                <Tooltip
                  text={currentTooltip.text}
                  classNames='top-container__tooltip'
                />
              )}
          </AnimatePresence>
        </button>
      </div>

      {isMobile && isOpenPanel ? (
        <button
          onClick={() => setIsOpenPanel(false)}
          className='mobile-close-button'
        >
          <X />
        </button>
      ) : null}

      <ul
        className={clsx({
          controls: isOpenPanel,
          'controls-minimized': !isOpenPanel,
          'controls-mobile': isMobile && isOpenPanel,
          'controls-mobile-minimized': isMobile && !isOpenPanel,
        })}
      >
        <motion.button
          className={clsx('controls__list__article-button', {
            'controls__list__article-button__closed': !isOpenPanel,
            'active-list-item': isAnalysisPathName,
          })}
          onMouseEnter={() =>
            handleOnMouseEnter(styles[0]?.TextIDsRepository.analysisTitle)
          }
          onMouseLeave={handleOnMouseLeave}
          onClick={() => {
            if (!styles[0]?.PartnerMenu.hasOwnProperty('analysisPage')) {
              navigate(RoutesEnum.Analysis, {
                state: { activeCatalogItem: -1 },
              })
            }
          }}
          whileHover={{ scale: 1.02 }}
        >
          {isOpenPanel ? (
            <span>{styles[0]?.TextIDsRepository.analysisTitle}</span>
          ) : (
            <img
              className='controls__list__article-button__icon'
              src={styles[0]?.TextIDsRepository.assets.microscopeIcon}
              alt=''
            />
          )}

          <AnimatePresence>
            {!isOpenPanel &&
              currentTooltip.isShow &&
              currentTooltip.text ===
                styles[0]?.TextIDsRepository.analysisTitle && (
                <Tooltip
                  text={styles[0]?.TextIDsRepository.analysisTitle}
                  classNames='top-container__tooltip-navbar'
                />
              )}
          </AnimatePresence>
        </motion.button>

        <li className='controls__list'>
          {isOpenPanel ? (
            <motion.button
              className={clsx('controls__list__article-button', {
                'active-list-item': activeCatalogItem === 0,
              })}
              onClick={() => {
                handleClickCatalogItem(0, 'catalog')

                if (isMobile) {
                  setIsOpenPanel(false)
                }

                if (location.pathname !== RoutesEnum.Main) {
                  navigate(RoutesEnum.Main, {
                    state: {
                      activeCatalogItem: 0,
                    },
                  })
                }
              }}
              whileHover={{ scale: 1.02 }}
            >
              {styles[0]?.TextIDsRepository.sidebarMenuListItemCatalog}
            </motion.button>
          ) : null}

          <ul>
            {catalogList.map((item, index) => (
              <motion.div
                key={index + 1}
                className={clsx('controls__list__list-item', {
                  'active-list-item': activeCatalogItem === index + 1,
                })}
                tabIndex={0}
                role='button'
                onMouseEnter={() => handleOnMouseEnter(item.title)}
                onMouseLeave={handleOnMouseLeave}
                onClick={() => {
                  handleClickCatalogItem(index + 1, item.title)

                  if (isMobile) {
                    setIsOpenPanel(false)
                  }

                  if (location.pathname !== RoutesEnum.Main) {
                    setIsOpenPanel(false)

                    navigate(RoutesEnum.Main, {
                      state: {
                        activeCatalogItem: index + 1,
                      },
                    })
                  }
                }}
                whileHover={{ scale: 1.02 }}
              >
                {isOpenPanel ? item.title : <img src={item.icon} alt='' />}

                <AnimatePresence>
                  {currentTooltip.isShow &&
                    currentTooltip.text === item.title &&
                    !isOpenPanel && (
                      <Tooltip
                        text={currentTooltip.text}
                        classNames='top-container__tooltip-navbar'
                      />
                    )}
                </AnimatePresence>
              </motion.div>
            ))}
          </ul>
        </li>
      </ul>

      <BottomContainer isOpenPanel={isOpenPanel} />
    </motion.nav>
  )
}

export default NavigationPanel
