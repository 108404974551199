import React from 'react'
import clsx from 'clsx'

import './styles.scss'

type ErrorMessageProps = {
  message: string | React.ReactNode | undefined
  classNames?: string
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, classNames }) => {
  return <div className={clsx('error-message', classNames)}>{message}</div>
}

export default ErrorMessage
