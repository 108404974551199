import numerous from 'numerous'

export const pluralizeWords = async (
  localeId: string,
  count: number,
  variants: { [key: string]: string }
) => {
  const locale = await import(`numerous/locales/${localeId}`)

  numerous.registerLocale(locale)

  const { pluralize } = numerous.create(localeId)

  return `${count} ${pluralize(count, variants)}`
}
