import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Plus, Search } from 'react-feather'
import { useLocation, useNavigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import clsx from 'clsx'
import { AxiosError } from 'axios'

import Button from '../../UI/Button'
import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import { RoutesEnum } from '../../../enums/routes'
import { ModalWrapper } from '../../ModalWrapper'
import { useMatchMedia } from '../../../hooks/useMatchMedia'
import {
  getCustomerList,
  getTagsList,
  NutritionistClient,
  NutritionistClientTag,
} from '../../../utils/axiosManager'
import Spinner from '../../Spinner'
import ClientCard from './ClientCard'
import { updateProfileCustomerField } from '../../../redux/slices/profileSlice'
import { setCurrentClientData } from '../../../redux/slices/personalCabinetSlice'
import { useOutsideClick } from '../../../hooks/useOutsideClick'
import TagsListCard from '../TagsListCard'
import { setErrorState } from '../../../redux/slices/errorsSlice'
import { deleteClient } from '../../../models/specialist/api'
import { ApiStatuses } from '../../../enums/apiRoutes'
import { setEditableClient } from '../../../redux/slices/specialistsSlice'

import './styles.scss'

enum SortingFilterValues {
  NameUp = 'name-up',
  NameDown = 'name-down',
  CityUp = 'price-up',
  CityDown = 'price-down',
  AgeUp = 'age-up',
  AgeDown = 'age-down',
  Default = '',
}

const ProfileClients = () => {
  const [deleteUserModal, setDeleteUserModal] = useState(false)
  const [selectedClient, setSelectedClient] =
    useState<NutritionistClient | null>(null)
  const [isLoadingClients, setIsLoadingClients] = useState(true)
  const [showClientTags, setShowClientTags] = useState(false)
  const [selectedClientTags, setSelectedClientTags] = useState<
    NutritionistClientTag['TagID'][]
  >([])
  const [sortingFilter, setSortingFilter] = useState<SortingFilterValues>(
    SortingFilterValues.Default
  )
  const [searchValue, setSearchValue] = useState('')
  const clientsTagRef = useRef(null)

  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const profileData = useAppSelector((state) => state.profileSlice)

  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const location = useLocation()
  const { isMobile } = useMatchMedia()

  useOutsideClick(clientsTagRef, setShowClientTags)

  const sortingMethod = (filter: SortingFilterValues): NutritionistClient[] => {
    switch (filter) {
      case SortingFilterValues.NameUp: {
        const sortedClients = [...profileData.clients].sort(
          (clientA, clientB) =>
            clientA.Name.toLowerCase().localeCompare(clientB.Name.toLowerCase())
        )

        return sortedClients
      }
      case SortingFilterValues.CityUp: {
        const sortedClients = [...profileData.clients].sort(
          (clientA, clientB) =>
            clientA.CustomerCity.toLowerCase().localeCompare(
              clientB.CustomerCity.toLowerCase()
            )
        )

        return sortedClients
      }
      case SortingFilterValues.AgeUp: {
        const sortedClients = [...profileData.clients].sort(
          (clientA, clientB) => clientA.Age - clientB.Age
        )

        // dispatch(updateProfileCustomerField({ clients: sortedClients }))
        return sortedClients
      }
      case SortingFilterValues.NameDown: {
        const sortedClients = [...profileData.clients].sort(
          (clientA, clientB) =>
            clientA.Name.toLowerCase().localeCompare(clientB.Name.toLowerCase())
        )

        return sortedClients.reverse()
      }
      case SortingFilterValues.CityDown: {
        const sortedClients = [...profileData.clients].sort(
          (clientA, clientB) =>
            clientA.CustomerCity.toLowerCase().localeCompare(
              clientB.CustomerCity.toLowerCase()
            )
        )

        return sortedClients.reverse()
      }
      case SortingFilterValues.AgeDown: {
        const sortedClients = [...profileData.clients].sort(
          (clientA, clientB) => clientA.Age - clientB.Age
        )

        return sortedClients.reverse()
      }
      default:
        return profileData.clients
    }
  }

  const clientCards = useMemo(() => {
    if (profileData.clients.length === 0) {
      return
    }

    const sortingClientsCard = sortingMethod(sortingFilter)

    if (selectedClientTags.length > 0) {
      return sortingClientsCard.filter((client) => {
        return client.Tags?.some((tag) =>
          selectedClientTags.includes(tag.TagID)
        )
      })
    }

    if (searchValue.length > 0) {
      return sortingClientsCard.filter((client) => {
        if (
          client.Name.toLowerCase().includes(searchValue.toLowerCase()) ||
          client.CustomerCity.toLowerCase().includes(
            searchValue.toLowerCase()
          ) ||
          client.Phone.toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        ) {
          return client
        }
      })
    }

    return sortingClientsCard
  }, [selectedClientTags, profileData.clients, sortingFilter, searchValue])

  useEffect(() => {
    getCustomerList(profileData.customerID)
      .then((response) => {
        dispatch(setCurrentClientData({ data: response[0] }))
        dispatch(updateProfileCustomerField({ clients: response }))
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => setIsLoadingClients(false))

    getTagsList(profileData.customerID).then((response) => {
      if (response instanceof AxiosError) {
        dispatch(setErrorState(true))
        return
      }

      dispatch(
        updateProfileCustomerField({
          tags: response,
        })
      )
    })
  }, [])

  const handleDeleteUserModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    client: NutritionistClient
  ) => {
    event.stopPropagation()

    setDeleteUserModal(true)
    setSelectedClient(client)
  }

  const handleDeleteUser = () => {
    if (selectedClient) {
      deleteClient(profileData.customerID, {
        clientId: selectedClient?.CustomerID,
      }).then((response) => {
        if (response === ApiStatuses.NotFound) {
          return
        }

        const updatedClients = profileData.clients.filter(
          (client) => client.CustomerID !== selectedClient.CustomerID
        )

        dispatch(updateProfileCustomerField({ clients: updatedClients }))
        setSelectedClient(null)
        setDeleteUserModal(false)
      })
    }
  }

  const handleSortingFilter = (
    up: keyof typeof SortingFilterValues,
    down: keyof typeof SortingFilterValues
  ) => {
    setSortingFilter((prevState) => {
      if (prevState === SortingFilterValues[up]) {
        return SortingFilterValues[down]
      }

      if (prevState === SortingFilterValues[down]) {
        return SortingFilterValues.Default
      }

      return SortingFilterValues[up]
    })
  }

  const handleCreateClient = () => {
    navigate(RoutesEnum.PersonalCabinet + RoutesEnum.Auth, {
      ...location,
      state: null,
    })
    dispatch(setEditableClient(null))
  }

  const renderClientsCards = () => {
    if (isLoadingClients) {
      return <Spinner classNames='clients-container__body__spinner' />
    }

    return (
      <>
        <div className='clients-container__body__header'>
          <div className='clients-container__body__header__column'>
            <span>{styles[0]?.TextIDsRepository.clientsTableHeaderFIO}</span>
            <button
              className={clsx('list-container__sort-button', {
                'list-container__sort-button-active':
                  sortingFilter === SortingFilterValues.NameUp,
                'list-container__sort-button-active-reverse':
                  sortingFilter === SortingFilterValues.NameDown,
              })}
              onClick={() => handleSortingFilter('NameUp', 'NameDown')}
            >
              <img
                src={styles[0]?.TextIDsRepository.assets.tableIcon}
                className='clients-container__body__header__column__img'
                alt=''
              />
            </button>
          </div>

          {!isMobile && (
            <div className='clients-container__body__header__column'>
              <span>{styles[0]?.TextIDsRepository.clientsTableHeaderTags}</span>
              <button
                className='clients-container__body__header__column__tag-filter'
                onClick={() => setShowClientTags((prevState) => !prevState)}
              />

              {showClientTags && (
                <TagsListCard
                  ref={clientsTagRef}
                  clientTags={profileData.tags}
                  selectedClientTags={selectedClientTags}
                  setSelectedClientTags={setSelectedClientTags}
                />
              )}

              <img
                src={styles[0]?.TextIDsRepository.assets.tableIcon}
                className='clients-container__body__header__column__img'
                alt=''
              />
            </div>
          )}
          <div className='clients-container__body__header__column'>
            <span>{styles[0]?.TextIDsRepository.clientsTableHeaderPlan}</span>
            <img
              src={styles[0]?.TextIDsRepository.assets.tableIcon}
              className='clients-container__body__header__column__img'
              alt=''
            />
          </div>

          <div className='clients-container__body__header__city'>
            <span>{styles[0]?.TextIDsRepository.clientsTableHeaderCity}</span>
            {!isMobile && (
              <button
                className={clsx('list-container__sort-button', {
                  'list-container__sort-button-active':
                    sortingFilter === SortingFilterValues.CityUp,
                  'list-container__sort-button-active-reverse':
                    sortingFilter === SortingFilterValues.CityDown,
                })}
                onClick={() => handleSortingFilter('CityUp', 'CityDown')}
              >
                <img
                  src={styles[0]?.TextIDsRepository.assets.tableIcon}
                  className='clients-container__body__header__city__img'
                  alt=''
                />
              </button>
            )}
          </div>

          {!isMobile && (
            <>
              <div className='clients-container__body__header__column'>
                {styles[0]?.TextIDsRepository.clientsTableHeaderPhone}
              </div>
              <div className='clients-container__body__header__column'>
                <span>
                  {styles[0]?.TextIDsRepository.clientsTableHeaderAge}
                </span>
                <button
                  className={clsx('list-container__sort-button', {
                    'list-container__sort-button-active':
                      sortingFilter === SortingFilterValues.AgeUp,
                    'list-container__sort-button-active-reverse':
                      sortingFilter === SortingFilterValues.AgeDown,
                  })}
                  onClick={() => handleSortingFilter('AgeUp', 'AgeDown')}
                >
                  <img
                    className='clients-container__body__header__column__img'
                    src={styles[0]?.TextIDsRepository.assets.tableIcon}
                    alt=''
                  />
                </button>
              </div>
              <div />
              <div />
            </>
          )}
        </div>

        {clientCards && clientCards.length > 0 ? (
          clientCards.map((client) => {
            return (
              <ClientCard
                key={client.CustomerID}
                client={client}
                handleDeleteUserModal={handleDeleteUserModal}
              />
            )
          })
        ) : (
          <div className='clients-container__body__header__column__empty-list'>
            {styles[0].TextIDsRepository.clientsEmptyList}
          </div>
        )}
      </>
    )
  }

  return (
    <div className='clients-container'>
      <div className='clients-container__header'>
        <Button
          title={
            isMobile ? <Plus /> : styles[0]?.TextIDsRepository.createButtonTitle
          }
          click={handleCreateClient}
          classNames='clients-container__header__button'
        />

        <label className='clients-container__header__label'>
          <input
            className='clients-container__header__search'
            placeholder={styles[0]?.TextIDsRepository.clientsSearchPlaceholder}
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
          />

          <Search />
        </label>
      </div>

      <div className='clients-container__body'>{renderClientsCards()}</div>

      <AnimatePresence>
        {deleteUserModal && (
          <ModalWrapper
            setIsOpen={setDeleteUserModal}
            contentClassNames='clients-container__modal'
          >
            <div className='clients-container__modal__body'>
              <span className='clients-container__modal__title'>
                {styles[0]?.TextIDsRepository.clientsDeleteModalTitle}
              </span>
              <span className='clients-container__modal__text'>
                {`${selectedClient?.Surname} ${selectedClient?.Name} ${selectedClient?.Patronymic}`}
              </span>
              <div className='clients-container__modal__buttons'>
                <Button
                  title={styles[0]?.TextIDsRepository.cancelButtonTitle}
                  click={() => setDeleteUserModal(false)}
                  classNames='clients-container__modal__cancel'
                />
                <Button
                  title={styles[0]?.TextIDsRepository.deleteButtonTitle}
                  click={handleDeleteUser}
                />
              </div>
            </div>
          </ModalWrapper>
        )}
      </AnimatePresence>
    </div>
  )
}

export default ProfileClients
