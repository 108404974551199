import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type ShippingPaymentState = {
  deliverySum: number
  deliverySumToAddress: number
}

const initialState: ShippingPaymentState = {
  deliverySum: 0,
  deliverySumToAddress: 0,
}

const shippingPaymentSlice = createSlice({
  name: 'shippingPayment',
  initialState,
  reducers: {
    changeDeliverySum(state, action: PayloadAction<{ deliverySum: number }>) {
      state.deliverySum = action.payload.deliverySum
    },
    changeDeliverySumToAddress(
      state,
      action: PayloadAction<{ deliverySumToAddress: number }>
    ) {
      state.deliverySumToAddress = action.payload.deliverySumToAddress
    },
    resetShippingPayment() {
      return {
        ...initialState,
      }
    },
  },
})

export const {
  changeDeliverySum,
  changeDeliverySumToAddress,
  resetShippingPayment,
} = shippingPaymentSlice.actions

export default shippingPaymentSlice.reducer
