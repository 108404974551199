export enum CustomerEventActions {
  Add = 'add',
  Edit = 'edit',
  Delete = 'delete',
}

export enum CustomerEventResults {
  Success = 'success',
  Error = 'error',
}

export enum EventMethodsName {
  CustomersComment = 'CustomersComment',
  CustomersConsultations = 'CustomersConsultations',
}

export enum EventType {
  Survey = 'survey',
  Comment = 'comment',
  Consultation = 'consultation',
  File = 'file',
  SupplementCourse = 'course',
}
