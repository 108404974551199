import React, { useEffect, useRef, useState } from 'react'
import { ChevronDown, Download, Paperclip, Trash2 } from 'react-feather'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'

import {
  EventFile,
  EventFiles,
  deleteEvent,
  updateDocEvent,
  updateEvent,
} from '../../../redux/slices/clientEventSlice'
import {
  customerUpdateFile,
  customerUpdateSurvey,
  deleteCustomersFiles,
  deleteFileEvent,
  renameCustomersFiles,
  RenameCustomersFilesRequest,
} from '../../../utils/axiosManager'
import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import Spinner from '../../Spinner'
import EventCardBody from '../EventCardBody'
import { ApiRoutes, ApiStatuses } from '../../../enums/apiRoutes'
import {
  CustomerEventActions,
  EventType,
} from '../../../enums/customerEventActions'
import { formatDate } from '../../../utils/formatDate'
import { smoothTransition } from '../../../utils/animations'

import './styles.scss'

type EventFileCardProps = {
  date: string
  // files: EventFile[]
  files: EventFiles
}

const EventFileCard: React.FC<EventFileCardProps> = ({ date, files }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [header, setHeader] = useState(files.header)
  const [editable, setEditable] = useState({ fileId: '', fileName: '' })
  const [isLoadingEditable, setIsLoadingEditable] = useState({
    fileId: '',
    value: false,
  })
  const [currentFileExtension, setCurrentFileExtension] = useState('')

  const profileData = useAppSelector((state) => state.profileSlice)
  const currentClient = useAppSelector(
    (state) => state.personalCabinet.currentClient
  )

  const firstRender = useRef(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!firstRender.current) {
      firstRender.current = true
      return
    }

    const changeEvent = setTimeout(() => renameFileEventHeader(), 1000)

    return () => clearTimeout(changeEvent)
  }, [header])

  // const handleDeleteFileFromEvent = (fileId: string) => {
  //   setIsLoadingEditable({ fileId, value: true })

  //   deleteCustomersFiles({ FileID: fileId }).then((response) => {
  //     if ('EventID' in response.EditCustomersFiles) {
  //       dispatch(
  //         updateDocEvent({
  //           EventId: response.EditCustomersFiles.EventID,
  //           Files: response.EditCustomersFiles.Files,
  //         })
  //       )

  //       setIsLoadingEditable({ fileId: '', value: false })
  //     }
  //   })
  // }

  // const handleRenameFileFromEvent = (fileId: string, fileName: string) => {
  //   const fullFileName = editable.fileName + currentFileExtension

  //   const request: RenameCustomersFilesRequest = {
  //     FileID: editable.fileId,
  //     FileName: fullFileName,
  //   }

  //   if (fullFileName !== fileName) {
  //     setIsLoadingEditable({ fileId, value: true })

  //     renameCustomersFiles(request).then((response) => {
  //       if ('EventID' in response.EditCustomersFiles) {
  //         dispatch(
  //           updateDocEvent({
  //             EventId: response.EditCustomersFiles.EventID,
  //             Files: response.EditCustomersFiles.Files,
  //           })
  //         )
  //       }

  //       setIsLoadingEditable({ fileId: '', value: false })
  //     })
  //   }

  //   setEditable({ fileId: '', fileName: '' })
  //   setCurrentFileExtension('')
  // }
  const handleDeleteFileEvent = () => {
    setIsLoading(true)

    deleteFileEvent(profileData.customerID, { eventId: files.eventId })
      .then((response) => {
        if (response.status === ApiStatuses.Ok) {
          dispatch(
            deleteEvent({
              eventType: EventType.File,
              id: files.eventId,
            })
          )
        }
      })
      .finally(() => setIsLoading(false))
  }

  const renameFileEventHeader = () => {
    setIsLoading(true)

    customerUpdateFile(
      currentClient.data
        ? currentClient.data.CustomerID
        : profileData.customerID,
      {
        eventId: files.eventId,
        header,
      }
    )
      .then((response) => {
        if (response.eventId) {
          dispatch(
            updateEvent({
              eventType: EventType.File,
              event: {
                ...files,
                header,
              },
            })
          )
        }
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <motion.div className='event-card-container' layout {...smoothTransition}>
      <div
        className='event-card-container__header'
        role='button'
        tabIndex={0}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <button
          className={clsx('event-card-container__button', {
            'event-card-container__button-active': isOpen,
          })}
        >
          <ChevronDown />
        </button>

        <div className='event-card-container__icon'>
          <Paperclip />
        </div>

        <div className='event-card-container__date'>
          {formatDate(date.slice(0, 10))}
        </div>

        <input
          className='event-card-container__input'
          // value={`Загружено ${files.length} шт.`}
          value={header}
          onChange={(event) => setHeader(event.target.value)}
          onClick={(event) => event.stopPropagation()}
        />

        <AnimatePresence>
          {isLoading ? (
            <motion.div
              className='event-card-container__delete-button'
              {...smoothTransition}
            >
              <Spinner classNames='event-card-container__spinner' />
            </motion.div>
          ) : (
            <button
              className='event-card-container__delete-button'
              onClick={(event) => {
                event.stopPropagation()
                handleDeleteFileEvent()
              }}
            >
              <Trash2 />
            </button>
          )}
        </AnimatePresence>
      </div>

      <EventCardBody isOpen={isOpen}>
        {/* {files.map((file) => {
          return  */}
        {
          // isLoadingEditable.fileId === file.FileID && isLoadingEditable.value ? (
          //   <Spinner
          //     key={file.FileID}
          //     classNamesSpinner='event-file__body-item__spinner'
          //   />
          // ) : (
          <div className='event-file__body-item'>
            {/* {editable.fileId.length > 0 && files.eventId === editable.fileId ? (
              <input
                className={clsx(
                  'event-card-container__input',
                  'tags-container__new-tag__tag-item__input'
                )}
                value={editable.fileName}
                onBlur={() =>
                  handleRenameFileFromEvent(file.FileID, file.FileName)
                }
                onChange={(event) => {
                  setEditable({ ...editable, fileName: event.target.value })
                }}
                autoFocus
              />
            ) : (
              <button
                className='event-file__body-item__name'
                onClick={() => {
                  const dividedFileName = file.FileName.split('.')

                  setEditable({
                    fileId: file.FileID,
                    fileName: dividedFileName[0],
                  })

                  setCurrentFileExtension(
                    `.${dividedFileName[dividedFileName.length - 1]}`
                  )
                }}
              >
                {file.FileName}
              </button>
            )}

            {editable.fileId.length > 0 &&
              file.FileID === editable.fileId &&
              currentFileExtension.length !== 0 && (
                <span className='event-file__body-item__file-extension'>
                  {currentFileExtension}
                </span>
              )} */}

            <button className='event-file__body-item__name'>
              {files.fileName}
            </button>

            <div className='event-file__body-item__button-group'>
              <a
                className='event-file__body-item__button-group__download'
                href={files.pathLink}
                download
              >
                <Download />
              </a>

              <button
              //  onClick={() => handleDeleteFileFromEvent(file.FileID)}
              >
                <Trash2 />
              </button>
            </div>
          </div>
          // )
        }
        {/* })} */}
      </EventCardBody>
    </motion.div>
  )
}

export default EventFileCard
