import React from 'react'
import { AnimatePresence } from 'framer-motion'

import { ModalWrapper } from '../../ModalWrapper'
import { useAppSelector } from '../../../redux/hook'
import { AnalysisEntity } from '../../../redux/slices/analysisSlice'

import './styles.scss'

type ModalAnalysisInfoProps = {
  isOpenModal: boolean
  setIsOpenModal: (value: boolean) => void
  analysis: AnalysisEntity
  containerClassName?: string
  fullScreen?: boolean
}

const ModalAnalysisInfo: React.FC<ModalAnalysisInfoProps> = ({
  isOpenModal,
  setIsOpenModal,
  analysis,
  containerClassName,
  fullScreen = false,
}) => {
  const styles = useAppSelector((state) => state.partnerInterface.styles)

  return (
    <AnimatePresence>
      {isOpenModal && (
        <ModalWrapper
          setIsOpen={setIsOpenModal}
          containerClassNames={containerClassName}
          contentClassNames='analysis-modal-info'
          fullScreen={fullScreen}
        >
          <div className='analysis-modal-info__header'>
            <span className='analysis-modal-info__header__title'>
              {analysis.nameService}
            </span>

            <div className='analysis-modal-info__header__short-info'>
              <div className='analysis-modal-info__header__short-info__wrapper'>
                <span className='analysis-modal-info__header__price'>
                  {`${analysis.price.toLocaleString()} ${
                    styles[0]?.TextIDsRepository.currency
                  }`}
                </span>

                {analysis.expertCashback && (
                  <span className='analysis-modal-info__header__reserved-price'>
                    {`${analysis.expertCashback} ${styles[0]?.TextIDsRepository.currency}`}
                  </span>
                )}
              </div>

              <div className='analysis-modal-info__header__short-info__wrapper'>
                <span className='analysis-modal-info__header__type-text'>
                  <img
                    src={styles[0]?.TextIDsRepository.assets.analysisIcon}
                    alt=''
                  />
                  {analysis.nameBiomaterial}
                </span>
              </div>
            </div>
          </div>

          {analysis.description && (
            <div className='analysis-modal-info__body'>
              <span className='analysis-modal-info__body__description'>
                {analysis.description}
              </span>
            </div>
          )}
        </ModalWrapper>
      )}
    </AnimatePresence>
  )
}

export default ModalAnalysisInfo
