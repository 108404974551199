import React, { useEffect } from 'react'
import { LayoutGroup } from 'framer-motion'

import PortalTooltip from '../../UI/PortalTooltip'
import FilterControls from '../../shared/FilterControls'
import ProfileData from '../ProfileData'
import ProfileClients from '../ProfileClients'
import ProfileAccruals from '../ProfileAcrruals'
import ProfilePayments from '../ProfilePayments'
import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import BankRequisites from '../../BankRequisites'
import { updateProfileCustomerField } from '../../../redux/slices/profileSlice'
import { setCurrentClient } from '../../../redux/slices/personalCabinetSlice'

type HeaderProps = {
  currentFilter: { type: string }
  setCurrentFilter: (value: { type: string }) => void
  filterControlsButtonsList: { currentFilterType: string; title: string }[]
}

const PersonalCabinetContainer: React.FC<HeaderProps> = ({
  currentFilter,
  setCurrentFilter,
  filterControlsButtonsList,
}) => {
  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const profileData = useAppSelector((state) => state.profileSlice)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (currentFilter.type !== 'clients') {
      dispatch(
        setCurrentClient({
          data: null,
          events: null,
        })
      )
    }
  }, [currentFilter])

  return (
    <div className='personal-cabinet-container'>
      <div className='personal-cabinet-container__header'>
        <span className='personal-cabinet-container__header__title'>
          {styles[0]?.TextIDsRepository.personalAccountTitle}
        </span>

        {profileData.nutritionist && (
          <div className='personal-cabinet-container__header__balance'>
            <span className='personal-cabinet-container__header__balance-title'>
              {`${styles[0]?.TextIDsRepository.personalAccountBalance}: `}

              <span className='personal-cabinet-container__header__balance-value'>
                3000 {styles[0]?.TextIDsRepository.currency}
              </span>
            </span>

            <PortalTooltip
              text={
                styles[0]?.TextIDsRepository.personalAccountMinPaymentTooltip
              }
              classNames='personal-cabinet-container__header__tooltip'
            >
              <img src={styles[0]?.TextIDsRepository.assets.balance} alt='' />
            </PortalTooltip>
          </div>
        )}
      </div>

      {profileData.nutritionist && (
        <div className='personal-cabinet-container__controls-container'>
          <LayoutGroup id='personalCabinet'>
            <FilterControls
              classNames='personal-cabinet-container__controls-container__body'
              buttonsList={filterControlsButtonsList}
              state={currentFilter}
              changeState={setCurrentFilter}
            />
          </LayoutGroup>
        </div>
      )}

      <div className='personal-cabinet-container__body'>
        {currentFilter.type === 'data' && <ProfileData />}

        {currentFilter.type === 'data' && profileData.nutritionist && (
          <BankRequisites />
        )}

        {currentFilter.type === 'clients' && <ProfileClients />}

        {currentFilter.type === 'earnings' && <ProfileAccruals />}

        {currentFilter.type === 'payments' && <ProfilePayments />}
      </div>
    </div>
  )
}

export default PersonalCabinetContainer
