import React, { useRef, useState } from 'react'
import { Edit3, PlusCircle, Trash2 } from 'react-feather'
import clsx from 'clsx'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import { useMatchMedia } from '../../../hooks/useMatchMedia'
import {
  NutritionistClient,
  NutritionistClientTag,
} from '../../../utils/axiosManager'
import { useOutsideClick } from '../../../hooks/useOutsideClick'
import TagsListCard from '../TagsListCard'
import { setCurrentClientData } from '../../../redux/slices/personalCabinetSlice'
import { formatDate } from '../../../utils/formatDate'
import { RoutesEnum } from '../../../enums/routes'
import { setEditableClient } from '../../../redux/slices/specialistsSlice'

type ClientCardProps = {
  client: NutritionistClient
  handleDeleteUserModal: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    client: NutritionistClient
  ) => void
}

const ClientCard: React.FC<ClientCardProps> = ({
  client,
  handleDeleteUserModal,
}) => {
  const [showClientTags, setShowClientTags] = useState(false)
  const clientsTagRef = useRef(null)
  useOutsideClick(clientsTagRef, setShowClientTags)

  const styles = useAppSelector((state) => state.partnerInterface.styles)

  const { isMobile } = useMatchMedia()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const handleCreateTag = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation()

    setShowClientTags(true)
  }

  const handleEditableClient = () => {
    navigate(RoutesEnum.PersonalCabinet + RoutesEnum.Register, {
      ...location,
      state: {
        editableClient: true,
      },
    })
    dispatch(setEditableClient(client))
  }

  return (
    <div
      key={client.CustomerID}
      className='clients-container__body__card'
      role='button'
      tabIndex={0}
      onClick={() => dispatch(setCurrentClientData({ data: client }))}
    >
      <div className='clients-container__body__card__column'>
        <span>{client.Name}</span>
        <span>
          {client.Surname} {client.Patronymic}
        </span>

        {isMobile && (
          <div className='clients-container__body__card__age'>
            <span className='clients-container__grey-text'>
              {`${styles[0]?.TextIDsRepository.clientsTableHeaderAge}: ${client.Age}`}
            </span>
            <span className='clients-container__grey-text'>
              {`+${client.Phone}`}
            </span>
          </div>
        )}
      </div>
      {!isMobile && (
        <div className='clients-container__body__card__tags'>
          <button
            className={clsx({
              'clients-container__body__card__add-tag-button':
                !client.Tags?.length,
            })}
            onClick={handleCreateTag}
          >
            {client.Tags?.length ? (
              client.Tags.map((tag: NutritionistClientTag) => (
                <p key={tag.TagID}>{tag.TagName}</p>
              ))
            ) : (
              <PlusCircle />
            )}
          </button>

          {showClientTags && (
            <TagsListCard
              ref={clientsTagRef}
              clientTags={client.Tags}
              clientId={client.CustomerID}
            />
          )}
        </div>
      )}
      <div
        className={clsx(
          'clients-container__body__header__date',
          'clients-container__body__card__column',
          {
            'clients-container__body__card__no-date':
              client.PlannedDate === null,
          }
        )}
      >
        {client.PlannedDate === null
          ? styles[0]?.TextIDsRepository.clientsTableNoDate
          : formatDate(client.PlannedDate.slice(0, -9))}
      </div>
      <div
        className={clsx(
          'clients-container__body__header__city',
          'clients-container__body__card__column'
        )}
      >
        <span className='clients-container__grey-text'>
          {client.CustomerCity}
        </span>
        {isMobile && (
          <div className='clients-container__body__header__controls'>
            <button
              className='clients-container__body__card__edit'
              onClick={handleEditableClient}
            >
              <Edit3 />
            </button>
            <button onClick={(event) => handleDeleteUserModal(event, client)}>
              <Trash2 />
            </button>
          </div>
        )}
      </div>

      {!isMobile && (
        <>
          <div className='clients-container__body__card__column'>{`+${client.Phone}`}</div>
          <div className='clients-container__body__card__column'>
            {client.Age}
          </div>
          <button
            className='clients-container__body__card__edit'
            onClick={handleEditableClient}
          >
            <Edit3 />
          </button>
          <button
            onClick={(event) => handleDeleteUserModal(event, client)}
            className='clients-container__body__card__trash'
          >
            <Trash2 />
          </button>
        </>
      )}
    </div>
  )
}

export default ClientCard
