import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import { SurveyAnswerType } from './surveySlice'
import { EventType } from '../../enums/customerEventActions'

export type EventConsultation = {
  eventId: number
  eventType: EventType.Consultation
  date: string
  complaints: string | null
  diseases: string | null
  allergy: string | null
  information: string | null
  diagnosis: string | null
  recommendations: string | null
  dietHistory: string | null
  header: string
}

export type EventComment = {
  eventId: number
  eventType: EventType.Comment
  date: string
  header: string
  body: string
}

export type EventCourse = {
  eventId: number
  date: string
  courseDuration: number
  orderNumber: string
  deliveryStatusForwarder: string
  orderProductionStatus: string
  eventType: EventType.SupplementCourse
  courseId: 2
  supplementCourseItems: {
    article: string
    supplementQuantity: number
    supplementPrice: number
    receptionTime: string
    receptionDay: string
  }[]
}

export type EventFile = {
  FileID: string
  FileName: string
  FileLink: string
}

export type EventFiles = {
  eventId: number
  eventType: EventType.File
  date: string
  header: string
  fileName: string
  pathLink: string
}

export type EventAnalysis = {
  EventID: string
  EventType: string
  Date: string
  Header: string
  Body: EventFile[]
  AnalyzesResults: {
    Parameter: string
    Result: string
    Reference: string
  }[]
}

export type EventSurveys = {
  eventId: number
  eventType: EventType.Survey
  date: string
  header: string
  answers: {
    question: string
    answer: number | boolean | string
  }[]
}

export type ClientEvents =
  | EventConsultation
  | EventComment
  | EventCourse
  | EventFiles
  | EventSurveys

export type ClientEventState = {
  events: ClientEvents[]
}

export type CustomerEventsResponse = {
  GetCustomerEvents: {
    Consultations: EventConsultation[]
    Comments: EventComment[]
    SupplementCourses: EventCourse[]
    Files: EventFiles[]
    Surveys: EventSurveys[]
  }
}

const initialState: ClientEventState = {
  events: [],
}

const clientEventSlice = createSlice({
  name: 'clientEvent',
  initialState,
  reducers: {
    addAllEvents(state, action: PayloadAction<ClientEventState['events']>) {
      state.events = action.payload
    },
    addEvent(state, action: PayloadAction<ClientEvents>) {
      state.events.unshift(action.payload)
    },
    updateDocEvent(
      state,
      action: PayloadAction<{ EventId: number; Files: EventFile[] }>
    ) {
      if (action.payload.Files.length === 0) {
        state.events = state.events.filter(
          (event) =>
            (event.eventType as EventType) === EventType.File &&
            event.eventId !== action.payload.EventId
        )
        return
      }

      state.events = state.events.map((event) =>
        (event.eventType as EventType) === EventType.File &&
        event.eventId === action.payload.EventId
          ? { ...event, Body: action.payload.Files }
          : event
      )
    },
    updateEvent(
      state,
      action: PayloadAction<{
        eventType: ClientEvents['eventType']
        event: ClientEvents
      }>
    ) {
      state.events = state.events.map((event) =>
        event.eventType === action.payload.eventType &&
        event.eventId === action.payload.event.eventId
          ? action.payload.event
          : event
      )
    },
    deleteEvent(
      state,
      action: PayloadAction<{
        eventType: ClientEvents['eventType']
        id: number
      }>
    ) {
      state.events = state.events.filter(
        (event) => event.eventId !== action.payload.id
      )
    },
  },
})

export const {
  addAllEvents,
  addEvent,
  updateDocEvent,
  updateEvent,
  deleteEvent,
} = clientEventSlice.actions

export default clientEventSlice.reducer
