import React, { useEffect, useRef, useState } from 'react'
import {
  ChevronLeft,
  ChevronRight,
  FileText,
  HelpCircle,
  Paperclip,
  User,
  X,
} from 'react-feather'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion'
import { AxiosError } from 'axios'
import { useCookies } from 'react-cookie'

import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import FilterControls from '../../shared/FilterControls'
import { getSupplementAsync } from '../../../redux/slices/supplementFactsSlice'
import FileUpload from '../../FileUpload'
import EventCommentCard from '../EventCommentCard/EventCommentCard'
import EventFileCard from '../EventFileCard/EventFileCard'
import EventConsultationCard from '../EventConsultationCard/EventConsultationCard'
import {
  EventConsultation,
  addAllEvents,
  addEvent,
} from '../../../redux/slices/clientEventSlice'
import { ClientsCurrentFilterEnum } from '../../../enums/infoSideBarFilters'
import EventAccrualCard from '../EventAcrrualCard/EventAccrualCard'
import { useMatchMedia } from '../../../hooks/useMatchMedia'
import {
  customerCreateComment,
  customerCreateConsultation,
  EventConsultationRequest,
  getCustomerEvents,
  uploadCustomersFiles,
  UploadFilesRequest,
} from '../../../utils/axiosManager'
import {
  CustomerEventActions,
  CustomerEventResults,
  EventType,
} from '../../../enums/customerEventActions'
import { clearUploadFileList } from '../../../redux/slices/uploadFilesSlice'
import {
  setCurrentClient,
  setCurrentClientEvents,
} from '../../../redux/slices/personalCabinetSlice'
import { CurrentFilter } from '../PersonalCabinet'
import EventSurveyCard from '../EventSurveyCard/EventSurveyCard'
import EventCourseCard from '../EventCourseCard/EventCourseCard'
import PortalTooltip from '../../UI/PortalTooltip'
import { RoutesEnum } from '../../../enums/routes'
import { setErrorState } from '../../../redux/slices/errorsSlice'
import EventSupplementCard from '../EventSupplementCard/EventSupplementCard'
import { CookieEnum } from '../../../enums/cookie'

import './styles.scss'

type ClientsCurrentFilter = { type: ClientsCurrentFilterEnum | string }

type InfoSideBarProps = {
  currentFilter?: Omit<CurrentFilter, string>
  profileDataFilter?: string
}

const InfoSideBar: React.FC<InfoSideBarProps> = ({
  currentFilter,
  profileDataFilter,
}) => {
  const [clientsCurrentFilter, setClientsCurrentFilter] =
    useState<ClientsCurrentFilter>({
      type: ClientsCurrentFilterEnum.Chronology,
    })
  const [isShowPanel, setIsShowPanel] = useState(true)
  const [isShowLoadFileScreen, setIsShowLoadFileScreen] = useState(false)
  const [dragHandlerCounter, setDragHandlerCounter] = useState(0)
  const commentInputRef = useRef<HTMLInputElement>(null)
  const profileData = useAppSelector((state) => state.profileSlice)
  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const clientEvents = useAppSelector((state) => state.clientEvent.events)
  const uploadFiles = useAppSelector((state) => state.uploadFiles.fileList)
  const currentClient = useAppSelector(
    (state) => state.personalCabinet.currentClient
  )
  const currentSpecialist = useAppSelector(
    (state) => state.specialists.currentSpecialist
  )
  const course = useAppSelector((state) => state.course.course)
  const [cookie] = useCookies([CookieEnum.SupplementCourse])

  const dispatch = useAppDispatch()
  const { isMobile, isTablet } = useMatchMedia()

  const navigate = useNavigate()

  const clientsFilterButtonsList = profileData.nutritionist
    ? [
        {
          currentFilterType: ClientsCurrentFilterEnum.Chronology,
          title: styles[0]?.TextIDsRepository.infoSideBarChronology,
        },
        {
          currentFilterType: ClientsCurrentFilterEnum.Accruals,
          title: styles[0]?.TextIDsRepository.infoSideBarAccruals,
        },
      ]
    : [
        {
          currentFilterType: ClientsCurrentFilterEnum.Chronology,
          title: styles[0]?.TextIDsRepository.infoSideBarChronology,
        },
      ]

  useEffect(() => {
    if (profileDataFilter !== 'clients') {
      profileData.sortedBySupplementSelectedDeliveryCourse.forEach(
        (supplement) => {
          dispatch(getSupplementAsync(supplement.supplement.Article))
        }
      )
    }
  }, [])

  useEffect(() => {
    // TODO: отключил чтобы ветка bank-account работала
    if (currentFilter === 'data') {
      handleGetCustomerEvents(profileData.customerID)
      return
    }
    if (currentClient && currentClient.data) {
      handleGetCustomerEvents(currentClient.data.CustomerID)
      return
    }
  }, [currentClient.data, currentFilter])

  useEffect(() => {
    const formData = new FormData()

    if (uploadFiles.length > 0) {
      formData.append('file', uploadFiles[0].file)
      formData.append('header', `Загружено: ${uploadFiles.length} шт.`)

      uploadCustomersFiles(profileData.customerID, formData)
        .then((response) => {
          if (response.eventId) {
            dispatch(addEvent({ ...response }))
          }
        })
        .finally(() => dispatch(clearUploadFileList()))
    }
  }, [uploadFiles])

  const handleGetCustomerEvents = (customerID: string) => {
    getCustomerEvents(customerID).then((response) => {
      if (response.events instanceof AxiosError) {
        dispatch(setErrorState(true))
        return
      }

      dispatch(
        setCurrentClientEvents({
          events: response,
        })
      )

      dispatch(addAllEvents(response.events))
    })
  }

  const dragEnterHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const tempCounter = dragHandlerCounter + 1

    setDragHandlerCounter(tempCounter)
    setIsShowLoadFileScreen(true)
  }

  const dragLeaveHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setDragHandlerCounter((prevState) => {
      const tempCount = prevState - 1
      if (tempCount === 0) {
        setIsShowLoadFileScreen(false)
      }

      return tempCount
    })
  }

  const handleCommentInput = (value: string) => {
    const valueAsArray = value.split(' ')

    const commentHeader = valueAsArray.slice(0, 4).join(' ')
    const commentBody =
      valueAsArray.slice(4, valueAsArray.length).join(' ') ?? ''

    customerCreateComment(
      currentClient.data
        ? currentClient.data.CustomerID
        : profileData.customerID,
      { header: commentHeader, body: commentBody }
    ).then((response) => {
      if (response.eventId) {
        dispatch(addEvent({ ...response }))
      }
    })

    if (commentInputRef.current) {
      commentInputRef.current.value = ''
    }
  }

  const handleConsultation = () => {
    const consultationRequest: Omit<
      EventConsultation,
      'eventId' | 'eventType' | 'date'
    > = {
      header: 'Консультация',
      complaints: '',
      diseases: '',
      allergy: '',
      information: '',
      diagnosis: '',
      dietHistory: '',
      recommendations: '',
    }

    customerCreateConsultation(
      currentClient.data
        ? currentClient.data.CustomerID
        : profileData.customerID,
      consultationRequest
    ).then((response) => {
      if (response.eventId) {
        dispatch(addEvent({ ...response }))
      }
    })
  }

  if (!isShowPanel) {
    return (
      <div
        className='info-side-bar__minimized'
        tabIndex={0}
        role='button'
        onClick={() => setIsShowPanel(true)}
      >
        <button
          className='info-side-bar__minimized__button'
          onClick={() => setIsShowPanel(true)}
        >
          <ChevronLeft />
        </button>

        <span className='info-side-bar__minimized__title'>История работы</span>
      </div>
    )
  }

  return (
    <div
      className={clsx('info-side-bar')}
      onDragEnter={dragEnterHandler}
      onDragLeave={dragLeaveHandler}
      onDragOver={(event) => {
        event.preventDefault()
        event.stopPropagation()
      }}
    >
      <div className='info-side-bar__header'>
        <span className='info-side-bar__header__title'>
          {styles[0].TextIDsRepository.infoSideBarHistoryTitle}

          {currentClient.data && `: ${currentClient.data.Name}`}
        </span>

        {!profileData.nutritionist && (
          <button
            className='info-side-bar__nutritionist'
            onClick={() => navigate(RoutesEnum.Specialists)}
          >
            <div className='info-side-bar__nutritionist__icon-container'>
              {currentSpecialist ? (
                <>
                  {currentSpecialist.pathLink ? (
                    <img
                      src={currentSpecialist.pathLink}
                      alt=''
                      className='info-side-bar__nutritionist__icon-container__photo'
                    />
                  ) : (
                    <div className='specialist-card__photo__specialist'>
                      <User className='specialist-card__photo__specialist__empty' />
                    </div>
                  )}

                  <img
                    src={styles[0]?.TextIDsRepository.assets.statusIcon}
                    alt=''
                    className={clsx(
                      'specialist-card__photo__active',
                      'info-side-bar__nutritionist__icon-container__status'
                    )}
                  />
                </>
              ) : (
                <User />
              )}
            </div>

            {currentSpecialist && !isMobile ? (
              <div className='info-side-bar__nutritionist__name-container'>
                <span className='info-side-bar__nutritionist__first-name'>
                  {currentSpecialist.customerSurname}
                </span>

                <span className='info-side-bar__nutritionist__second-name'>
                  {`${currentSpecialist.customerName} ${currentSpecialist.customerPatronymic}`}
                </span>
              </div>
            ) : (
              <>
                <span className='info-side-bar__nutritionist__title'>
                  Выберите специалиста
                </span>

                <PortalTooltip
                  text={
                    'Прикрепитесь к специалисту, чтобы он собрал для Вас персональный курс.\n' +
                    'Открепиться нельзя'
                  }
                  classNames='info-side-bar__nutritionist__tooltip'
                >
                  <div className='info-side-bar__nutritionist__tooltip__icon'>
                    <HelpCircle />
                  </div>
                </PortalTooltip>
              </>
            )}
          </button>
        )}

        {(isMobile || isTablet) && (
          <button
            className='info-side-bar__close-button'
            onClick={() => {
              setIsShowPanel(false)
              dispatch(setCurrentClient({ data: null, events: null }))
            }}
          >
            {isMobile ? <ChevronRight /> : <X />}
          </button>
        )}
      </div>

      <LayoutGroup id='infoSideBar'>
        <FilterControls
          classNames='info-side-bar__controls'
          buttonsList={clientsFilterButtonsList}
          state={clientsCurrentFilter}
          changeState={setClientsCurrentFilter}
        />
      </LayoutGroup>

      {clientsCurrentFilter.type === ClientsCurrentFilterEnum.Chronology && (
        <>
          <div className='info-side-bar__clients-controls'>
            <label className='info-side-bar__clients-controls__label'>
              <input
                className='info-side-bar__clients-controls__input'
                placeholder='Добавьте комментарий'
                ref={commentInputRef}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleCommentInput(commentInputRef.current?.value as string)
                  }
                }}
              />

              <button
                onClick={() =>
                  handleCommentInput(commentInputRef.current?.value as string)
                }
                className='info-side-bar__clients-controls__comment-button'
              >
                <img
                  src={styles[0]?.TextIDsRepository.assets.telegramIcon}
                  alt=''
                />
              </button>
            </label>

            <PortalTooltip
              text={
                styles[0]?.TextIDsRepository.personalAccountConsultationTooltip
              }
              classNames='info-side-bar__clients-controls__tooltip'
            >
              <button
                className='info-side-bar__clients-controls__button'
                onClick={handleConsultation}
              >
                <FileText />
              </button>
            </PortalTooltip>

            <PortalTooltip
              text={styles[0]?.TextIDsRepository.personalAccountFilesTooltip}
              classNames='info-side-bar__clients-controls__tooltip'
            >
              <button
                className='info-side-bar__clients-controls__button'
                onClick={() => setIsShowLoadFileScreen(true)}
              >
                <Paperclip />
              </button>
            </PortalTooltip>

            <PortalTooltip
              text={styles[0]?.TextIDsRepository.personalAccountResearchTooltip}
              classNames='info-side-bar__clients-controls__tooltip'
            >
              <button
                className='info-side-bar__clients-controls__research'
                onClick={() => navigate(RoutesEnum.Analysis)}
              >
                <img
                  src={styles[0]?.TextIDsRepository.assets.microscopeIcon}
                  alt=''
                />
              </button>
            </PortalTooltip>

            <PortalTooltip
              text={styles[0]?.TextIDsRepository.personalAccountCourseTooltip}
              classNames='info-side-bar__clients-controls__tooltip'
            >
              <button
                className='info-side-bar__clients-controls__course'
                onClick={() => navigate(RoutesEnum.Main)}
              >
                <img
                  src={styles[0]?.TextIDsRepository.assets.packageIcon}
                  alt=''
                />
              </button>
            </PortalTooltip>
          </div>

          <motion.div
            className='info-side-bar__clients-controls__events'
            layout
            animate={{ height: '100%' }}
          >
            {cookie.SupplementCourse && !currentClient.data && course.id && (
              <EventSupplementCard />
            )}

            <AnimatePresence initial={false}>
              {clientEvents.map((event) => {
                switch (event.eventType) {
                  case EventType.File:
                    return (
                      <EventFileCard
                        key={event.eventId}
                        date={event.date}
                        files={event}
                      />
                    )
                  case EventType.Comment:
                    return (
                      <EventCommentCard key={event.eventId} comment={event} />
                    )
                  case EventType.Consultation:
                    return (
                      <EventConsultationCard
                        key={event.eventId}
                        consultation={event}
                      />
                    )
                  case EventType.Survey:
                    return (
                      <EventSurveyCard key={event.eventId} survey={event} />
                    )
                  case EventType.SupplementCourse:
                    return (
                      <EventCourseCard key={event.eventId} course={event} />
                    )
                }
              })}
            </AnimatePresence>
          </motion.div>
        </>
      )}

      {clientsCurrentFilter.type === ClientsCurrentFilterEnum.Accruals && (
        <div className='info-side-bar__clients-controls__events'>
          <EventAccrualCard
            accrual={{
              date: '18.04.23',
              title: 'Лаборатория Helix',
              totalPrice: 10000,
              profitPercent: 10,
              profit: 1000,
              status: 'Создано',
            }}
          />
          <EventAccrualCard
            accrual={{
              date: '16.04.23',
              title: 'Лаборатория INVITRO',
              totalPrice: 1700,
              profitPercent: 3,
              profit: 51,
              status: 'Создано',
            }}
          />
        </div>
      )}

      {isShowLoadFileScreen && (
        <FileUpload
          setIsShowLoadFileScreen={setIsShowLoadFileScreen}
          dragEnterHandler={dragEnterHandler}
          dragLeaveHandler={dragLeaveHandler}
        />
      )}
    </div>
  )
}

export default InfoSideBar
