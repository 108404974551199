import React, { useEffect } from 'react'

export function useOutsideClick(
  ref: React.RefObject<HTMLInputElement>,
  setState: (show: boolean) => void
) {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        ('key' in event && event.key === 'Escape') ||
        (ref.current && !ref.current.contains(event.target))
      ) {
        setState(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleClickOutside)
    }
  }, [ref])
}
