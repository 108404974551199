import React, { useState } from 'react'
import { AxiosError } from 'axios'

import { AnalysisEntity } from '../../../redux/slices/analysisSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import Button from '../../UI/Button'
import ModalAnalysisInfo from '../ModalAnalysisInfo'
import { useMatchMedia } from '../../../hooks/useMatchMedia'
import { addAnalysisToOrder } from '../../../redux/slices/analysisOrderSlice'
import {
  AnalyzesCartSchema,
  analyzesAddCart,
} from '../../../utils/axiosManager'
import { setErrorState } from '../../../redux/slices/errorsSlice'

import './styles.scss'

type AnalysisEntityCardProps = {
  entity: AnalysisEntity
  locationState: { guid: string; name: string }[]
}

const AnalysisEntityCard: React.FC<AnalysisEntityCardProps> = ({
  entity,
  locationState,
}) => {
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false)

  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const analysisOrderList = useAppSelector((state) => state.analysisOrder.list)
  const customerID = useAppSelector((state) => state.profileSlice.customerID)

  const { isMobile } = useMatchMedia()
  const dispatch = useAppDispatch()

  const handleAnalysisToOrder = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    const request: AnalyzesCartSchema = {
      serviceId: entity.serviceId,
      guid: locationState[locationState.length - 1].guid,
    }

    analyzesAddCart(customerID, request).then((response) => {
      if (response instanceof AxiosError) {
        dispatch(setErrorState(true))
        return
      }

      dispatch(
        addAnalysisToOrder({
          history: locationState,
          ...entity,
        })
      )
    })
  }

  return (
    <>
      <div
        className='analysis-entity-card'
        tabIndex={0}
        role='button'
        onClick={() => setIsOpenModalInfo(true)}
      >
        <div className='analysis-entity-card__name-block'>
          <span className='analysis-entity-card__name-block__group-name'>
            {entity.nameService}
          </span>
          <span className='analysis-entity-card__name-block__group-count'>
            <img
              src={styles[0]?.TextIDsRepository.assets.analysisIcon}
              alt=''
            />

            {entity.nameBiomaterial}
          </span>
        </div>

        <div className='analysis-entity-card__count-block'>
          <span className='analysis-entity-card__count-block__group-count'>
            {`${entity.price.toLocaleString()} ${
              styles[0]?.TextIDsRepository.currency
            }`}
          </span>

          <Button
            title={styles[0]?.TextIDsRepository.formTableCartBtnLabel}
            click={handleAnalysisToOrder}
            disabled={analysisOrderList.some(
              (order) => order.serviceId === entity.serviceId
            )}
          />
        </div>
      </div>

      <ModalAnalysisInfo
        isOpenModal={isOpenModalInfo}
        setIsOpenModal={setIsOpenModalInfo}
        analysis={entity}
        fullScreen={isMobile}
      />
    </>
  )
}

export default AnalysisEntityCard
