import React from 'react'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'

import './styles.scss'

type FilterControlsStateProps = {
  type: string
}

type FilterControlsProps = {
  buttonsList: {
    currentFilterType: string
    title: string
  }[]
  state: FilterControlsStateProps
  changeState: (state: FilterControlsStateProps) => void
  classNames?: string
}

const FilterControls: React.FC<FilterControlsProps> = ({
  buttonsList,
  state,
  changeState,
  classNames,
}) => {
  return (
    <div className={clsx('filter-controls-container', classNames)}>
      {buttonsList.map((button) => {
        return (
          <button
            key={button.currentFilterType}
            className={clsx('filter-controls-container__button', {
              'active-filter': state.type === button.currentFilterType,
            })}
            onClick={() => changeState({ type: button.currentFilterType })}
          >
            <span>{button.title}</span>

            <AnimatePresence>
              {state.type === button.currentFilterType ? (
                <motion.div
                  className='active-underline'
                  layoutId='underline'
                  transition={{
                    type: 'spring',
                    duration: 0.2,
                  }}
                ></motion.div>
              ) : null}
            </AnimatePresence>
          </button>
        )
      })}
    </div>
  )
}

export default FilterControls
