import React, { useEffect, useState } from 'react'
import { ChevronDown, ChevronUp, ExternalLink } from 'react-feather'
import { LayoutGroup } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import { CurrentFilter } from '../../CoursePanel/CoursePanel'
import FilterControls from '../../shared/FilterControls'
import Card from '../../UI/Card'
import {
  RecipeOfDay,
  sortedBySupplement,
  sortedByTime,
} from '../../../redux/slices/courseSlice'
import SupplementOfDay from '../../SupplementOfDay'
import SortedByTimeCard from '../../SortedByTimeCard'
import SortedByTimeCardItem from '../../SortedByTimeCard/SortedByTimeCardItem'
import { CheckboxType } from '../../UI/Checkbox/Checkbox'
import { pluralizeWords } from '../../../utils/pluralizeWords'
import { RoutesEnum } from '../../../enums/routes'

import './styles.scss'

const EventSupplementCard = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentFilter, setCurrentFilter] = useState<CurrentFilter>({
    type: 'time',
  })
  const [weekdaysCheckboxes, setWeekdayCheckboxes] = useState<CheckboxType[]>(
    []
  )
  const [weekDeclension, setWeekDeclension] = useState('')
  const { course } = useAppSelector((state) => state.course)
  const { styles } = useAppSelector((state) => state.partnerInterface)
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const filterControlsButtonsList = [
    {
      title: styles[0]?.TextIDsRepository.myCourseByAppointmentTime,
      currentFilterType: 'time',
    },
    {
      title: styles[0]?.TextIDsRepository.myCourseBySupplement,
      currentFilterType: 'supplement',
    },
  ]

  useEffect(() => {
    if (currentFilter.type === 'time') {
      dispatch(sortedByTime())
    }

    if (currentFilter.type === 'supplement') {
      dispatch(sortedBySupplement())
    }

    if (styles[0].TextIDsRepository.myCourseWeek.variantsGenitive) {
      pluralizeWords(
        styles[0]?.TextIDsRepository.localeId,
        course.duration,
        styles[0]?.TextIDsRepository.myCourseWeek.variantsGenitive
      ).then((res) => setWeekDeclension(res.toLowerCase()))
    }
  }, [currentFilter])

  return (
    <div className='event-card-container'>
      <div
        className='event-card-container__header'
        role='button'
        tabIndex={0}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <button className='event-card-container__button'>
          {isOpen ? <ChevronUp /> : <ChevronDown />}
        </button>

        <div className='event-card-container__icon'>
          <img src={styles[0]?.TextIDsRepository.assets.packageIcon} alt='' />
        </div>

        {/* TODO: отключил потому что у объекта курсов нет даты создания */}
        {/*<div className='event-card-container__date'>04.03.2023</div>*/}

        <div className='event-card-container__title'>
          {`${styles[0]?.TextIDsRepository.course} ${styles[0]?.TextIDsRepository.myCourseOn} `}
          {weekDeclension}
        </div>

        <span
          className='event-supplement__link'
          role='button'
          tabIndex={0}
          onClick={() => navigate(RoutesEnum.Main)}
        >
          {styles[0]?.TextIDsRepository.personalAccountCourseContinue}
        </span>

        <button
          className='event-supplement__link-button'
          onClick={() => navigate(RoutesEnum.Main)}
        >
          <ExternalLink />
        </button>
      </div>

      {isOpen && (
        <div className='event-card-container__body'>
          <LayoutGroup id='eventSupplement'>
            <FilterControls
              classNames='course-panel-container__controls'
              buttonsList={filterControlsButtonsList}
              state={currentFilter}
              changeState={setCurrentFilter}
            />
          </LayoutGroup>

          <div>
            {currentFilter.type === 'supplement'
              ? course.supplementsList?.map((supplement) => {
                  return (
                    <Card
                      key={supplement.supplement.Article}
                      supplementInCourse={supplement}
                      title={supplement.supplement.GoodsCommercialName}
                      isCollapsedAll={false}
                      classNames='event-supplement__supp-filter'
                    >
                      {supplement.recipes.map((recipe: RecipeOfDay) => {
                        return (
                          <SupplementOfDay
                            key={recipe.id}
                            recipe={recipe}
                            form={supplement.supplement.SupplementForm}
                            article={supplement.supplement.Article}
                            weekdaysCheckboxes={weekdaysCheckboxes}
                            setWeekdayCheckboxes={setWeekdayCheckboxes}
                          />
                        )
                      })}
                    </Card>
                  )
                })
              : Object.entries(course.filteredByTime).map((day) => {
                  return Object.entries(day[1]).map((time) => {
                    return (
                      <SortedByTimeCard
                        key={time[0]}
                        day={day}
                        time={time}
                        isCollapsedAll={false}
                        classNames='event-supplement__time-filter'
                      >
                        {time[1].map((supplement) => {
                          return (
                            <SortedByTimeCardItem
                              key={supplement.supplement.Article}
                              supplement={supplement}
                              numberOfItems={time[1].length}
                            />
                          )
                        })}
                      </SortedByTimeCard>
                    )
                  })
                })}
          </div>
        </div>
      )}
    </div>
  )
}

export default EventSupplementCard
