import React, { useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import clsx from 'clsx'

import { useAppSelector } from '../../../redux/hook'
import { useMatchMedia } from '../../../hooks/useMatchMedia'

import './styles.scss'

const EventPaymentRequestCard = () => {
  const [isOpen, setIsOpen] = useState(false)
  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const { isMobile } = useMatchMedia()

  const renderRequestNumber = () => {
    if (isMobile) {
      return (
        <div className='event-payment-card-container__request-container'>
          <span className='event-payment-card-container__request'>
            Заявка №145529
          </span>
          {isOpen && (
            <span className='event-payment-card-container__status'>
              Создано
            </span>
          )}
        </div>
      )
    } else {
      return (
        <span className='event-payment-card-container__request'>
          Заявка на вывод №145529
        </span>
      )
    }
  }

  return (
    <div className='event-payment-card-container'>
      <div
        className={clsx(
          'event-card-container__header',
          'event-payment-card-container__header'
        )}
        role='button'
        tabIndex={0}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <button className='event-card-container__button'>
          {isOpen ? <ChevronUp /> : <ChevronDown />}
        </button>

        <div className='event-card-container__icon'>
          <img src={styles[0]?.TextIDsRepository.assets.balance} alt='' />
        </div>

        <div className='event-card-container__date'>18.04.2023</div>

        {renderRequestNumber()}

        {!isMobile && (
          <span className='event-payment-card-container__status'>Создано</span>
        )}

        <span className='event-payment-card-container__payment'>
          2 000 {styles[0]?.TextIDsRepository.currency}
        </span>
      </div>

      {isOpen && (
        <div className='event-payment-card-container__body'>
          <span>Банковский счёт Тинькоф, руб...</span>
          <span>Р/с: 40802810064580000000</span>
          <span>К/с: 30100000000000000977</span>
          <span>БИК: 045525977</span>
        </div>
      )}
    </div>
  )
}

export default EventPaymentRequestCard
