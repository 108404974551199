import clsx from 'clsx'
import { motion } from 'framer-motion'

import './styles.scss'

type EventCardBodyProps = {
  isOpen: boolean
  children: React.ReactElement | React.ReactNode | JSX.Element[]
  className?: string
  classNamePadding?: string
}

const EventCardBody: React.FC<EventCardBodyProps> = ({
  isOpen,
  children,
  className,
  classNamePadding,
}) => {
  const animate = {
    open: { opacity: 1, height: 'auto', display: 'block' },
    collapsed: {
      opacity: 0,
      height: 0,
      transitionEnd: {
        display: 'none',
      },
    },
  }

  return (
    <motion.div
      className={clsx('event-card__body', className)}
      initial={{ height: 0 }}
      animate={isOpen ? animate.open : animate.collapsed}
    >
      <div
        className={clsx('event-card__body__padding-block', classNamePadding)}
      >
        {children}
      </div>
    </motion.div>
  )
}

export default EventCardBody
