import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type FileItem = {
  fileLink: string
  fileName: string
  file?: File
  fileId?: string
}

export type FileState = {
  fileList: FileItem[]
}
const initialState: FileState = {
  fileList: [],
}

const uploadFilesSlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    addFiles(state, action: PayloadAction<FileItem[]>) {
      state.fileList = action.payload
    },
    addFile(state, action: PayloadAction<FileItem>) {
      state.fileList.push(action.payload)
    },
    editUploadFileStatus(state, action: PayloadAction<FileItem>) {
      state.fileList = state.fileList.map((file) => {
        if (
          file.file &&
          action.payload.file &&
          file.file.name === action.payload.file.name
        ) {
          return {
            ...file,
            fileId: action.payload.fileId,
          }
        }

        return { ...file }
      })
    },
    editUploadFileLink(state, action: PayloadAction<FileItem>) {
      state.fileList = state.fileList.map((file) => {
        if (
          file.file &&
          action.payload.file &&
          file.file.name === action.payload.file.name
        ) {
          return {
            ...file,
            fileLink: action.payload.fileLink,
          }
        }

        return { ...file }
      })
    },
    deleteUploadFile(state, action: PayloadAction<{ fileId: string }>) {
      state.fileList = state.fileList.filter(
        (file) => file.fileId !== action.payload.fileId
      )
    },
    clearUploadFileList(state) {
      state.fileList = []
    },
  },
})

export const {
  addFile,
  addFiles,
  editUploadFileStatus,
  editUploadFileLink,
  deleteUploadFile,
  clearUploadFileList,
} = uploadFilesSlice.actions

export default uploadFilesSlice.reducer
