import React from 'react'
import clsx from 'clsx'
import { X } from 'react-feather'
import { motion } from 'framer-motion'

import './styles.scss'

type ModalWrapperProps = {
  children: React.ReactNode
  close?: () => void
  setIsOpen?: (value: boolean) => void
  closeButton?: boolean
  fullScreen?: boolean
  containerClassNames?: string
  contentClassNames?: string
  closeButtonClassNames?: string
}

export const ModalWrapper: React.FC<ModalWrapperProps> = ({
  closeButton = true,
  fullScreen = false,
  close,
  setIsOpen,
  children,
  containerClassNames,
  contentClassNames,
  closeButtonClassNames,
}) => {
  const closeModal = () => {
    if (setIsOpen) {
      setIsOpen(false)

      return
    }

    if (close) {
      close()
    }
  }

  return (
    <motion.div
      className={clsx('modal', containerClassNames, {
        'full-screen-modal': fullScreen,
      })}
      role='button'
      tabIndex={0}
      onClick={closeModal}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.2 } }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className={clsx('modal__content', contentClassNames, {
          'full-screen-modal__content': fullScreen,
        })}
        role='presentation'
        onClick={(e) => e.stopPropagation()}
        initial={{ scale: 0.5 }}
        animate={{ scale: 1, transition: { duration: 0.2 } }}
        exit={{ scale: 0 }}
      >
        {children}

        {closeButton && (
          <button
            className={clsx('modal__close-button', closeButtonClassNames)}
            onClick={closeModal}
          >
            <X />
          </button>
        )}
      </motion.div>
    </motion.div>
  )
}
