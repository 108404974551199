import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AxiosError } from 'axios'

import Input from '../UI/Input'
import Button from '../UI/Button'
import {
  CustomerBankAccountRequisites,
  customerCreateBankAccount,
  customerUpdateBankAccount,
  getCustomerBankAccount,
} from '../../utils/axiosManager'
import { useAppSelector } from '../../redux/hook'
import { ApiStatuses } from '../../enums/apiRoutes'
import ErrorMessage from '../ErrorMessage/ErrorMessage'

import './styles.scss'
import Spinner from '../Spinner'

enum RequisitesStatus {
  Create = 'create',
  Edit = 'edit',
}

const BankRequisites = () => {
  const [requisitesStatus, setRequisitesStatus] = useState<RequisitesStatus>(
    RequisitesStatus.Create
  )

  const profileData = useAppSelector((state) => state.profileSlice)

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: { isDirty, isSubmitting, errors },
  } = useForm<CustomerBankAccountRequisites>({
    defaultValues: {
      recipientName: '',
      inn: '',
      kpp: '',
      accountName: '',
      settlementAccount: '',
      correspondentAccount: '',
      bik: '',
    },
    mode: 'onChange',
  })

  const formData = watch()

  useEffect(() => {
    getCustomerBankAccount(profileData.customerID).then((res) => {
      if (
        res instanceof AxiosError &&
        res.response?.status === ApiStatuses.NotFound
      ) {
        setRequisitesStatus(RequisitesStatus.Create)

        return
      }

      const formValues = getValues()
      setRequisitesStatus(RequisitesStatus.Edit)

      Object.keys(formValues).map((formKey) => {
        const key = formKey as keyof CustomerBankAccountRequisites

        setValue(key, res[key])
      })
    })
  }, [])

  const handleSaveRequisites = () => {
    if (requisitesStatus === RequisitesStatus.Create) {
      customerCreateBankAccount(profileData.customerID, getValues()).then(
        (res) => console.log(res)
      )

      return
    }

    if (requisitesStatus === RequisitesStatus.Edit) {
      customerUpdateBankAccount(profileData.customerID, getValues()).then(
        (res) => console.log(res)
      )
    }
  }

  if (isSubmitting) {
    return (
      <div className='bank-requisites'>
        <span className='bank-requisites__title'>Реквизиты</span>

        <Spinner />
      </div>
    )
  }

  return (
    <div className='bank-requisites'>
      <span className='bank-requisites__title'>Реквизиты</span>

      <form
        className='bank-requisites__form'
        onSubmit={handleSubmit(handleSaveRequisites)}
      >
        <Input
          title='Наименование получателя *'
          classNameContainer='bank-requisites__form__child'
          error={!!errors.recipientName}
          register={{ ...register('recipientName') }}
        />
        <Input
          title='ИНН *'
          numeric={true}
          maxLength={12}
          register={{ ...register('inn') }}
        />
        <Input
          title='КПП *'
          numeric={true}
          maxLength={9}
          register={{ ...register('kpp') }}
        />
        <Input
          title='Имя счёта (произвольно) *'
          register={{ ...register('accountName') }}
        />
        <Input
          title='Расчётный счёт *'
          maxLength={20}
          numeric={true}
          register={{ ...register('settlementAccount') }}
        />
        <Input
          title='Корреспондентский счёт *'
          maxLength={20}
          numeric={true}
          register={{ ...register('correspondentAccount') }}
        />
        <Input
          title='БИК *'
          maxLength={9}
          numeric={true}
          register={{ ...register('bik') }}
        />

        <Button
          title='Сохранить'
          classNames='bank-requisites__save-button'
          disabled={
            formData.recipientName.length === 0 ||
            formData.inn.length !== 12 ||
            formData.kpp.length !== 9 ||
            formData.accountName.length === 0 ||
            formData.settlementAccount.length !== 20 ||
            formData.correspondentAccount.length !== 20 ||
            formData.bik.length !== 9 ||
            !isDirty
          }
          type='submit'
        />
      </form>

      {Object.entries(errors).map(([errorKey, errorValue]) => (
        <ErrorMessage key={errorKey} message={errorValue.message} />
      ))}
    </div>
  )
}

export default BankRequisites
