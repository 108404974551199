import React from 'react'
import { useCookies } from 'react-cookie'
import { ulid } from 'ulid'

import Button from '../UI/Button'
import { useDefineCurrentPathName } from '../../hooks/useDefineCurrentPathName'
import { RoutesEnum } from '../../enums/routes'
import { CookieEnum } from '../../enums/cookie'
import {
  addFile,
  addFiles,
  FileItem,
} from '../../redux/slices/uploadFilesSlice'
import { useAppDispatch, useAppSelector } from '../../redux/hook'

import './styles.scss'

type FileUploadProps = {
  setIsShowLoadFileScreen: (value: boolean) => void
  dragEnterHandler: (event: React.DragEvent<HTMLDivElement>) => void
  dragLeaveHandler: (event: React.DragEvent<HTMLDivElement>) => void
  setFileLoadError?: (value: { name: string }) => void
}

const FileUpload: React.FC<FileUploadProps> = ({
  setIsShowLoadFileScreen,
  dragEnterHandler,
  dragLeaveHandler,
  setFileLoadError,
}) => {
  const uploadFiles = useAppSelector((state) => state.uploadFiles.fileList)

  const [cookies] = useCookies([CookieEnum.CustomerID])
  const isCabinetPathName = useDefineCurrentPathName(
    `${RoutesEnum.PersonalCabinet}/*`
  )

  const dispatch = useAppDispatch()

  const dropHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()

    const files: (FileItem & { isUpload: boolean })[] = []
    const tempFiles = [...(event.dataTransfer as DataTransfer).files]

    tempFiles.forEach((file) => {
      if (file.size > 2097152 && setFileLoadError) {
        setFileLoadError({
          name: file.name,
        })

        return
      }

      files.push({
        file,
        fileLink: '',
        isUpload: false,
        fileId: ulid(),
      })
    })

    dispatch(
      addFiles(
        !cookies.CustomerID
          ? [...uploadFiles, ...files].slice(0, 2)
          : [...uploadFiles, ...files]
      )
    )

    setIsShowLoadFileScreen(false)
  }

  const handleUploadFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const files: File[] = Object.values(event.target.files as FileList)

    files.forEach((file) => {
      dispatch(
        addFile({
          file: file,
          fileLink: '',
        })
      )
    })

    setIsShowLoadFileScreen(false)
  }

  return (
    <div className='file-upload-container'>
      <div
        className='file-upload-container__content'
        tabIndex={0}
        role='button'
        onDrop={dropHandler}
        onDragEnter={dragEnterHandler}
        onDragLeave={dragLeaveHandler}
        onDragOver={(event) => {
          event.preventDefault()
          event.stopPropagation()
        }}
        onClick={() => setIsShowLoadFileScreen(false)}
      >
        <span className='file-upload-container__content__title'>
          Перетащите файлы сюда
        </span>

        {isCabinetPathName && (
          <div className='file-upload-container__content__text'>
            <span>или нажмите</span>
            <Button
              title='Загрузить'
              classNames='file-upload-button'
              click={(event) => event.stopPropagation()}
            >
              <input type='file' multiple onChange={handleUploadFiles} />
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default FileUpload
