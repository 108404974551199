import React, { useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'

import { useAppSelector } from '../../../redux/hook'
import { useMatchMedia } from '../../../hooks/useMatchMedia'

import './styles.scss'

type EventAccrualCardProps = {
  accrual: {
    date: string
    title: string
    totalPrice: number
    profitPercent: number
    profit: number
    status?: string
  }
}

const EventAccrualCard: React.FC<EventAccrualCardProps> = ({ accrual }) => {
  const [isOpen, setIsOpen] = useState(false)
  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const { isMobile } = useMatchMedia()

  if (!styles.length) {
    return null
  }

  return (
    <div className='event-card-container'>
      <div
        className='event-card-container__header event-accrual-card__header'
        role='button'
        tabIndex={0}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <button className='event-card-container__button'>
          {isOpen ? <ChevronUp /> : <ChevronDown />}
        </button>

        <div className='event-card-container__icon'>
          <img
            src={styles[0].TextIDsRepository.assets.flaskIcon}
            alt=''
            className='event-analysis__icon'
          />
        </div>

        <div className='event-card-container__date'>{accrual.date}</div>

        <div className='event-accrual-card__title-wrapper'>
          <div className='event-card-container__title'>{accrual.title}</div>
          {!isMobile && (
            <div className='event-accrual-card__title-wrapper__status'>
              {accrual.status}
            </div>
          )}
        </div>

        {!isMobile && (
          <div className='event-accrual-card__price'>
            <div>{`${accrual.totalPrice} ${styles[0].TextIDsRepository.currency}`}</div>
            <div>{`${accrual.profitPercent}%`}</div>
          </div>
        )}

        <div className='event-card-container__title event-accrual-card__profit'>
          {`${accrual.profit} ${styles[0].TextIDsRepository.currency}`}
        </div>
      </div>

      {isOpen && (
        <div className='event-card-container__body'>
          {isMobile && (
            <div className='event-accrual-card__body__status-container'>
              <div className='event-accrual-card__title-wrapper__status'>
                {accrual.status}
              </div>

              <div className='event-accrual-card__price'>
                <div>{`Сумма: ${accrual.totalPrice} ${styles[0].TextIDsRepository.currency}`}</div>
                <div>{`Ставка: ${accrual.profitPercent}%`}</div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default EventAccrualCard
