import { AxiosError } from 'axios'

import { ApiRoutes } from '../../enums/apiRoutes'
import { deleteData, getData, setData } from '../../utils/axiosManager'
import { CustomerAttachRequest, SpecialistResponse } from './interface'
import { CustomerRequest } from '../auth/interface'

export const getSpecialists = async (
  baseURL = ApiRoutes.GetSpecialists
): Promise<SpecialistResponse[]> => {
  return getData(baseURL)
}

export const customerAttach = async (
  clientId: string,
  data: CustomerAttachRequest,
  baseURL = ApiRoutes.CustomerAttach
): Promise<{ status: number }> => {
  return setData(baseURL + `?clientId=${clientId}`, data).then((response) => {
    if (response instanceof AxiosError && response.response?.status) {
      return { status: response.response.status }
    }

    return { status: response }
  })
}

export const deleteClient = async (
  customerId: string,
  data: CustomerRequest['delete'],
  baseURL = ApiRoutes.Customer
) => {
  return deleteData(baseURL + `?customerId=${customerId}`, data)
}
