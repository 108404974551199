import React from 'react'
import clsx from 'clsx'

import './styles.scss'

type SwitchProps = {
  setChecked: (value: (prevState: boolean) => boolean) => void
  checked?: boolean
}

const Switch: React.FC<SwitchProps> = ({ setChecked, checked }) => {
  return (
    <label className='switch'>
      <input
        type='checkbox'
        className='switch__input'
        checked={checked}
        onChange={() => setChecked((prevState) => !prevState)}
      />
      <span
        className={clsx('switch__slider', 'switch__slider__rounded')}
      ></span>
    </label>
  )
}

export default Switch
