import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { Trash2, ChevronDown, ChevronUp } from 'react-feather'
import InputMask from 'react-input-mask'
import clsx from 'clsx'

import { useAppDispatch, useAppSelector } from '../../redux/hook'
import CurrentAcceptance from '../shared/CurrentAcceptance'
import {
  changeClockTimeSupplementInCourse,
  deleteTimeFromDay,
  TimeOfDaySupplementsType,
} from '../../redux/slices/courseSlice'
import SupplementFactsTable from '../shared/SupplementFactsTable/SupplementFactsTable'
import { SupplementFacts } from '../../redux/slices/modalSupplementInfoSlice'
import { useDefineCurrentPathName } from '../../hooks/useDefineCurrentPathName'

import './styles.scss'

type CardProps = {
  children: ReactNode
  day: [string, { [key: string]: TimeOfDaySupplementsType[] }]
  time: [string, TimeOfDaySupplementsType[]]
  classNames?: string
  isCollapsedAll?: boolean
}

const SortedByTimeCard: React.FC<CardProps> = ({
  children,
  day,
  time,
  classNames,
  isCollapsedAll,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isOpenFacts, setIsOpenFacts] = useState<boolean>(false)
  const [currentTime, setCurrentTime] = useState<string>(time[0])
  const { styles } = useAppSelector((state) => state.partnerInterface)
  const dispatch = useAppDispatch()
  const { supplementFactsList } = useAppSelector(
    (state) => state.supplementFacts
  )
  const isCabinetPathName = useDefineCurrentPathName('/cabinet/*')

  const supplementFacts = useMemo(() => {
    const currentSupplementFacts: SupplementFacts[] = []

    time[1].forEach((supplement) => {
      const currentFact = supplementFactsList.filter(
        (fact) => fact.Article === supplement.supplement.Article
      )[0]

      if (currentFact && currentFact.Supplementsfacts) {
        const allFacts = currentFact.Supplementsfacts.map((fact) => ({
          ...fact,
          ActiveComponentsWeigth:
            fact.ActiveComponentsWeigth * Number(supplement.dosage.dosage[0]),
        }))

        currentSupplementFacts.push(...allFacts)
      }
    })

    return Object.values(
      currentSupplementFacts.reduce(
        (acc: { [key: string]: SupplementFacts }, value) => {
          acc[value.ActiveComponents] = acc[value.ActiveComponents]
            ? {
                ...value,
                ActiveComponentsWeigth:
                  value.ActiveComponentsWeigth +
                  acc[value.ActiveComponents].ActiveComponentsWeigth,
              }
            : value

          return acc
        },
        {}
      )
    )
  }, [time])

  useEffect(() => {
    if (isCollapsedAll) {
      setIsOpen(true)
      return
    }

    setIsOpen(false)
  }, [isCollapsedAll])

  useEffect(() => {
    const currentTimeNumber = Number(currentTime.replace(':', ''))

    if (!isFinite(currentTimeNumber)) {
      return
    }

    time[1].forEach((supplement) => {
      dispatch(
        changeClockTimeSupplementInCourse({
          ...supplement.dosage,
          time: currentTime,
          recipeId: supplement.recipeId,
          article: supplement.supplement.Article,
        })
      )
    })
  }, [currentTime])

  const handleDeleteCard = (
    event: React.MouseEvent,
    dayId: string,
    time: string
  ) => {
    event.stopPropagation()
    dispatch(deleteTimeFromDay({ dayId, time }))
  }

  const handleIsOpen = () => {
    setIsOpen((prevState) => !prevState)
    setIsOpenFacts(false)
  }

  const handleIsOpenWithFacts = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    if (isOpen && !isOpenFacts) {
      setIsOpenFacts(true)
      return
    } else if (isOpen && isOpenFacts) {
      setIsOpen(false)
      setIsOpenFacts(false)
      return
    }

    setIsOpen((prevState) => !prevState)
    setIsOpenFacts((prevState) => !prevState)
  }

  const mask = (time: string) => {
    return [
      /[0-2]/,
      time.startsWith('2') ? /[0-3]/ : /[0-9]/,
      ':',
      /[0-5]/,
      /[0-9]/,
    ]
  }

  return (
    <div className={clsx('card-container', classNames)}>
      <div
        className='card-header'
        role='button'
        tabIndex={0}
        onClick={handleIsOpen}
      >
        <button className='card-header__button'>
          {isOpen ? <ChevronUp /> : <ChevronDown />}
        </button>

        <span className='time-card-container__title'>
          {day[0] === 'daily'
            ? styles[0].TextIDsRepository.myCourseDaily
            : day[0]}{' '}
          в{' '}
          <InputMask
            className='time-card-container__change-time'
            mask={mask(currentTime)}
            value={currentTime}
            onChange={(event) => {
              setCurrentTime(event.target.value)
            }}
            onClick={(event) => event.stopPropagation()}
            disabled={Boolean(isCabinetPathName)}
          />
        </span>

        <div className='time-card-container__product-count'>
          <CurrentAcceptance
            supplements={time[1]}
            onClick={handleIsOpenWithFacts}
          />
        </div>

        {!isCabinetPathName && (
          <button
            onClick={(event) => handleDeleteCard(event, day[0], time[0])}
            className='card-header__delete-button'
          >
            <Trash2 />
          </button>
        )}
      </div>

      {isOpen && <hr className='bottom-divider' />}

      {isOpen && (
        <div className='card-body'>
          {children}
          {isOpenFacts && supplementFacts.length > 0 && (
            <SupplementFactsTable supplementFacts={supplementFacts} />
          )}
        </div>
      )}
    </div>
  )
}

export default SortedByTimeCard
