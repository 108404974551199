import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type ErrorsState = {
  error: boolean
}

const initialState: ErrorsState = {
  error: false,
}

const errorsSlice = createSlice({
  name: 'supplements',
  initialState,
  reducers: {
    setErrorState(state, action: PayloadAction<boolean>) {
      state.error = action.payload
    },
  },
})

export const { setErrorState } = errorsSlice.actions

export default errorsSlice.reducer
