import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type AnalysisGroup = {
  name: string
  guid: string
}

export type AnalysisSubGroup = {
  subGroup: string | null
  guid: string | null
  haveAnalyzes: boolean
}

export type AnalysisEntity = {
  serviceId: number
  nameService: string
  biomaterialId: string
  nameBiomaterial: string
  price: number
  description: string | null
  expertCashback: string | null
}

export type Analysis = {
  name: string
  type: string
  price: number
  group: string
  subGroup?: string
}

type AnalysisState = {
  currentGroup: AnalysisGroup
  groupList: AnalysisGroup[]
  subGroupList: AnalysisSubGroup[]
  analyzesList: AnalysisEntity[]
}

const initialState: AnalysisState = {
  currentGroup: {
    guid: '',
    name: '',
  },
  groupList: [],
  subGroupList: [],
  analyzesList: [],
}

const analysisSlice = createSlice({
  name: 'analyzes',
  initialState,
  reducers: {
    addAnalyzesGroups(state, action: PayloadAction<AnalysisGroup[]>) {
      state.groupList = action.payload
    },
    addAnalyzesSubGroups(state, action: PayloadAction<AnalysisSubGroup[]>) {
      state.subGroupList = action.payload
    },
    addAnalyzesList(state, action: PayloadAction<AnalysisEntity[]>) {
      state.analyzesList = action.payload
    },
    changeCurrentGroup(state, action: PayloadAction<AnalysisGroup>) {
      state.currentGroup = { ...action.payload }
    },
  },
})

export const {
  addAnalyzesGroups,
  addAnalyzesSubGroups,
  addAnalyzesList,
  changeCurrentGroup,
} = analysisSlice.actions

export default analysisSlice.reducer
