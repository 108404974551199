import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'

export type Purpose = {
  Purpose: string
  PurposePicture: string
}

export type Supplement = {
  Article: string
  Picture: string
  GoodsCommercialName: string
  CommercialDescription: string
  SupplementForm: string
  Purposes: Purpose[]
  CurrentPrices: number
}

type SupplementState = {
  list: Supplement[]
  purposesList: { purpose: Purpose; supplements: Supplement[] }[]
  activePurpose: string
}

const initialState: SupplementState = {
  list: [],
  purposesList: [],
  activePurpose: '',
}

const supplementSlice = createSlice({
  name: 'supplements',
  initialState,
  reducers: {
    addAllSupplements(state, action: PayloadAction<Supplement[]>) {
      return {
        ...state,
        list: action.payload,
      }
    },
    addSupplement(state, action: PayloadAction<Supplement>) {
      return { ...state, list: [...state.list, action.payload] }
    },
    addPurpose(state, action: PayloadAction<Purpose>) {
      const supplementInPurpose = current(state).list.filter((supplement) => {
        if (
          supplement.Purposes.some(
            (purpose) => purpose.Purpose === action.payload.Purpose
          )
        ) {
          return { ...supplement }
        }
      })

      const isPurpose = state.purposesList.some(
        (purpose) => purpose.purpose.Purpose === action.payload.Purpose
      )

      return {
        ...state,
        purposesList: !isPurpose
          ? [
              ...state.purposesList,
              { purpose: action.payload, supplements: supplementInPurpose },
            ]
          : state.purposesList,
      }
    },
    changeActivePurpose(state, action: PayloadAction<string>) {
      return {
        ...state,
        activePurpose: action.payload,
      }
    },
  },
})

export const { addAllSupplements, addPurpose, changeActivePurpose } =
  supplementSlice.actions

export default supplementSlice.reducer
