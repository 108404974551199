import React, { useState } from 'react'
import { ArrowLeft, ArrowRight, ChevronDown, ChevronUp } from 'react-feather'
import clsx from 'clsx'

import { useAppSelector } from '../../../redux/hook'
import AnalysisPanelBody from './AnalysisPanelBody'
import { useMatchMedia } from '../../../hooks/useMatchMedia'

import './styles.scss'

const AnalysisPanel = () => {
  const [isOpenPanel, setIsOpenPanel] = useState(false)
  const [currentSpecialist, setCurrentSpecialist] = useState(0)

  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const specialistList = useAppSelector((state) => state.specialists.list)

  const { isMobile } = useMatchMedia()

  const handleSliderLeft = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    if (currentSpecialist > 0) {
      setCurrentSpecialist((prevState) => prevState - 1)
    }
  }

  const handleSliderRight = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    if (currentSpecialist < specialistList.length - 1) {
      setCurrentSpecialist((prevState) => prevState + 1)
    }
  }

  return (
    <div
      className={clsx('analysis-panel', {
        'analysis-panel__opened': isOpenPanel,
      })}
    >
      <div className='analysis-panel__content'>
        <div
          className={clsx('analysis-panel__header', {
            'analysis-panel__header__opened': isOpenPanel,
          })}
          tabIndex={0}
          role='button'
          onClick={() => setIsOpenPanel((prevState) => !prevState)}
        >
          <button
            className={clsx('analysis-panel__open-button', {
              'analysis-panel__open-button__opened': isOpenPanel && isMobile,
            })}
          >
            {isOpenPanel ? <ChevronUp /> : <ChevronDown />}

            {isOpenPanel && isMobile && (
              <span className='analysis-panel__header__content__specialist-title'>
                {styles[0].TextIDsRepository.analysisPanelTitle}
              </span>
            )}
          </button>

          <div
            className={clsx('analysis-panel__header__content', {
              'analysis-panel__header__content__opened':
                isOpenPanel && isMobile,
            })}
          >
            {!isOpenPanel ? (
              <span className='analysis-panel__header__content__title'>
                {styles[0]?.TextIDsRepository.analysisPanelTitle}
              </span>
            ) : (
              specialistList.map((specialist, index) => {
                if (index === currentSpecialist) {
                  return (
                    <div
                      className='analysis-panel__header__content__specialist'
                      key={specialist.id + index + currentSpecialist}
                    >
                      <img
                        className={clsx(
                          'analysis-panel__header__content__avatar',
                          'analysis-panel__fade-in'
                        )}
                        src={specialistList[currentSpecialist].photo}
                        alt=''
                      />

                      <div className='analysis-panel__header__content__specialist-header'>
                        {!isMobile && (
                          <span className='analysis-panel__header__content__specialist-title'>
                            {styles[0].TextIDsRepository.analysisPanelTitle}
                          </span>
                        )}

                        {isMobile && (
                          <button
                            className='analysis-panel__body__left-button'
                            onClick={handleSliderLeft}
                            disabled={currentSpecialist === 0}
                          >
                            <ArrowLeft />
                          </button>
                        )}

                        <span
                          className={clsx(
                            'analysis-panel__fade-in',
                            'analysis-panel__header__content__specialist-name'
                          )}
                        >
                          {specialistList[currentSpecialist].firstName}{' '}
                          {specialistList[currentSpecialist].lastName}{' '}
                          {specialistList[currentSpecialist].surName}
                        </span>

                        {!isMobile && (
                          <span
                            className={clsx(
                              'analysis-panel__fade-in',
                              'analysis-panel__header__content__specialist-jobs'
                            )}
                          >
                            {specialistList[currentSpecialist].specializations}
                          </span>
                        )}

                        {isMobile && (
                          <button
                            className='analysis-panel__body__right-button'
                            onClick={handleSliderRight}
                            disabled={
                              currentSpecialist === specialistList.length - 1
                            }
                          >
                            <ArrowRight />
                          </button>
                        )}
                      </div>

                      {isMobile && (
                        <span
                          className={clsx(
                            'analysis-panel__fade-in',
                            'analysis-panel__header__content__specialist-jobs'
                          )}
                        >
                          {specialistList[currentSpecialist].specializations}
                        </span>
                      )}
                    </div>
                  )
                }
              })
            )}
          </div>
        </div>

        {isOpenPanel && (
          <div className='analysis-panel__body'>
            {!isMobile && (
              <button
                className='analysis-panel__body__left-button'
                onClick={handleSliderLeft}
                disabled={currentSpecialist === 0}
              >
                <ArrowLeft />
              </button>
            )}

            {specialistList.map((specialist, index) => {
              if (index === currentSpecialist) {
                return (
                  <AnalysisPanelBody
                    key={specialistList[currentSpecialist].id}
                    currentSpecialist={specialistList[currentSpecialist]}
                  />
                )
              }
            })}

            {!isMobile && (
              <button
                className='analysis-panel__body__right-button'
                onClick={handleSliderRight}
                disabled={currentSpecialist === specialistList.length - 1}
              >
                <ArrowRight />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default AnalysisPanel
