import React, { useState } from 'react'
import clsx from 'clsx'
import {
  AlertCircle,
  ChevronLeft,
  ChevronRight,
  HelpCircle,
  Trash2,
  User,
  X,
} from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import PortalTooltip from '../../UI/PortalTooltip'
import TextArea from '../../UI/TextArea'
import FileUpload from '../../FileUpload'
import ErrorMessage from '../../ErrorMessage/ErrorMessage'
import Button from '../../UI/Button'
import {
  clearUploadFileList,
  deleteUploadFile,
} from '../../../redux/slices/uploadFilesSlice'
import { RoutesEnum } from '../../../enums/routes'
import { uploadCustomersFiles } from '../../../utils/axiosManager'
import { useMatchMedia } from '../../../hooks/useMatchMedia'
import { customerSendMail } from '../../../models/customer/api'
import { CustomerSendMailRequest } from '../../../models/customer/interface'
import { setErrorState } from '../../../redux/slices/errorsSlice'
import { ApiStatuses } from '../../../enums/apiRoutes'

import './styles.scss'

const SpecialistsSideBar = () => {
  const [isShowLoadFileScreen, setIsShowLoadFileScreen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [dragHandlerCounter, setDragHandlerCounter] = useState(0)
  const [fileLoadError, setFileLoadError] = useState<{ name: string } | null>(
    null
  )
  const [fileLoadNotification, setFileLoadNotification] = useState<{
    count: number
    day: string
    time: string
  } | null>(null)
  const [specialistMessage, setSpecialistMessage] = useState('')

  const uploadFiles = useAppSelector((state) => state.uploadFiles.fileList)
  const styles = useAppSelector((state) => state.partnerInterface.styles)
  const currentSpecialist = useAppSelector(
    (state) => state.specialists.currentSpecialist
  )
  const profileData = useAppSelector((state) => state.profileSlice)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { isMobile } = useMatchMedia()

  const dragEnterHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()

    const tempCounter = dragHandlerCounter + 1

    setDragHandlerCounter(tempCounter)
    setIsShowLoadFileScreen(true)
  }

  const dragLeaveHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()

    setDragHandlerCounter((prevState) => {
      const tempCount = prevState - 1
      if (tempCount === 0) {
        setIsShowLoadFileScreen(false)
      }

      return tempCount
    })
  }

  const handleUploadingFiles = () => {
    const formData = new FormData()

    if (uploadFiles.length > 0 && currentSpecialist) {
      const header = `Загружено файлов от клиента ${profileData.customerName} ${profileData.customerSurname}: ${uploadFiles.length} шт.`

      formData.append('file', uploadFiles[0].file)
      formData.append('header', header)

      uploadCustomersFiles(currentSpecialist.CustomerID, formData)
        .then((response) => {
          if (!response.eventId) {
            dispatch(setErrorState(true))
            return
          }

          const date = new Date(Date.now()).toLocaleString().split(', ')
          const uploadDay = date[0]
          const uploadTime = date[1].slice(0, -3)

          setFileLoadNotification({
            count: uploadFiles.length,
            day: uploadDay,
            time: uploadTime,
          })
        })
        .finally(() => dispatch(clearUploadFileList()))
    }
  }

  const handleReceiveEmailToSpecialist = () => {
    if (specialistMessage.length > 0 && currentSpecialist) {
      const request: CustomerSendMailRequest = {
        customerId: currentSpecialist.CustomerID,
        text: specialistMessage,
      }

      customerSendMail(profileData.customerID, request).then((response) => {
        if (
          response.status === ApiStatuses.NotFound ||
          response.status === ApiStatuses.ServerError
        ) {
          dispatch(setErrorState(true))
          return
        }

        setSpecialistMessage('')
      })
    }
  }

  const animateTransition = {
    type: 'spring',
    stiffness: 5000,
    damping: 100,
    mass: 0.2,
  }

  if (!currentSpecialist && isOpen) {
    return (
      <motion.aside
        className={clsx('course-panel-container', 'specialist-aside__opened')}
        layout
        transition={animateTransition}
      >
        <div
          className={clsx(
            'specialist-aside__header',
            'specialist-aside__opened__header'
          )}
        >
          <button
            className='specialist-aside__closed__button'
            onClick={() => setIsOpen(false)}
          >
            <ChevronRight />
          </button>

          <span className='specialist-aside__opened__title'>
            {styles[0]?.TextIDsRepository.specialistPageSideBarTitle}
          </span>
        </div>

        <div className='specialist-aside__body'>
          <span className='specialist-aside__opened__desc'>
            {styles[0]?.TextIDsRepository.specialistPageSideBarEmptyDesc}
          </span>
        </div>
      </motion.aside>
    )
  }

  if (
    !currentSpecialist ||
    (isMobile && !isOpen && (!currentSpecialist || currentSpecialist))
  ) {
    return (
      <motion.aside
        className={clsx('course-panel-container', 'specialist-aside')}
        layout
        transition={animateTransition}
      >
        <div className='specialist-aside__closed'>
          {isMobile && (
            <button
              className='specialist-aside__closed__button'
              onClick={() => setIsOpen(true)}
            >
              <ChevronLeft />
            </button>
          )}

          <span className='specialist-aside__closed__title'>
            {styles[0]?.TextIDsRepository.specialistPageSideBarTitle}
          </span>

          {!isMobile && (
            <span className='specialist-aside__closed__desc'>
              {styles[0]?.TextIDsRepository.specialistPageSideBarEmptyDesc}
            </span>
          )}
        </div>
      </motion.aside>
    )
  }

  return currentSpecialist && (!isMobile || (isMobile && isOpen)) ? (
    <motion.aside
      className={clsx('course-panel-container', {
        'specialist-aside__opened': currentSpecialist,
      })}
      onDragEnter={dragEnterHandler}
      onDragLeave={dragLeaveHandler}
      onDragOver={(event) => {
        event.preventDefault()
        event.stopPropagation()
      }}
      layout
      transition={animateTransition}
    >
      <div className='specialist-aside__header'>
        {isMobile && (
          <button
            className='specialist-aside__closed__button'
            onClick={() => setIsOpen(false)}
          >
            <ChevronRight />
          </button>
        )}

        <div className='specialist-aside__header__avatar'>
          {currentSpecialist.pathLink ? (
            <img src={currentSpecialist.pathLink} alt='' />
          ) : (
            <div className='specialist-card__photo__specialist'>
              <User className='specialist-card__photo__specialist__empty' />
            </div>
          )}
        </div>

        <div className='specialist-aside__header__common-info'>
          <span className='specialist-aside__header__common-info__name'>
            {currentSpecialist.CustomerSurname}
          </span>

          <span className='specialist-aside__header__common-info__last-name'>
            {`${currentSpecialist.CustomerName} ${currentSpecialist.CustomerPatronymic}`}
          </span>

          <div className='specialist-aside__header__status'>
            <img src={styles[0]?.TextIDsRepository.assets.statusIcon} alt='' />

            <span className='specialist-aside__header__status__text'>
              {styles[0]?.TextIDsRepository.specialistPageAttachComplete}
            </span>

            <PortalTooltip
              text={styles[0].TextIDsRepository.specialistPageAttachError}
              classNames='specialist-card__body__attach__help-container'
            >
              <div className='specialist-card__body__attach__help-icon'>
                <HelpCircle />
              </div>
            </PortalTooltip>
          </div>
        </div>
      </div>

      <div className='specialist-aside__body'>
        <div
          className={clsx('card-container', 'specialist-aside__body__warning')}
        >
          <div className='specialist-aside__body__warning__icon'>
            <AlertCircle />
          </div>

          <span>{styles[0]?.TextIDsRepository.specialistPageInfo}</span>
        </div>

        <div
          className={clsx('card-container', 'specialist-aside__body__message')}
        >
          <label>
            <span>{styles[0]?.TextIDsRepository.specialistPageMessage}</span>
            <TextArea
              value={specialistMessage}
              onChange={(event) => setSpecialistMessage(event.target.value)}
              placeholder={
                styles[0]?.TextIDsRepository.specialistPageMessagePlaceholder
              }
            />

            <button
              className='specialist-aside__body__label-icon'
              onClick={handleReceiveEmailToSpecialist}
            >
              <img
                src={styles[0]?.TextIDsRepository.assets.telegramIcon}
                alt=''
              />
            </button>
          </label>
        </div>

        <div
          className={clsx(
            'card-container',
            'specialist-aside__body__common-card'
          )}
        >
          <span className='specialist-aside__body__common-card__title'>
            {styles[0]?.TextIDsRepository.specialistPageSurveyTitle}
          </span>

          <span className='specialist-aside__body__common-card__sub-title'>
            {styles[0]?.TextIDsRepository.specialistPageSurveySubTitle}
          </span>

          <button
            className='specialist-aside__body__common-card__link'
            onClick={() => navigate(RoutesEnum.ShortQuestionnaire)}
          >
            <span>
              {styles[0]?.TextIDsRepository.specialistPageSurveyStart}
            </span>
          </button>
        </div>

        <div
          className={clsx(
            'card-container',
            'specialist-aside__body__common-card'
          )}
        >
          <span className='specialist-aside__body__common-card__title'>
            {styles[0]?.TextIDsRepository.specialistPagePersonalSurveyTitle}
          </span>

          <span className='specialist-aside__body__common-card__sub-title'>
            {styles[0]?.TextIDsRepository.specialistPagePersonalSurveySubTitle}
          </span>

          <button
            className='specialist-aside__body__common-card__link'
            disabled
          >
            <span>
              {styles[0]?.TextIDsRepository.specialistPageSurveyStart}
            </span>
          </button>
        </div>

        <div
          className={clsx(
            'card-container',
            'specialist-aside__body__common-card',
            'specialist-aside__body__file-card'
          )}
        >
          <div className='specialist-aside__body__file-card__header'>
            <span className='specialist-aside__body__common-card__title'>
              {styles[0]?.TextIDsRepository.specialistPageSendFilesTitle}
            </span>

            <span className='specialist-aside__body__common-card__sub-title'>
              {styles[0]?.TextIDsRepository.specialistPageSendFilesTypes}
            </span>

            {isMobile && uploadFiles.length > 0 ? (
              <Button
                title={styles[0]?.TextIDsRepository.sendButtonTitle}
                classNames='specialist-aside__body__file-card__send-button'
                click={handleUploadingFiles}
              />
            ) : (
              <button
                className='specialist-aside__body__common-card__link'
                onClick={() => setIsShowLoadFileScreen(true)}
              >
                <span>
                  {styles[0]?.TextIDsRepository.personalAccountSelectCourse}
                </span>
              </button>
            )}
          </div>

          {(uploadFiles.length > 0 || fileLoadError) && (
            <div className='specialist-aside__body__file-card__list'>
              {fileLoadError && (
                <ErrorMessage
                  message={
                    <>
                      <span>
                        {`${styles[0]?.TextIDsRepository.specialistPageLoadErrorFirst} ${fileLoadError.name}. `}

                        {`${styles[0]?.TextIDsRepository.specialistPageLoadErrorSecond}`}
                      </span>

                      <button
                        className='specialist-aside__body__file-card__close-error'
                        onClick={() => setFileLoadError(null)}
                      >
                        <X />
                      </button>
                    </>
                  }
                  classNames='specialist-aside__body__file-card__file-error'
                />
              )}

              {uploadFiles.map((file) => (
                <div key={file.file.name}>
                  <span className='specialist-aside__body__file-card__file-name'>
                    {file.file.name}
                  </span>

                  <button
                    className='specialist-aside__body__file-card__file-del'
                    onClick={() => {
                      if (file.fileId) {
                        dispatch(deleteUploadFile({ fileId: file.fileId }))
                      }
                    }}
                  >
                    <Trash2 />
                  </button>
                </div>
              ))}

              {!isMobile && uploadFiles.length > 0 && (
                <Button
                  title={styles[0]?.TextIDsRepository.sendButtonTitle}
                  classNames='specialist-aside__body__file-card__send-button'
                  click={handleUploadingFiles}
                />
              )}
            </div>
          )}
        </div>

        <div
          className={clsx(
            'card-container',
            'specialist-aside__body__common-card',
            'specialist-aside__body__common-card__contacts-container'
          )}
        >
          <span className='specialist-aside__body__common-card__title'>
            {styles[0]?.TextIDsRepository.specialistPageContacts}
          </span>

          {currentSpecialist.customerInfo && (
            <div className='specialist-aside__body__common-card__contacts'>
              {currentSpecialist.customerInfo.telegram && (
                <div className='specialist-aside__body__common-card__contact'>
                  <img
                    src={styles[0].TextIDsRepository.assets.greyTelegramIcon}
                    alt=''
                  />
                  <span>{currentSpecialist.customerInfo.telegram}</span>
                </div>
              )}

              {currentSpecialist.customerInfo.instagram && (
                <div className='specialist-aside__body__common-card__contact'>
                  <img
                    src={styles[0].TextIDsRepository.assets.instagramIcon}
                    alt=''
                  />
                  <span className='specialist-card__body__view-profile__link'>
                    {currentSpecialist.customerInfo.instagram}
                  </span>
                </div>
              )}

              {currentSpecialist.customerInfo.skype && (
                <div className='specialist-aside__body__common-card__contact'>
                  <img
                    src={styles[0].TextIDsRepository.assets.bestFileSharingIcon}
                    alt=''
                  />
                  <span className='specialist-card__body__view-profile__link'>
                    {currentSpecialist.customerInfo.skype}
                  </span>
                </div>
              )}

              {currentSpecialist.customerInfo.email && (
                <div className='specialist-aside__body__common-card__contact'>
                  <img
                    src={styles[0].TextIDsRepository.assets.mailIcon}
                    alt=''
                  />
                  <span className='specialist-card__body__view-profile__link'>
                    {currentSpecialist.customerInfo.email}
                  </span>
                </div>
              )}

              {currentSpecialist.customerInfo.vkontakte && (
                <div className='specialist-aside__body__common-card__contact'>
                  <img src={styles[0].TextIDsRepository.assets.vkIcon} alt='' />
                  <span className='specialist-card__body__view-profile__link'>
                    {currentSpecialist.customerInfo.vkontakte}
                  </span>
                </div>
              )}

              {currentSpecialist.customerInfo.whatsapp && (
                <div className='specialist-aside__body__common-card__contact'>
                  <img
                    src={styles[0].TextIDsRepository.assets.whatsAppIcon}
                    alt=''
                  />
                  <span className='specialist-card__body__view-profile__link'>
                    {currentSpecialist.customerInfo.whatsapp}
                  </span>
                </div>
              )}

              {currentSpecialist.customerInfo.facebook && (
                <div className='specialist-aside__body__common-card__contact'>
                  <img
                    src={styles[0].TextIDsRepository.assets.facebookIcon}
                    alt=''
                  />
                  <span className='specialist-card__body__view-profile__link'>
                    {currentSpecialist.customerInfo.facebook}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {isShowLoadFileScreen && (
        <FileUpload
          setIsShowLoadFileScreen={setIsShowLoadFileScreen}
          dragEnterHandler={dragEnterHandler}
          dragLeaveHandler={dragLeaveHandler}
          setFileLoadError={setFileLoadError}
        />
      )}

      {fileLoadNotification && (
        <div className='specialist-aside__notification'>
          <span className='specialist-aside__notification__title'>
            {`${styles[0]?.TextIDsRepository.specialistPageFilesTitle}
            ${fileLoadNotification.count}
            ${styles[0]?.TextIDsRepository.myCourseQuantityAbbr}.
            ${styles[0]?.TextIDsRepository.specialistPageSendToSpecialist}
            ${fileLoadNotification.day} ${styles[0]?.TextIDsRepository.prepositionIn}
            ${fileLoadNotification.time}`}
          </span>

          <button
            className='specialist-aside__notification__link'
            onClick={() => {
              setFileLoadNotification(null)
              navigate(RoutesEnum.PersonalCabinet)
            }}
          >
            {styles[0]?.TextIDsRepository.specialistPageMoveToChronology}
          </button>

          <button
            className='specialist-aside__notification__close-button'
            onClick={() => setFileLoadNotification(null)}
          >
            <X />
          </button>
        </div>
      )}
    </motion.aside>
  ) : null
}

export default SpecialistsSideBar
