import React, { useEffect, useRef, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import InputMask from 'react-input-mask'
import clsx from 'clsx'

import RadioButton from '../UI/RadioButton'
import { editSurveyQuestion, SurveyItem } from '../../redux/slices/surveySlice'
import { useAppDispatch, useAppSelector } from '../../redux/hook'

type ShortQuestionnaireItemProps = {
  question: SurveyItem
  index: number
  readOnly?: boolean
}

const ShortQuestionnaireItem: React.FC<ShortQuestionnaireItemProps> = ({
  question,
  index,
  readOnly,
}) => {
  const [editable, setEditable] = useState(false)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const elementRef = useRef(null)

  const styles = useAppSelector((state) => state.partnerInterface.styles)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (elementRef.current == null) {
      return
    }

    const resizeObserver = new ResizeObserver(([entry]) => {
      const childrenWidth = (entry.target.children[0] as HTMLElement)
        .offsetWidth

      setIsOverflowing(childrenWidth > entry.target.clientWidth)
    })

    resizeObserver.observe(elementRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return (
    <div
      key={question.id}
      className={clsx('short-questions__content__questions__item', {
        'short-questions__content__questions__item-answered':
          question.answerType === 'int'
            ? !editable && question.value !== null
            : question.value !== null,
      })}
    >
      <span
        className='short-questions__content__question-container'
        ref={elementRef}
      >
        <span
          className={clsx('short-questions__content__question', {
            'short-questions__content__question-opened': isOpen,
          })}
        >
          {index}. {question.question}
        </span>
      </span>

      {(question.answerType === 'int' || question.answerType === 'text') && (
        <div>
          <label className='short-questions__content__questions__item__label'>
            {(editable || question.value == null) && (
              <InputMask
                inputMode='numeric'
                mask='999'
                maskPlaceholder={null}
                autoFocus={editable && question.value !== null}
                value={question.value == null ? 0 : (question.value as number)}
                onFocus={() => {
                  setEditable(true)
                }}
                onBlur={() => {
                  if (question.value) {
                    setEditable(false)
                  }
                }}
                onChange={(event) => {
                  dispatch(
                    editSurveyQuestion({
                      ...question,
                      value:
                        event.target.value.length === 0 ||
                        event.target.value.startsWith('0')
                          ? null
                          : event.target.value,
                    })
                  )
                }}
              />
            )}
          </label>

          {question.value !== null && !editable && (
            <button onClick={() => !readOnly && setEditable(true)}>
              {question.value}
            </button>
          )}
        </div>
      )}

      {question.answerType === 'bool' && (
        <div>
          {(isOverflowing || isOpen) && (
            <button onClick={() => setIsOpen((prevState) => !prevState)}>
              {!isOpen ? <ChevronDown /> : <ChevronUp />}
            </button>
          )}

          {(editable || question.value == null) && (
            <>
              <RadioButton
                label={styles[0].TextIDsRepository.agreeButtonTitle}
                checked={
                  (question.value !== null && (question.value as boolean)) ||
                  false
                }
                onChange={() => {
                  dispatch(editSurveyQuestion({ ...question, value: true }))
                  setEditable(false)
                }}
              />
              <RadioButton
                label={styles[0].TextIDsRepository.disagreeButtonTitle}
                checked={question.value !== null && !question.value}
                onChange={() => {
                  dispatch(editSurveyQuestion({ ...question, value: false }))
                  setEditable(false)
                }}
              />
            </>
          )}

          {question.value !== null && !editable && readOnly && (
            <button onClick={() => !readOnly && setEditable(true)}>
              {question.value
                ? styles[0].TextIDsRepository.agreeButtonTitle
                : styles[0].TextIDsRepository.disagreeButtonTitle}
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default ShortQuestionnaireItem
