import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'

import App from './App'
import store from './redux/store'

import './styles/_global.scss'

const root = ReactDOM.createRoot(
  document.getElementById(
    'VITAMIN_TRADE_ROOT_BLOCK_FOR_MOUNTING_APP_WITH_UNIQUE_ID'
  ) as HTMLElement
)

root.render(
  <Provider store={store}>
    <CookiesProvider>
      <Router basename={process.env.REACT_APP_ROUTER_BASE_NAME}>
        <App />
      </Router>
    </CookiesProvider>
  </Provider>
)
