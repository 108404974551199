import React from 'react'
import { ChevronRight } from 'react-feather'
import { AxiosError } from 'axios'

import {
  addAnalyzesList,
  addAnalyzesSubGroups,
  AnalysisGroup,
  AnalysisSubGroup,
  changeCurrentGroup,
} from '../../../redux/slices/analysisSlice'
import { getAnalyzes, getGroupChildAnalyzes } from '../../../utils/axiosManager'
import { useAppDispatch, useAppSelector } from '../../../redux/hook'
import { setErrorState } from '../../../redux/slices/errorsSlice'

import './styles.scss'

type AnalysisGroupCardProps = {
  group: AnalysisGroup | AnalysisSubGroup
  setIsLoading: (value: boolean) => void
  setLocationState: (
    value: (
      prevState: { guid: string; name: string }[]
    ) => { guid: string; name: string }[]
  ) => void
}

const AnalysisGroupCard: React.FC<AnalysisGroupCardProps> = ({
  group,
  setIsLoading,
  setLocationState,
}) => {
  const analysisOrderByGuid = useAppSelector(
    (state) => state.analysisOrder.orderByGuid
  )

  const dispatch = useAppDispatch()

  const handleGroupCardClick = () => {
    if (
      (group.guid && !('haveAnalyzes' in group)) ||
      (group.guid && 'haveAnalyzes' in group && !group.haveAnalyzes)
    ) {
      setIsLoading(true)

      getGroupChildAnalyzes(group.guid).then((response) => {
        if (response instanceof AxiosError) {
          dispatch(setErrorState(true))
          return
        }

        const firstSubGroup = response[0]

        if (firstSubGroup.haveAnalyzes) {
          getAnalyzes(group.guid as string).then((analyzes) => {
            if (response instanceof AxiosError) {
              dispatch(setErrorState(true))
              return
            }

            dispatch(addAnalyzesList(analyzes))

            handleChangeGroupState()

            setIsLoading(false)
          })
        }

        if (
          !firstSubGroup.subGroup &&
          !firstSubGroup.haveAnalyzes &&
          group.guid
        ) {
          setIsLoading(false)

          return
        }

        if (firstSubGroup.subGroup) {
          dispatch(addAnalyzesSubGroups(response))

          handleChangeGroupState()

          setIsLoading(false)
        }
      })
    } else if (group.guid && 'haveAnalyzes' in group && group.haveAnalyzes) {
      setIsLoading(true)

      getAnalyzes(group.guid).then((analyzes) => {
        if (analyzes instanceof AxiosError) {
          dispatch(setErrorState(true))
          return
        }

        dispatch(addAnalyzesList(analyzes))

        handleChangeGroupState()

        setIsLoading(false)
      })
    }
  }

  const handleChangeGroupState = () => {
    const currentGroup = {
      guid: group.guid ? group.guid : '',
      name: 'name' in group ? group.name : group.subGroup ? group.subGroup : '',
    }

    setLocationState((prevState) => [...prevState, currentGroup])

    dispatch(changeCurrentGroup(currentGroup))
  }

  return (
    <div
      className='analysis-group-card'
      tabIndex={0}
      role='button'
      onClick={handleGroupCardClick}
    >
      <div className='analysis-group-card__chevron'>
        <ChevronRight />
      </div>

      <span className='analysis-group-card__group-name'>
        {'name' in group ? group.name : group.subGroup}
      </span>

      {group.guid && analysisOrderByGuid[group.guid] && (
        <span className='analysis-group-card__group-count'>
          Добавлено: {analysisOrderByGuid[group.guid].length}
        </span>
      )}
    </div>
  )
}

export default AnalysisGroupCard
