import { configureStore } from '@reduxjs/toolkit'
import supplementsSlice from '../slices/supplementsSlice'
import courseSlice from '../slices/courseSlice'
import errorsSlice from '../slices/errorsSlice'
import modalSupplementSlice from '../slices/modalSupplementSlice'
import partnerInterfaceSlice from '../slices/partnerInterfaceSlice'
import supplementOfDaySlice from '../slices/supplementOfDaySlice'
import modalSupplementInfoSlice from '../slices/modalSupplementInfoSlice'
import supplementFactsSlice from '../slices/supplementFactsSlice'
import authSlice from '../slices/authSlice'
import profileSlice from '../slices/profileSlice'
import shippingPaymentSlice from '../slices/shippingPaymentSlice'
import orderSlice from '../slices/orderSlice'
import clientEventSlice from '../slices/clientEventSlice'
import analysisSlice from '../slices/analysisSlice'
import surveySlice from '../slices/surveySlice'
import uploadFilesSlice from '../slices/uploadFilesSlice'
import personalCabinetSlice from '../slices/personalCabinetSlice'
import specialistsSlice from '../slices/specialistsSlice'
import analysisOrderSlice from '../slices/analysisOrderSlice'
import geoPositionSlice from '../slices/geoPositionSlice'

const store = configureStore({
  reducer: {
    supplements: supplementsSlice,
    course: courseSlice,
    errors: errorsSlice,
    modalSupplement: modalSupplementSlice,
    modalSupplementInfo: modalSupplementInfoSlice,
    partnerInterface: partnerInterfaceSlice,
    supplementOfDay: supplementOfDaySlice,
    supplementFacts: supplementFactsSlice,
    auth: authSlice,
    profileSlice: profileSlice,
    shippingPayment: shippingPaymentSlice,
    order: orderSlice,
    clientEvent: clientEventSlice,
    analysis: analysisSlice,
    analysisOrder: analysisOrderSlice,
    survey: surveySlice,
    uploadFiles: uploadFilesSlice,
    personalCabinet: personalCabinetSlice,
    specialists: specialistsSlice,
    geoPosition: geoPositionSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export default store

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
